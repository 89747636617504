import { Box } from '@mui/material';
import {
  BarGraph,
  BottomLeft,
  CircleGroup,
  CircleWithBubble,
  ColoredDots,
  FilledZigZags,
  LeftTop,
  RedCircleQuarter,
  RightBlockCircle,
  TransposedGraph,
  TwoTonePieChart,
  Zigzag,
} from './DecorationElements';
import { Figure } from './style';

export const DesktopBackgroundElements = () => {
  return (
    <Box
      zIndex={-1}
      left="50%"
      sx={{
        pointerEvents: 'none',
        userSelect: 'none',
        transform: 'translateX(-50%)',
      }}
      position="absolute"
      minWidth={1440}
      width="100vw"
      minHeight={800}
      height="100vh"
    >
      <Figure bottom={0} left={0} position="absolute">
        <BottomLeft />
      </Figure>
      <Figure top={0} left={0}>
        <LeftTop />
      </Figure>
      <Box
        position="absolute"
        zIndex={-1}
        right={0}
        top={0}
        height="100%"
        width="100%"
      >
        <Box position="relative" height="100%" width="100%">
          <Figure position="absolute" top={0} right="270px">
            <Zigzag />
          </Figure>
          <Box
            display="grid"
            right={0}
            alignContent="start"
            top={0}
            height="100%"
            position="absolute"
            zIndex={-1}
            sx={{ width: '289px', background: '#B2AEF8' }}
          >
            <Figure pr={2} pt={2} pb={2.5} justifySelf="end">
              <RightBlockCircle />
            </Figure>
            <Figure pl={5} justifySelf="start">
              <ColoredDots />
            </Figure>
            <Figure
              position="absolute"
              top="50%"
              left="157px"
              sx={{ transform: 'translateY(-50%)' }}
            >
              <TransposedGraph />
            </Figure>
            <Figure position="absolute" bottom="36px" left="36px">
              <TwoTonePieChart />
            </Figure>
            <Figure position="absolute" bottom={0} right={0}>
              <RedCircleQuarter />
            </Figure>
          </Box>
          <Figure position="absolute" bottom={0} right="288px">
            <FilledZigZags />
          </Figure>
          <Figure position="absolute" right="537px" bottom="32px">
            <CircleGroup />
          </Figure>
          <Figure position="absolute" right="639px" top="37px">
            <CircleWithBubble />
          </Figure>
          <Figure position="absolute" right="396px" top="126px">
            <BarGraph />
          </Figure>
        </Box>
      </Box>
    </Box>
  );
};
