import { SvgIcon, SvgIconProps } from '@mui/material';
import { Dashboard } from '../modules';
import { RouteType } from './types';
import { routePaths } from './routePaths';

const DashboardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M20 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4V20C3 20.2652 3.10536 20.5196 3.29289 20.7071C3.48043 20.8946 3.73478 21 4 21H20C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V4C21 3.73478 20.8946 3.48043 20.7071 3.29289C20.5196 3.10536 20.2652 3 20 3ZM11 19H5V16H11V19ZM11 14H5V5H11V14ZM19 19H13V10H19V19ZM19 8H13V5H19V8Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

const DashboardRoute: RouteType = {
  exact: true,
  icon: DashboardIcon,
  showInNavBar: false,
  path: routePaths.dashboard,
  title: 'Dashboard',
  component: () => <Dashboard />,
};

export default DashboardRoute;
