import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import {
  CountryCode,
  refetchPendingAccountsQuery,
  useCreatePendingAccountMutation,
} from '../../generated/graphql';
import { NewAccountConfirmationDialog } from './NewAccountConfirmationDialog';
import {
  PendingAccountForm,
  PendingAccountFormValues,
} from './PendingAccountForm';
import { PendingAccount } from './types';

export const CreatePendingAccountForm = () => {
  const [createPendingAccount] = useCreatePendingAccountMutation({
    refetchQueries: [refetchPendingAccountsQuery()],
  });

  const [pendingAccount, setPendingAccount] = useState<PendingAccount | null>(
    null,
  );

  const onSubmit = (values: PendingAccountFormValues) => {
    setPendingAccount({
      venueName: values.venueName,
      venueCountry: values.venueCountry,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
    });
  };

  const handleCreateNewVenueConfirmed = () => {
    if (!pendingAccount) return;

    createPendingAccount({
      variables: {
        venueName: pendingAccount.venueName,
        country: pendingAccount.venueCountry,
        email: pendingAccount.email,
        firstName: pendingAccount.firstName,
        lastName: pendingAccount.lastName,
      },
      toastConfig: {
        successText: 'New venue added to the Pending list',
        errorText: 'Failed to add new venue to the Pending list',
      },
    });

    setPendingAccount(null);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="h3">Create new pending venue</Typography>
        <PendingAccountForm
          initialValues={{
            email: '',
            firstName: '',
            lastName: '',
            venueName: '',
            venueCountry: CountryCode.CzechRepublic,
          }}
          onSubmit={onSubmit}
        />
      </Box>
      <NewAccountConfirmationDialog
        pendingAccount={pendingAccount}
        onClose={() => setPendingAccount(null)}
        onConfirm={handleCreateNewVenueConfirmed}
      />
    </>
  );
};
