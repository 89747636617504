export const TransposedGraph = () => (
  <svg
    width="78"
    height="125"
    viewBox="0 0 78 125"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M78 109.375L78 125L2.16547e-06 125L2.84846e-06 109.375L78 109.375Z"
        fill="#FFBEE9"
      />
      <path
        d="M58.5 15.625L58.5 -8.52372e-07L7.62939e-06 -3.40949e-06L6.9464e-06 15.625L58.5 15.625Z"
        fill="#FFBEE9"
      />
      <path
        d="M58.5 103.125L58.5 87.5L3.80465e-06 87.5L3.12166e-06 103.125L58.5 103.125Z"
        fill="#FFBEE9"
      />
      <path
        d="M39 37.5L39 21.875L6.67321e-06 21.875L5.99022e-06 37.5L39 37.5Z"
        fill="#FFBEE9"
      />
      <path
        d="M39 81.25L39 65.625L4.76083e-06 65.625L4.07784e-06 81.25L39 81.25Z"
        fill="#FFBEE9"
      />
      <path
        d="M19.5 59.375L19.5 43.75L5.71702e-06 43.75L5.03403e-06 59.375L19.5 59.375Z"
        fill="#FFBEE9"
      />
    </g>
  </svg>
);
