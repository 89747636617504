import React, { PropsWithChildren } from 'react';
import { Box, Container } from '@mui/material';
import { useToolbarHeight } from '../ui';
import Navbar from './Navbar';

const PrivateLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const toolbarHeight = useToolbarHeight();
  const minHeight = `calc(100vh - ${toolbarHeight}px)`;

  return (
    <>
      <Navbar />
      <Container disableGutters maxWidth={false}>
        <Box
          sx={{ minHeight, background: '#E5EDFE' }}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          height="100%"
        >
          {children}
        </Box>
      </Container>
    </>
  );
};

export default PrivateLayout;
