import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
.react-pdf__Page.prevPage {
  position: absolute !important;
  z-index: 1;
  left: 0;
}
  ${({ theme }) => `
      ${theme.breakpoints.up('md')} {
        &.Toastify__toast-container--top-right {
          top: ${theme.spacing(13.5)};
        }
      }
    `}
`;

export default GlobalStyle;
