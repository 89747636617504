/* eslint-disable max-len */
export const RightBlockCircle = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M0 40C-1.7104e-07 34.7471 1.03463 29.5457 3.04481 24.6926C5.055 19.8396 8.00138 15.4301 11.7157 11.7157C15.4301 8.00138 19.8396 5.055 24.6926 3.04481C29.5457 1.03463 34.7471 0 40 0L40 40L0 40Z"
        fill="#97EDDD"
      />
      <path
        d="M40 0C47.9112 3.4208e-07 55.6449 2.34594 62.2229 6.7412C68.8008 11.1365 73.9277 17.3836 76.9552 24.6926C79.9827 32.0017 80.7749 40.0443 79.2315 47.8036C77.688 55.5628 73.8784 62.6901 68.2843 68.2842C62.6902 73.8783 55.5629 77.688 47.8037 79.2314C40.0444 80.7748 32.0018 79.9826 24.6927 76.9551C17.3837 73.9276 11.1365 68.8007 6.74129 62.2228C2.34604 55.6448 -6.8416e-07 47.9112 0 40L40 40L40 0Z"
        fill="#FFBEE9"
      />
    </g>
  </svg>
);
