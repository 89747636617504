export const GreenDiamond = () => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m20 0-20 20 20 20 20-20z" fill="#97eddd" opacity=".4" />
  </svg>
);
