import { Figure } from '../style';
import { SvgProps } from './types';

export const BrowserFrame = ({ width, height }: SvgProps) => {
  return (
    <Figure>
      <svg
        fill="none"
        height={height}
        width={width}
        viewBox="0 0 640 28"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <path d="m0 0h640v28h-640z" fill="#2c3039" />
        <g fill="#d9d9d9">
          <circle cx="15" cy="14" r="3" />
          <circle cx="29" cy="14" opacity=".4" r="3" />
          <circle cx="43" cy="14" opacity=".2" r="3" />
        </g>
      </svg>
    </Figure>
  );
};
