/* eslint-disable max-len */
export const CircleGroup = () => (
  <svg
    fill="none"
    height="116"
    viewBox="0 0 186 116"
    width="186"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="58" cy="58" fill="#8846f3" opacity=".3" r="16" />
    <path
      d="m99.0122 99.0122c-8.1115 8.1118-18.4461 13.6358-29.697 15.8738s-22.9127 1.089-33.5108-3.301c-10.5982-4.39-19.6565-11.8238-26.02964-21.3619-6.37312-9.538-9.7747627-20.7518-9.77476-32.2231-.00000202-11.4713 3.40164-22.685 9.77476-32.223 6.37314-9.5381 15.43144-16.97211 26.02964-21.36199 10.5981-4.3898797 22.2599-5.53847 33.5108-3.30053s21.5855 7.76191 29.697 15.87332l-8.2024 8.2025c-6.4892-6.4892-14.7569-10.9084-23.7576-12.6987-9.0007-1.7904-18.3302-.8715-26.8087 2.6404s-15.7252 9.4591-20.8237 17.0896c-5.0985 7.6304-7.8198 16.6014-7.8198 25.7784 0 9.1771 2.7213 18.148 7.8198 25.7785 5.0985 7.6304 12.3452 13.5776 20.8237 17.0895 8.4785 3.512 17.808 4.431 26.8087 2.64 9.0007-1.79 17.2684-6.2091 23.7576-12.6982z"
      fill="#ffbee9"
      opacity=".6"
    />
    <circle cx="114" cy="58" fill="#ffbee9" opacity=".6" r="16" />
    <circle cx="170" cy="58" fill="#1daea6" opacity=".3" r="16" />
  </svg>
);
