export const routePaths = {
  dashboard: '/',
  newAccount: '/new-account',
} as const;

export enum Method {
  CREATE = '/create',
  UPDATE = '/update',
}

export type RoutePathsType = typeof routePaths;
export type RoutePathsKeys = keyof RoutePathsType;
export type RoutePathsValues = RoutePathsType[RoutePathsKeys];
