import {
  FetchResult,
  MutationFunctionOptions,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
  useMutation as useApolloMutation,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { toast } from 'react-toastify';

interface ToastConfig {
  successText: string;
  errorText?: string;
}

interface MutationOptions<TData, TVariables>
  extends MutationFunctionOptions<TData, TVariables> {
  toastConfig: ToastConfig | null;
}

type MutationTuple<TData, TVariables> = [
  (
    options: MutationOptions<TData, TVariables>,
  ) => Promise<FetchResult<TData> | null>,
  MutationResult<TData>,
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useMutation = <TData = any, TVariables = OperationVariables>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>,
): MutationTuple<TData, TVariables> => {
  const [mutationFn, mutationState] = useApolloMutation(mutation, options);

  const mutationWithToast = async (
    mutationOptions: MutationOptions<TData, TVariables>,
  ) => {
    const { toastConfig } = mutationOptions;

    try {
      const data = await mutationFn(mutationOptions);

      if (toastConfig) {
        toast(toastConfig.successText, {
          type: 'success',
        });
      }

      return data;
    } catch (e) {
      if (toastConfig) {
        toast(
          toastConfig.errorText ||
            'Oops, something is wrong. Please check your internet connection or contact our support.',
          {
            type: 'error',
          },
        );
      }
    }

    return null;
  };

  return [mutationWithToast, mutationState];
};

export default useMutation;
