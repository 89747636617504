/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-props-no-spreading */
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import {
  Box,
  ClickAwayListener,
  Fade,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Slide,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { RefObject, useEffect, useRef } from 'react';
import { useTheme } from 'styled-components';
import { useUser } from '../../../../providers/current-user.provider';

interface UserMenuViewProps {
  isOpen: boolean;
  onClose: () => void;
  anchorRef: RefObject<HTMLDivElement>;
}

const UserMenuView = ({ isOpen, anchorRef, onClose }: UserMenuViewProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { user, logout } = useUser();

  const handleClose = () => {
    onClose();
  };

  const handleLogout = () => {
    logout().catch((e) => console.error(e));
  };

  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (anchorRef.current && prevOpen.current && !isOpen) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  const AnimationContainer = isDesktop ? Slide : Fade;

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      role={undefined}
      transition
      disablePortal
      placement="bottom-end"
      sx={{ zIndex: 1500 }}
    >
      {({ TransitionProps }) => (
        <AnimationContainer
          {...TransitionProps}
          direction={isDesktop ? 'left' : 'down'}
        >
          <Paper
            square
            sx={{
              width: isDesktop ? '375px' : '100vw',
              overflowY: 'auto',
              maxHeight: `calc(100vh - ${
                isDesktop
                  ? theme.appbar.desktopHeight
                  : theme.appbar.mobileHeight
              }px)`,
              borderBottomLeftRadius: isDesktop ? '6px' : 'none',
              boxShadow: isDesktop ? undefined : 'none',
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Box sx={{ padding: '25px' }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    background: '#97EDDD99',
                    height: '100px',
                    borderRadius: '6px',
                  }}
                >
                  <Typography variant="subtitle1">
                    {user.given_name} {user.family_name}
                  </Typography>
                </Box>
                <MenuList autoFocusItem={isOpen} id="menu-list-grow">
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToAppOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Log out</ListItemText>
                  </MenuItem>
                </MenuList>
              </Box>
            </ClickAwayListener>
          </Paper>
        </AnimationContainer>
      )}
    </Popper>
  );
};

export default UserMenuView;
