import { Clear, Done, Edit, History } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { PendingAccount } from './types';

export interface PendingAccountCardProps {
  pendingAccount: PendingAccount;
  isSent: boolean;
  isDeleted: boolean;

  onEdit: () => void;
  onArchive: () => void;
  onUnarchive: () => void;
  onProceed: () => void;
}

export const PendingAccountCard: React.FC<PendingAccountCardProps> = ({
  pendingAccount: { venueName, venueCountry, email, firstName, lastName },
  isSent,
  isDeleted,
  onEdit,
  onArchive,
  onUnarchive,
  onProceed,
}) => {
  const theme = useTheme();

  return (
    <Box
      py={2}
      px={3}
      display="flex"
      flexDirection="column"
      sx={{
        borderRadius: '8px',
        backgroundColor: 'white',
        boxShadow:
          '0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px 4px 8px 0px rgba(0, 0, 0, 0.06)',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
        mb={1}
        height={30}
      >
        <Typography variant="h3" fontSize={16} mt="-2px">
          {venueName}
        </Typography>
        -<Typography variant="body2">{venueCountry}</Typography>
        <Box flexGrow={1} />
        {isSent ? (
          <>
            <Typography color={theme.palette.success.main}>Created</Typography>
            <Done color="success" />
          </>
        ) : null}
        {isDeleted ? (
          <>
            <Typography color={theme.palette.error.main}>
              Archived, not created
            </Typography>
            <Clear color="error" />
          </>
        ) : null}
        {!isDeleted && !isSent ? (
          <>
            <Typography color={theme.palette.warning.main}>Pending</Typography>
            <History color="warning" />
            <Tooltip title="Edit">
              <IconButton size="small" onClick={onEdit}>
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          </>
        ) : null}
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" gap={1}>
            <Typography>Login:</Typography>
            <Typography color={theme.palette.primary.main}>{email}</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography>Name:</Typography>
            <Typography>
              {firstName} {lastName}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" gap={1}>
          {isDeleted ? (
            <Button size="small" color="success" onClick={onUnarchive}>
              Unarchive
            </Button>
          ) : null}
          {!isDeleted && !isSent ? (
            <>
              <Button size="small" color="error" onClick={onArchive}>
                Archive
              </Button>
              <Button
                variant="contained"
                size="small"
                color="success"
                onClick={onProceed}
              >
                Proceed
              </Button>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
