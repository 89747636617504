/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/naming-convention */
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Backdrop,
  ClickAwayListener,
  Fade,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Slide,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { RefObject, useEffect, useRef, useState } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import { MenuListLink } from './MenuListLink';
import { primaryLinks } from './utils';

interface BurgerMenuProps {
  anchorRef: RefObject<HTMLDivElement>;
}

export const BurgerMenu = ({ anchorRef }: BurgerMenuProps) => {
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const desktopAnchorRef = useRef<HTMLDivElement>(null);

  const matchedRoutes = matchRoutes([...primaryLinks], location);
  const matchedRoute = matchedRoutes ? matchedRoutes[0] : null;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const onDrawerClose = () => setIsDrawerOpen(false);
  const onDrawerOpen = () => setIsDrawerOpen(true);
  const AnimationContainer = isDesktop ? Slide : Fade;

  useEffect(() => {
    const bodyElement = window.document.querySelector('body');
    if (!bodyElement) {
      return;
    }

    if (isDrawerOpen) {
      bodyElement.setAttribute('style', 'overflow: hidden;');
    } else {
      bodyElement.removeAttribute('style');
    }
  }, [isDrawerOpen]);

  return (
    <>
      <div ref={anchorRef} style={{ position: 'absolute', top: 0, left: 0 }} />
      <IconButton onClick={isDrawerOpen ? onDrawerClose : onDrawerOpen}>
        {isDrawerOpen ? (
          <CloseIcon sx={{ color: theme.palette.common.black }} />
        ) : (
          <MenuIcon sx={{ color: theme.palette.common.black }} />
        )}
      </IconButton>
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          top: isDesktop ? undefined : theme.appbar.mobileHeight,
        }}
        open={isDrawerOpen}
      />
      <Popper
        open={isDrawerOpen}
        anchorEl={isDesktop ? desktopAnchorRef.current : anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement={isDesktop ? 'top' : 'bottom-start'}
        sx={{ zIndex: 1500 }}
      >
        {({ TransitionProps }) => (
          <AnimationContainer
            {...TransitionProps}
            direction={isDesktop ? 'right' : 'down'}
          >
            <Paper
              square
              sx={{
                width: isDesktop ? '300px' : '100vw',
                height: isDesktop ? '100vh' : undefined,
              }}
            >
              <ClickAwayListener onClickAway={onDrawerClose}>
                <MenuList
                  sx={{
                    ...(isDesktop ? { p: 3 } : { pt: 2, px: 1, pb: 3 }),
                    overflowY: 'auto',
                    boxShadow: 'none',
                    maxHeight: isDesktop
                      ? '100vh'
                      : `calc(100vh - ${theme.appbar.mobileHeight}px)`,
                  }}
                >
                  {primaryLinks.map(({ path, title, Icon }) => (
                    <MenuListLink
                      key={path}
                      onClose={onDrawerClose}
                      path={path}
                      title={title}
                      Icon={Icon}
                      isActive={
                        matchedRoute ? matchedRoute.route.path === path : false
                      }
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </AnimationContainer>
        )}
      </Popper>
    </>
  );
};
