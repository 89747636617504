import { Box, Card, useTheme } from '@mui/material';
import { CreatePendingAccountForm } from './CreatePendingAccountForm';
import { PendingAccountList } from './PendingAccountList';

export const NewAccount = () => {
  const theme = useTheme();

  return (
    <Box
      width="100%"
      height="100%"
      p={3}
      display="flex"
      justifyContent="center"
    >
      <Card sx={{ p: 2, backgroundColor: '#FAFBFF', maxWidth: '1200px' }}>
        <Box display="grid" gridTemplateColumns="1fr max-content 1fr" gap={2}>
          <CreatePendingAccountForm />
          <Box
            sx={{
              width: '2px',
              height: '100%',
              backgroundColor: theme.palette.divider,
            }}
          />
          <PendingAccountList />
        </Box>
      </Card>
    </Box>
  );
};
