/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useApolloClient } from '@apollo/client';
import { Backdrop, CircularProgress } from '@mui/material';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import {
  CheckAuthStateDocument,
  CheckAuthStateMutation,
  CheckAuthStateMutationVariables,
  CurrentUserAdminPermissionsDocument,
  CurrentUserFragment,
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  LogoutDocument,
  LogoutMutation,
  LogoutMutationVariables,
} from '../generated/graphql';
import useSilentMutation from '../graphql/hooks/useSilentMutation';
import { LocaleEnumFe } from '../types';
import { mapBeLocaleToFeLocale } from '../utils';

interface AdminPermissions {
  canCreateNewVenues?: boolean;
}

export interface CurrentUser
  extends Omit<CurrentUserFragment, 'cultureCode' | 'languageCode'> {
  cultureCode: LocaleEnumFe;
  languageCode: LocaleEnumFe;

  adminPermissions: AdminPermissions;
}

interface AuthContextInterface {
  user: CurrentUser | null;
  login: (loginArgs: LoginMutationVariables) => Promise<void>;
  isLoginLoading?: boolean;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextInterface>({
  user: null,
} as AuthContextInterface);

const LOCAL_STORAGE_USER_KEY = 'user';

const setCurrentUserToLS = (currentUser: CurrentUser) =>
  localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(currentUser));
const unsetCurrentUserFromLS = () =>
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);

export const getUserFromLS = () => {
  const userFromLs = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
  if (userFromLs) {
    try {
      const parsedUser = JSON.parse(userFromLs) as CurrentUser;
      return parsedUser;
    } catch {
      return null;
    }
  }

  return null;
};

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const apolloClient = useApolloClient();
  const [user, setUser] = useState<CurrentUser | null>(null);

  const [checkAuthMutation] = useSilentMutation<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >(CheckAuthStateDocument, { fetchPolicy: 'no-cache' });

  const updateUserInfo = useCallback(
    (
      userPayload: CurrentUserFragment & { adminPermissions: AdminPermissions },
    ) => {
      const { cultureCode, languageCode, ...other } = userPayload;

      const fetchedUser = {
        ...other,
        cultureCode: mapBeLocaleToFeLocale(cultureCode),
        languageCode: mapBeLocaleToFeLocale(languageCode),
      };
      setCurrentUserToLS(fetchedUser);
      setUser(fetchedUser);

      if (
        user &&
        JSON.stringify(userPayload.adminPermissions) !==
          JSON.stringify(user.adminPermissions)
      ) {
        window.location.reload();
      }
    },
    [user],
  );

  const processFetchedUser = useCallback(
    async (
      userPayload: CurrentUserFragment & { adminPermissions: AdminPermissions },
    ) => {
      updateUserInfo(userPayload);
    },
    [updateUserInfo],
  );

  const getUserAdminPermissions = async () =>
    new Promise<AdminPermissions>((resolve, reject) => {
      apolloClient
        .query({
          query: CurrentUserAdminPermissionsDocument,
          fetchPolicy: 'no-cache',
        })
        .then(({ data }) => {
          if (data?.adminPermissions) {
            resolve(data.adminPermissions);
          } else {
            logout();
            reject();
          }
        })
        .catch(() => {
          logout();
          reject();
        });
    });

  const checkAuth = useCallback(async () => {
    try {
      const { data } = await checkAuthMutation();
      const adminPermissions = await getUserAdminPermissions();
      if (data && data.checkAuthState) {
        await processFetchedUser({ ...data.checkAuthState, adminPermissions });
      }
    } catch (err) {
      await logout();
      if (user) {
        toast('You are unauthorized and logged out.', { type: 'error' });
      }
    }
  }, [processFetchedUser]);

  const checkUserPermissions = useCallback(() => {
    getUserAdminPermissions()
      .then((newPermissions) => {
        if (
          JSON.stringify(newPermissions) !==
          JSON.stringify(user?.adminPermissions)
        ) {
          checkAuth();
        }
      })
      .catch(async (err) => {
        if (err.message === 'Failed to fetch') return;

        await logout();
        if (user) {
          toast('You are unauthorized and logged out.', { type: 'error' });
        }
      });
  }, [checkAuth]);

  const [loginMutation, { loading: isLoginLoading }] = useSilentMutation<
    LoginMutation,
    LoginMutationVariables
  >(LoginDocument);

  const login = async (variables: LoginMutationVariables) => {
    const { data } = await loginMutation({
      variables,
    });
    if (data) {
      const adminPermissions = await getUserAdminPermissions();
      await processFetchedUser({ ...data.login, adminPermissions });
    }
  };

  useEffect(() => {
    checkAuth()
      .catch((err) => {
        unsetCurrentUserFromLS();
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [logoutMutation] = useSilentMutation<
    LogoutMutation,
    LogoutMutationVariables
  >(LogoutDocument);

  const logout = async () => {
    try {
      await logoutMutation();
      setUser(null);
      unsetCurrentUserFromLS();
    } catch (err) {
      console.error(err);
      setUser(null);
      unsetCurrentUserFromLS();
    }
  };

  const localStorageListener = (event: StorageEvent) => {
    if (event.key === LOCAL_STORAGE_USER_KEY) {
      const { oldValue, newValue } = event;

      if (!oldValue && newValue) {
        checkAuth().catch((err) => {
          console.error(err);
        });
      }

      if (oldValue && !newValue) {
        setUser(null);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', localStorageListener);
    const interval = setInterval(checkUserPermissions, 10 * 1000);

    return () => {
      window.removeEventListener('storage', localStorageListener);
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [checkUserPermissions]);

  if (isLoading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isLoginLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
