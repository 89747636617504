/* eslint-disable max-len */
import { Figure } from '../style';
import { SvgProps } from './types';

export const Sidebar = ({ width, height }: SvgProps) => {
  return (
    <Figure>
      <svg
        fill="none"
        height={height}
        width={width}
        viewBox="0 0 104 339"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
      >
        <mask id="a" fill="#fff">
          <path d="m0 0h104v339h-104z" fill="#fff" />
        </mask>
        <path d="m0 0h104v339h-104z" fill="#fafbff" />
        <path
          d="m38.7911 24.6667-.256-.9067h-2.6453l-.256.9067h-1.824l2.528-7.4667h1.7387l2.5386 7.4667zm-2.5173-2.2613h1.8773l-.9386-3.3174z"
          fill="#2c3039"
        />
        <path
          d="m44.7026 19.3867c.704 0 1.2729.2489 1.7067.7467.4409.4977.6613 1.152.6613 1.9626 0 .8036-.224 1.4578-.672 1.9627-.448.4978-1.0275.7467-1.7386.7467-.3698 0-.7005-.0818-.992-.2454-.2916-.1635-.5049-.3698-.64-.6186v2.8586h-1.632v-7.2746h1.632v.736c.1351-.256.3555-.4658.6613-.6294.3058-.1635.6436-.2453 1.0133-.2453zm-.512 4.064c.3556 0 .6472-.1245.8747-.3733.2276-.256.3413-.5832.3413-.9814s-.1137-.7218-.3413-.9706c-.2275-.2489-.5191-.3734-.8747-.3734-.3697 0-.6684.1245-.896.3734-.2275.2488-.3413.5724-.3413.9706 0 .4054.1138.7325.3413.9814.2347.2488.5334.3733.896.3733z"
          fill="#2c3039"
        />
        <path
          d="m48.0522 24.6667v-5.1413h1.632v1.0773c.0854-.3698.2596-.6542.5227-.8533.2702-.2063.5689-.3094.896-.3094.1707 0 .3129.0142.4267.0427v1.5147c-.192-.0214-.3556-.032-.4907-.032-.9031 0-1.3547.5724-1.3547 1.7173v1.984z"
          fill="#2c3039"
        />
        <path
          d="m53.9531 18.7787c-.192.192-.4338.288-.7254.288-.2915 0-.5333-.096-.7253-.288-.1849-.192-.2773-.4267-.2773-.704 0-.2845.0924-.5227.2773-.7147.192-.192.4338-.288.7253-.288.2916 0 .5334.0996.7254.2987.192.192.288.4267.288.704s-.096.512-.288.704zm-1.5467 5.888v-5.1413h1.632v5.1413z"
          fill="#2c3039"
        />
        <path d="m55.3856 24.6667v-7.4667h1.632v7.4667z" fill="#2c3039" />
        <path
          d="m58.6847 26.16c-.1493 0-.2844-.0106-.4053-.032v-.6293c.1707.0213.3271.032.4693.032.3129 0 .5583-.096.736-.288.1636-.1707.256-.3911.2774-.6613-.0996.1208-.2703.1813-.512.1813-.2916 0-.5298-.096-.7147-.288s-.2773-.4373-.2773-.736c0-.2916.0995-.5333.2986-.7253.1991-.1992.4516-.2987.7574-.2987.3342 0 .6186.128.8533.384.2418.256.3627.6293.3627 1.12 0 .6115-.16 1.088-.48 1.4293-.32.3414-.7751.512-1.3654.512z"
          fill="#2c3039"
        />
        <path
          d="m69.0789 24.2507c-.512.384-1.2018.576-2.0693.576-.8676 0-1.5609-.192-2.08-.576-.512-.384-.768-.9138-.768-1.5893 0-.4338.1031-.8036.3093-1.1094s.5013-.5191.8853-.64c-.32-.1209-.576-.3306-.768-.6293-.192-.3058-.288-.6507-.288-1.0347 0-.6115.2454-1.0951.736-1.4506.4978-.3627 1.1556-.544 1.9734-.544.8106 0 1.4649.1813 1.9626.544.4978.3555.7467.8391.7467 1.4506 0 .384-.0996.7289-.2987 1.0347-.1991.2987-.4586.5084-.7786.6293.384.1209.6826.3342.896.64.2133.3058.32.6756.32 1.1094 0 .6684-.2596 1.1982-.7787 1.5893zm-2.0693-3.9467c.3271 0 .5866-.0818.7786-.2453.192-.1707.288-.3911.288-.6613 0-.2845-.0995-.5085-.2986-.672-.192-.1636-.448-.2454-.768-.2454-.3271 0-.5867.0818-.7787.2454-.192.1635-.288.3875-.288.672 0 .2702.0995.4906.2987.6613.1991.1635.4551.2453.768.2453zm-.8427 2.976c.2133.1778.4942.2667.8427.2667.3484 0 .6293-.0889.8426-.2667.2134-.1849.32-.4302.32-.736 0-.2986-.1066-.5369-.32-.7146-.2133-.1849-.4942-.2774-.8426-.2774-.3414 0-.6223.0925-.8427.2774-.2133.1777-.32.416-.32.7146 0 .3058.1067.5511.32.736z"
          fill="#2c3039"
        />
        <path
          d="m13.3333 46.6667c0-3.6819 2.9848-6.6667 6.6667-6.6667h64c3.6819 0 6.6666 2.9848 6.6666 6.6667v63.3333c0 3.682-2.9847 6.667-6.6666 6.667h-64c-3.6819 0-6.6667-2.985-6.6667-6.667z"
          fill="#97eddd"
          fillOpacity=".2"
        />
        <path d="m31.6748 40.1245h1.0677v76.5005h-1.0677z" fill="#fff" />
        <path d="m51.4255 40.1245h1.0676v76.5005h-1.0676z" fill="#fff" />
        <path d="m71.1762 40.1245h1.0676v76.5005h-1.0676z" fill="#fff" />
        <path d="m13.2575 59.1829v-1.0662h77.4037v1.0662z" fill="#fff" />
        <path d="m13.2575 78.9079v-1.0662h77.4037v1.0662z" fill="#fff" />
        <path d="m13.2575 98.6329v-1.0662h77.4037v1.0662z" fill="#fff" />
        <g fill="#2c3039">
          <path d="m32.8491 64.8h1.6707l-3.2387 6.5333h-1.68l1.2133-2.464-2.3146-4.0693h1.7546l1.3907 2.576z" />
          <path d="m37.9789 70.8013c-.448.448-1.0515.672-1.8106.672s-1.3658-.224-1.82-.672-.6814-1.0204-.6814-1.7173.2272-1.2662.6814-1.708c.4542-.448 1.0609-.672 1.82-.672s1.3626.224 1.8106.672c.4543.4418.6814 1.0111.6814 1.708s-.2271 1.2693-.6814 1.7173zm-1.8106-.5413c.3297 0 .5911-.1089.784-.3267s.2893-.5009.2893-.8493c0-.3485-.0964-.6316-.2893-.8493-.1929-.2178-.4543-.3267-.784-.3267-.336 0-.6005.1089-.7934.3267-.1866.2177-.28.5008-.28.8493 0 .3484.0934.6315.28.8493.1929.2178.4574.3267.7934.3267z" />
          <path d="m41.0959 71.4547c-.5351 0-.9458-.1587-1.232-.476-.2862-.3236-.4293-.7778-.4293-1.3627v-2.7813h1.4466v2.464c0 .6346.2489.952.7467.952.5787 0 .868-.3329.868-.9987v-2.4173h1.4187v4.4986h-1.4187v-.644c-.2675.5102-.7342.7654-1.4.7654z" />
          <path d="m45.0958 71.3333v-4.4986h1.428v.9426c.0747-.3235.2271-.5724.4574-.7466.2364-.1805.4977-.2707.784-.2707.1493 0 .2737.0124.3733.0373v1.3254c-.168-.0187-.3111-.028-.4293-.028-.7903 0-1.1854.5008-1.1854 1.5026v1.736z" />
          <path d="m54.0686 66.7133c.616 0 1.1138.2178 1.4933.6534.3858.4355.5787 1.008.5787 1.7173 0 .7031-.196 1.2755-.588 1.7173-.392.4356-.8991.6534-1.5214.6534-.3235 0-.6128-.0716-.868-.2147-.2551-.1431-.4417-.3236-.56-.5413v2.5013h-1.4279v-6.3653h1.4279v.644c.1183-.224.3112-.4076.5787-.5507.2676-.1431.5631-.2147.8867-.2147zm-.448 3.556c.3111 0 .5662-.1089.7653-.3266.1991-.224.2987-.5103.2987-.8587 0-.3485-.0996-.6316-.2987-.8493-.1991-.2178-.4542-.3267-.7653-.3267-.3236 0-.5849.1089-.784.3267-.1991.2177-.2987.5008-.2987.8493 0 .3547.0996.6409.2987.8587.2053.2177.4666.3266.784.3266z" />
          <path d="m56.9995 71.3333v-4.4986h1.428v.9426c.0746-.3235.2271-.5724.4573-.7466.2364-.1805.4978-.2707.784-.2707.1493 0 .2738.0124.3733.0373v1.3254c-.168-.0187-.3111-.028-.4293-.028-.7902 0-1.1853.5008-1.1853 1.5026v1.736z" />
          <path d="m64.7394 70.8013c-.448.448-1.0516.672-1.8107.672s-1.3658-.224-1.82-.672-.6813-1.0204-.6813-1.7173.2271-1.2662.6813-1.708c.4542-.448 1.0609-.672 1.82-.672s1.3627.224 1.8107.672c.4542.4418.6813 1.0111.6813 1.708s-.2271 1.2693-.6813 1.7173zm-1.8107-.5413c.3298 0 .5911-.1089.784-.3267s.2893-.5009.2893-.8493c0-.3485-.0964-.6316-.2893-.8493-.1929-.2178-.4542-.3267-.784-.3267-.336 0-.6005.1089-.7933.3267-.1867.2177-.28.5008-.28.8493 0 .3484.0933.6315.28.8493.1928.2178.4573.3267.7933.3267z" />
          <path d="m71.0416 66.1813c-.168.168-.3795.252-.6347.252-.2551 0-.4666-.084-.6346-.252-.1618-.168-.2427-.3733-.2427-.616 0-.2489.0809-.4573.2427-.6253.168-.168.3795-.252.6346-.252.2552 0 .4667.0871.6347.2613.168.168.252.3734.252.616 0 .2427-.084.448-.252.616zm-4.536.2147c0-.3298.0529-.6098.1587-.84.1058-.2365.2458-.4107.42-.5227s.3484-.1929.5226-.2426c.1743-.0498.3578-.0747.5507-.0747.3298 0 .6036.0342.8213.1027v1.0173c-.0311 0-.0933-.0031-.1866-.0093-.0871-.0125-.1556-.0187-.2054-.0187-.4355 0-.6533.1898-.6533.5693v.4574h1.0453v1.1293h-1.0453v3.3693h-1.428v-3.3693h-.8027v-1.1293h.8027zm3.1827 4.9373v-4.4986h1.428v4.4986z" />
          <path d="m74.6551 70.2227c.1991 0 .364-.0094.4946-.028v1.1386c-.2737.0498-.5911.0747-.952.0747-.1617 0-.3049-.0093-.4293-.028s-.2644-.0591-.42-.1213c-.1555-.0685-.2831-.1587-.3827-.2707-.0995-.112-.1866-.2738-.2613-.4853-.0684-.2116-.1027-.4605-.1027-.7467v-1.792h-.7093v-1.1293h.7093v-1.3347h1.428v1.3347h1.0174v1.1293h-1.0174v1.4933c0 .3049.0529.5102.1587.616.1058.0996.2613.1494.4667.1494z" />
        </g>
        <path
          d="m26.4246 88.1547h-1.8027v-1.6534h1.8027v-1.8453h1.728v1.8453h1.8026v1.6534h-1.8026v1.8453h-1.728z"
          fill="#1daea6"
        />
        <path
          d="m33.8337 90.1387c-.6115 0-1.1449-.0854-1.6-.256-.4551-.1707-.8071-.4125-1.056-.7254s-.3733-.6791-.3733-1.0986c0-.7894.448-1.3583 1.344-1.7067-.2916-.2418-.5013-.4871-.6293-.736-.1209-.2489-.1814-.544-.1814-.8853 0-.384.1067-.7254.32-1.024.2205-.2987.5262-.5298.9174-.6934.3982-.1635.8497-.2453 1.3546-.2453.4551 0 .8605.0711 1.216.2133.3627.1351.6436.3307.8427.5867.2062.2489.3093.5333.3093.8533 0 .3342-.0711.6151-.2133.8427-.1422.2275-.3662.4195-.672.576.4907.256.8498.5475 1.0773.8747.2347.3271.352.7182.352 1.1733 0 .704-.2666 1.2551-.8 1.6533-.5333.3982-1.2693.5974-2.208.5974zm.4587-4.6827c.2204-.2133.3307-.4693.3307-.768 0-.2489-.0676-.4373-.2027-.5653-.1351-.1352-.3307-.2027-.5867-.2027-.2702 0-.4764.0498-.6186.1493-.1352.0925-.2027.2276-.2027.4054 0 .1777.0675.3306.2027.4586.1422.128.3804.256.7146.384zm-.448 3.2107c.2702 0 .4871-.0498.6507-.1494.1706-.1066.256-.2453.256-.416 0-.1849-.0783-.3449-.2347-.48-.1493-.1422-.3982-.2809-.7467-.416l-.4906-.192c-.2276.2347-.3414.5262-.3414.8747 0 .2347.0818.4231.2454.5653.1706.1422.3911.2134.6613.2134z"
          fill="#1daea6"
        />
        <path
          d="m37.6519 90.5547c.3627 0 .6009-.0072.7147-.0214.1137-.0142.1884-.0569.224-.128.0355-.0711.0533-.2062.0533-.4053h-.992v-1.9947h2.1867v1.088c0 .8178-.0498 1.4045-.1494 1.76-.0924.3627-.288.6116-.5866.7467-.2916.1351-.7752.2027-1.4507.2027z"
          fill="#1daea6"
        />
        <path
          d="m42.8457 90.1387c-.6542 0-1.3298-.1529-2.0266-.4587l.1386-1.504c.2845.1067.5725.1884.864.2453.2916.0569.5511.0854.7787.0854.3342 0 .5902-.0783.768-.2347.1778-.1565.2667-.3662.2667-.6293 0-.2347-.064-.4232-.192-.5654-.128-.1493-.2952-.224-.5014-.224-.2915 0-.6435.1422-1.056.4267l-1.1093-.48.2773-3.936h4.1174l-.16 1.5253h-2.5174l-.1386 1.184c.192-.1209.3804-.2062.5653-.256s.3982-.0746.64-.0746c.4124 0 .7822.1031 1.1093.3093.3342.2062.5938.4942.7787.864.192.3627.288.7715.288 1.2267 0 .4764-.1245.9066-.3733 1.2906-.2489.3769-.5938.672-1.0347.8854-.4338.2133-.928.32-1.4827.32z"
          fill="#1daea6"
        />
        <path
          d="m49.7274 90.0853c-.64 0-1.216-.1564-1.728-.4693-.5049-.32-.9031-.7573-1.1947-1.312-.2844-.5618-.4266-1.1982-.4266-1.9093 0-.704.1422-1.3334.4266-1.888.2916-.5618.6898-.9992 1.1947-1.312.512-.32 1.088-.48 1.728-.48.6329 0 1.2018.16 1.7067.48.512.3128.9102.7502 1.1946 1.312.2916.5617.4374 1.1911.4374 1.888 0 .704-.1423 1.3368-.4267 1.8986-.2845.5618-.6827 1.0027-1.1947 1.3227-.5049.3129-1.0773.4693-1.7173.4693zm0-1.7706c.2133 0 .4053-.0818.576-.2454.1778-.1706.3164-.4018.416-.6933.0995-.2916.1493-.6187.1493-.9813 0-.5618-.1066-1.0205-.32-1.376-.2133-.3556-.4871-.5334-.8213-.5334-.3485 0-.6293.1778-.8427.5334-.2062.3484-.3093.8071-.3093 1.376 0 .5617.1067 1.024.32 1.3866.2204.3556.4978.5334.832.5334z"
          fill="#1daea6"
        />
        <path
          d="m57.1232 90.0853c-.64 0-1.216-.1564-1.728-.4693-.5049-.32-.9031-.7573-1.1946-1.312-.2845-.5618-.4267-1.1982-.4267-1.9093 0-.704.1422-1.3334.4267-1.888.2915-.5618.6897-.9992 1.1946-1.312.512-.32 1.088-.48 1.728-.48.6329 0 1.2018.16 1.7067.48.512.3128.9102.7502 1.1947 1.312.2915.5617.4373 1.1911.4373 1.888 0 .704-.1422 1.3368-.4267 1.8986-.2844.5618-.6826 1.0027-1.1947 1.3227-.5048.3129-1.0773.4693-1.7173.4693zm0-1.7706c.2134 0 .4054-.0818.576-.2454.1778-.1706.3165-.4018.416-.6933.0996-.2916.1494-.6187.1494-.9813 0-.5618-.1067-1.0205-.32-1.376-.2134-.3556-.4872-.5334-.8214-.5334-.3484 0-.6293.1778-.8426.5334-.2063.3484-.3094.8071-.3094 1.376 0 .5617.1067 1.024.32 1.3866.2205.3556.4978.5334.832.5334z"
          fill="#1daea6"
        />
        <path
          d="m65.9753 90.0853c-.5689 0-1.0738-.1138-1.5147-.3413-.4337-.2347-.7751-.5618-1.024-.9813-.2417-.4196-.3626-.896-.3626-1.4294 0-.512.128-.9778.384-1.3973.256-.4196.6044-.7502 1.0453-.992s.9316-.3627 1.472-.3627c.6898 0 1.2338.1422 1.632.4267l-.2027 1.4187c-.4053-.1209-.7395-.1814-1.0026-.1814-.3414 0-.6116.0996-.8107.2987-.1991.192-.2987.4551-.2987.7893s.0996.6009.2987.8.4622.2987.7893.2987c.2845 0 .6187-.0605 1.0027-.1813l.2027 1.4933c-.2418.1209-.4871.2062-.736.256-.2489.0569-.5405.0853-.8747.0853z"
          fill="#1daea6"
        />
        <path
          d="m68.1692 88.8587 1.8774-2.8374h-1.8027v-1.3653h4.4373v1.152l-1.7813 2.8267h1.824v1.3653h-4.5547z"
          fill="#1daea6"
        />
        <path
          d="m78.8597 90.0853c-.4764 0-.8604-.0604-1.152-.1813-.2844-.128-.5191-.3378-.704-.6293-.1849-.2916-.3484-.7076-.4906-1.248l-.6294.5013v1.472h-2.2613l.0106-7.0293 2.2614-.3307-.0107 3.648 2.7414-2.24 1.2266 1.6747-1.7173 1.1946c.0853.5405.2062.9174.3627 1.1307.1635.2133.4017.32.7146.32.128 0 .2809-.0213.4587-.064l-.2133 1.7493c-.2134.0214-.4125.032-.5974.032z"
          fill="#1daea6"
        />
        <path d="m103 0v339h2v-339z" fill="#dde6fd" mask="url(#a)" />
      </svg>
    </Figure>
  );
};
