/* eslint-disable max-len */
export const TwoTonePieChart = () => (
  <svg
    width="200"
    height="200"
    viewBox="0 0 200 200"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M100 1.11338e-05C113.132 1.05921e-05 126.136 2.58659 138.268 7.61205C150.401 12.6375 161.425 20.0035 170.711 29.2893C179.997 38.5752 187.362 49.5991 192.388 61.7317C197.413 73.8642 200 86.8678 200 100L100 100L100 1.11338e-05Z"
      fill="#5C61D7"
    />
    <path
      opacity="0.6"
      d="M200 100C200 119.778 194.135 139.112 183.147 155.557C172.159 172.002 156.541 184.819 138.268 192.388C119.996 199.957 99.8891 201.937 80.491 198.079C61.0929 194.22 43.2746 184.696 29.2893 170.711C15.3041 156.726 5.78001 138.907 1.92149 119.509C-1.93705 100.111 0.0433009 80.0044 7.61206 61.7318C15.1808 43.4592 27.9981 27.8413 44.443 16.8532C60.8879 5.86501 80.2219 0.000115756 100 0.000117192L100 100L200 100Z"
      fill="#FFBEE9"
    />
  </svg>
);
