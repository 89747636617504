import { CloseRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  ExistingAccountObjectType,
  useExistingAccountByEmailLazyQuery,
} from '../../generated/graphql';
import { PendingAccount } from './types';

export interface NewAccountConfirmationDialogProps {
  pendingAccount: PendingAccount | null;
  onClose: () => void;
  onConfirm: (args: { doesAccountExist: boolean }) => void;
  isFinalConfirm?: boolean;
}

export const NewAccountConfirmationDialog: React.FC<
  NewAccountConfirmationDialogProps
> = ({ pendingAccount, onClose, onConfirm, isFinalConfirm }) => {
  const theme = useTheme();

  const [getExistingAccount, { loading: existingAccountLoading }] =
    useExistingAccountByEmailLazyQuery();

  const [existingAccount, setExistingAccount] =
    useState<ExistingAccountObjectType | null>(null);

  useEffect(() => {
    if (!pendingAccount) {
      setExistingAccount(null);
      return;
    }

    getExistingAccount({
      variables: {
        email: pendingAccount.email,
      },
      fetchPolicy: 'network-only',
    }).then((data) => {
      setExistingAccount(data.data?.existingAccountByEmail || null);
    });
  }, [pendingAccount]);

  const handleConfirm = () => {
    if (!pendingAccount) return;

    onConfirm({
      doesAccountExist: !!existingAccount,
    });
  };

  return (
    <Dialog
      open={!!pendingAccount}
      onClose={onClose}
      fullWidth
      sx={{ maxWidth: '844px', margin: 'auto' }}
    >
      <Box display="flex" flexDirection="column" px={2} py={2}>
        <Box
          display="grid"
          pb={2}
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          gridAutoFlow="column"
        >
          <Typography lineHeight={1.2} variant="h3">
            {isFinalConfirm ? 'Confirm' : 'Create new pending venue'}
          </Typography>
          <IconButton
            sx={{ padding: 0 }}
            onClick={onClose}
            color="primary"
            aria-label="close-dialog"
            component="span"
          >
            <CloseRounded />
          </IconButton>
        </Box>

        {pendingAccount && !existingAccountLoading ? (
          <>
            <Typography>
              {isFinalConfirm
                ? 'You are creating an account for the following new venue:'
                : 'You are creating a new venue with the following data:'}
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={0.5}
              pl={2}
              pt={1}
              pb={2}
            >
              <Typography>
                <strong>Venue name:</strong> {pendingAccount.venueName}
              </Typography>
              <Typography>
                <strong>Country:</strong> {pendingAccount.venueCountry}
              </Typography>
            </Box>

            {existingAccount ? (
              <>
                <Typography>
                  An account with the email{' '}
                  <span style={{ color: theme.palette.primary.main }}>
                    {pendingAccount.email}
                  </span>{' '}
                  already exists:
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={0.5}
                  pl={2}
                  pt={1}
                  pb={2}
                >
                  <Typography>
                    <strong>Email:</strong>{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                      {pendingAccount.email}
                    </span>
                  </Typography>
                  <Typography>
                    <strong>Name:</strong> {existingAccount.firstName}{' '}
                    {existingAccount.lastName}
                  </Typography>
                  <Typography>
                    <strong>Venues:</strong>{' '}
                    {existingAccount.venueNames.join(', ')}
                  </Typography>
                </Box>
                {existingAccount.isDeleted ? (
                  <Typography color="error">
                    The existing account is deleted. It is not possible to
                    create a new venue for this email. Reach out to the tech
                    team to solve this.
                  </Typography>
                ) : null}
                {!existingAccount.isDeleted && !isFinalConfirm ? (
                  <Typography color={theme.palette.warning.main}>
                    The new venue will be added to this account after you
                    confirm it in the list of pending venues.
                  </Typography>
                ) : null}
                {!existingAccount.isDeleted && isFinalConfirm ? (
                  <>
                    <Typography color={theme.palette.warning.main}>
                      The new venue will be added to this account.
                    </Typography>
                    <Typography pt={2}>
                      <strong>No emails with credentials will be sent.</strong>{' '}
                      The user will see this venue in their account as soon as
                      you press &quot;Confirm&quot;.
                    </Typography>
                  </>
                ) : null}
              </>
            ) : (
              <>
                <Typography>
                  There are no existing accounts with this email, a new account
                  will be created:
                </Typography>
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={0.5}
                  pl={2}
                  pt={1}
                  pb={2}
                >
                  <Typography>
                    <strong>Email:</strong>{' '}
                    <span style={{ color: theme.palette.primary.main }}>
                      {pendingAccount.email}
                    </span>
                  </Typography>
                  <Typography>
                    <strong>Name:</strong> {pendingAccount.firstName}{' '}
                    {pendingAccount.lastName}
                  </Typography>
                </Box>
                {isFinalConfirm ? (
                  <>
                    <Typography color={theme.palette.success.dark}>
                      A new account will be created for this user.
                    </Typography>
                    <Typography pt={2}>
                      <strong>The user will receive an email</strong> with their
                      credentials as soon as you press &quot;Confirm&quot;.
                    </Typography>
                  </>
                ) : (
                  <Typography color={theme.palette.success.dark}>
                    A new account will be created for this user after you
                    confirm it in the list of pending venues.
                  </Typography>
                )}
              </>
            )}

            <Box display="flex" justifyContent="flex-end" gap={2}>
              <Button color="inherit" onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleConfirm}
                disabled={existingAccount?.isDeleted}
              >
                {isFinalConfirm ? 'Confirm' : 'Add to pending accounts'}
              </Button>
            </Box>
          </>
        ) : (
          <Box
            height="355px"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
