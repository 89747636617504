import { Box } from '@mui/material';
import { BrowserFrame } from './BrowserFrame';
import { Header } from './Header';
import { ScrollingContent } from './ScrollingContent';
import { Sidebar } from './Sidebar';

const AppPreview = () => (
  <Box
    maxWidth={640}
    overflow="hidden"
    display="grid"
    zIndex={1}
    sx={{
      aspectRatio: '16 / 10',
      borderTopLeftRadius: '1.875% 3%',
      borderTopRightRadius: '1.875% 3%',
      borderBottomRightRadius: '1.875% 3%',
      borderBottomLeftRadius: '1.875% 3%',
    }}
    gridTemplateRows="auto auto 1fr"
  >
    <BrowserFrame width="100%" />
    <Header width="100%" />
    <Box
      display="grid"
      maxWidth="100%"
      gridTemplateColumns="1fr 5fr"
      bgcolor="#E7EEFF"
    >
      <Sidebar height="100%" width="100%" />
      <Box position="relative" overflow="hidden">
        <ScrollingContent />
      </Box>
    </Box>
  </Box>
);

export default AppPreview;
