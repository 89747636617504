export const BottomLeft = () => (
  <svg
    fill="none"
    height="120"
    viewBox="0 0 108 120"
    width="108"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#97eddd" opacity=".5">
      <path d="m91.6364 79.3407h16.3636v40.6593h-16.3637z" />
      <path d="m85.0909 59.011h-16.3636v60.989h16.3636z" />
      <path d="m16.3636 79.3407h-16.36360583l-.00000711 40.6593h16.36361294z" />
      <path d="m62.1818 46h-16.3636v74h16.3636z" />
      <path d="m39.2727 68.3626h-16.3636v51.6374h16.3636z" />
    </g>
    <circle cx="54" cy="16" fill="#ffbee9" opacity=".6" r="16" />
  </svg>
);
