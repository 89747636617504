import { Box, Typography } from '@mui/material';

const Dashboard = () => {
  return (
    <Box
      width="100%"
      height="100%"
      p={3}
      display="flex"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h2" mb={2}>
        Welcome to Dishboard Admin
      </Typography>
      <Typography variant="h3">
        Access the admin features from the sidebar.
      </Typography>
    </Box>
  );
};

export default Dashboard;
