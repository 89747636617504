/* eslint-disable react/jsx-props-no-spreading */
import { MouseEvent, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField as MuiTextField,
  TextFieldProps,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const PasswordField = ({
  required,
  helperText,
  ...other
}: Omit<TextFieldProps, 'type'>) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const handleClickShowPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <MuiTextField
      margin="normal"
      InputLabelProps={{ shrink: true, required }}
      variant="filled"
      type={isPasswordShown ? 'text' : 'password'}
      helperText={helperText}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              color="primary"
            >
              {isPasswordShown ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
            </IconButton>
          </InputAdornment>
        ),
        autoComplete: 'new-password',
        'aria-autocomplete': 'none',
      }}
      {...other}
    />
  );
};

export default PasswordField;
