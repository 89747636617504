import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
  useMediaQuery,
} from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import './index.css';
import { IndexPage } from './modules/index-page';
import { Providers } from './providers';
import theme from './theme';

let host: string;
let httpProtocol: string;
const env = import.meta.env.VITE_HOST_ENV;

switch (env) {
  case 'demo':
    host = 'demo-api.dishboard.app/graphql';
    httpProtocol = 'https://';
    break;
  case 'prod':
    host = 'api.dishboard.app/graphql';
    httpProtocol = 'https://';
    break;
  case 'development':
  default:
    host = 'localhost:4000/graphql';
    httpProtocol = 'http://';
}

const uri = `${httpProtocol}${host}`;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, extensions }) => {
      // We don't want to show Unauth errors, so we will not spam user
      // They are handled in auth.provider
      // @ts-expect-error - better types later
      if (extensions?.response?.statusCode !== 401) {
        toast(message, { type: 'error', theme: 'colored' });
      }
    });
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    const { message } = networkError;
    toast(message, { type: 'warning', theme: 'colored' });
  }
});

const link = from([errorLink, createHttpLink({ uri, credentials: 'include' })]);

const cache = new InMemoryCache({});

const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  },
});

const App = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <CssBaseline />
          <ToastContainer position={isDesktop ? 'bottom-left' : 'top-right'} />
          <Router>
            <Providers>
              <IndexPage />
            </Providers>
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
