import React from 'react';
import { Login } from '../modules';

const LoginRoute = {
  exact: true,
  path: '/login',
  title: 'Login',
  component: () => <Login />,
};

export default LoginRoute;
