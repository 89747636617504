import { SchemaOf, object, string } from 'yup';
import { LoginMutationVariables } from '../../../generated/graphql';

export const loginFormValidation: SchemaOf<LoginMutationVariables> =
  object().shape({
    email: string()
      .email('Please, insert valid email')
      .required('Email is required'),
    password: string().required('Password is required'),
  });
