import UserIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { RefObject, useEffect, useState } from 'react';
import { Backdrop, Button, useMediaQuery, useTheme } from '@mui/material';
import { useUser } from '../../../../providers/current-user.provider';
import UserMenuView from './UserMenuView';

interface UserMenuProps {
  anchorRef: RefObject<HTMLDivElement>;
}

const UserMenu = ({ anchorRef }: UserMenuProps) => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  useEffect(() => {
    const bodyElement = window.document.querySelector('body');
    if (!bodyElement) {
      return;
    }

    if (isOpen && !isDesktop) {
      bodyElement.setAttribute('style', 'overflow: hidden;');
    } else {
      bodyElement.removeAttribute('style');
    }
  }, [isOpen, isDesktop]);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        color="inherit"
        endIcon={
          isOpen ? (
            <CloseIcon sx={{ fontSize: '18pt !important' }} />
          ) : (
            <UserIcon sx={{ fontSize: '18pt !important' }} />
          )
        }
        style={{ fontSize: '10pt', borderRadius: '9999px' }}
      >
        {user.given_name} {user.family_name}
      </Button>
      <UserMenuView
        anchorRef={anchorRef}
        isOpen={isOpen}
        onClose={handleClose}
      />
      <Backdrop
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          top: theme.appbar.mobileHeight,
        }}
        open={!isDesktop && isOpen}
      />
    </>
  );
};

export default UserMenu;
