import React, { PropsWithChildren } from 'react';
import { AuthProvider } from './auth.provider';
import GlobalStyle from './GlobalStyle';

const Providers: React.FC<PropsWithChildren> = ({ children }) => (
  <AuthProvider>
    <GlobalStyle />
    {children}
  </AuthProvider>
);

export default Providers;
