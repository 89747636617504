/* eslint-disable max-len */
import { Figure } from '../style';
import { SvgProps } from './types';

export const Header = ({ width, height }: SvgProps) => {
  return (
    <Figure>
      <svg
        fill="none"
        height={height}
        width={width}
        viewBox="0 0 640 33"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid meet"
      >
        <mask id="path-1-inside-1_2804_23265" fill="white">
          <path d="M0 0H640V33H0V0Z" />
        </mask>
        <path d="M0 0H640V33H0V0Z" fill="#FAFBFF" />
        <path
          d="M31.3558 21.9255V15.5963H32.7683V21.9255H31.3558ZM32.0621 14.4359C31.8223 14.4359 31.6212 14.3524 31.4585 14.1854C31.3044 14.0184 31.2274 13.8118 31.2274 13.5656C31.2274 13.3195 31.3044 13.1129 31.4585 12.9459C31.6212 12.7789 31.8223 12.6954 32.0621 12.6954C32.3103 12.6954 32.5115 12.7789 32.6656 12.9459C32.8197 13.1129 32.8968 13.3195 32.8968 13.5656C32.8968 13.8118 32.8197 14.0184 32.6656 14.1854C32.5115 14.3524 32.3103 14.4359 32.0621 14.4359Z"
          fill="#2C3039"
        />
        <path
          d="M36.3688 22.0574C36.0263 22.0574 35.6967 22.0135 35.38 21.9255C35.0632 21.8288 34.7721 21.697 34.5068 21.53C34.2414 21.3542 34.0102 21.1432 33.8133 20.897L34.7122 19.974C34.9262 20.229 35.1702 20.4223 35.4442 20.5542C35.7267 20.6773 36.0435 20.7388 36.3945 20.7388C36.7112 20.7388 36.9509 20.6905 37.1136 20.5938C37.2762 20.4971 37.3576 20.3564 37.3576 20.1718C37.3576 19.9784 37.2805 19.829 37.1264 19.7235C36.9723 19.618 36.7711 19.5301 36.5229 19.4598C36.2832 19.3807 36.0263 19.3015 35.7524 19.2224C35.487 19.1433 35.2302 19.0378 34.9819 18.906C34.7422 18.7653 34.5453 18.5763 34.3912 18.339C34.2371 18.1016 34.16 17.7939 34.16 17.4159C34.16 17.0116 34.2499 16.6643 34.4297 16.3742C34.618 16.0842 34.8792 15.86 35.213 15.7018C35.5555 15.5435 35.9621 15.4644 36.433 15.4644C36.9295 15.4644 37.3661 15.5567 37.7428 15.7413C38.1281 15.9171 38.4491 16.1852 38.7059 16.5457L37.807 17.4687C37.6272 17.2401 37.4218 17.0687 37.1906 16.9544C36.9595 16.8402 36.6941 16.783 36.3945 16.783C36.1119 16.783 35.8936 16.827 35.7395 16.9149C35.5854 17.0028 35.5084 17.1302 35.5084 17.2973C35.5084 17.4731 35.5854 17.6093 35.7395 17.706C35.8936 17.8027 36.0905 17.8862 36.3302 17.9566C36.5785 18.0269 36.8353 18.106 37.1007 18.1939C37.3747 18.273 37.6315 18.3873 37.8712 18.5368C38.1195 18.6774 38.3207 18.8708 38.4748 19.1169C38.6289 19.3543 38.7059 19.6663 38.7059 20.0531C38.7059 20.6685 38.4962 21.1564 38.0767 21.5168C37.6572 21.8772 37.0879 22.0574 36.3688 22.0574Z"
          fill="#2C3039"
        />
        <path
          d="M44.0953 21.9255V18.273C44.0953 17.8511 43.9626 17.5038 43.6973 17.2313C43.4404 16.9588 43.1065 16.8226 42.6956 16.8226C42.4217 16.8226 42.1777 16.8841 41.9636 17.0072C41.7496 17.1302 41.5827 17.3017 41.4628 17.5214C41.343 17.7412 41.283 17.9917 41.283 18.273L40.7309 17.9566C40.7309 17.4731 40.8336 17.0467 41.0391 16.6775C41.2445 16.2995 41.527 16.005 41.8866 15.7941C42.2547 15.5743 42.6657 15.4644 43.1194 15.4644C43.5817 15.4644 43.9926 15.5699 44.3522 15.7809C44.7117 15.9919 44.9942 16.2863 45.1997 16.6643C45.4052 17.0335 45.5079 17.4643 45.5079 17.9566V21.9255H44.0953ZM39.8705 21.9255V12.4316H41.283V21.9255H39.8705Z"
          fill="#2C3039"
        />
        <path
          d="M50.2436 22.0574C49.7728 22.0574 49.3533 21.9563 48.9851 21.7541C48.617 21.5432 48.3259 21.2575 48.1119 20.897C47.9065 20.5366 47.8037 20.1279 47.8037 19.6707V17.8511C47.8037 17.394 47.9107 16.9852 48.1248 16.6248C48.3388 16.2644 48.6299 15.9831 48.998 15.7809C49.3661 15.5699 49.7813 15.4644 50.2436 15.4644C50.8001 15.4644 51.3009 15.6139 51.7461 15.9127C52.1998 16.2028 52.5551 16.5984 52.8119 17.0995C53.0773 17.5918 53.21 18.15 53.21 18.7741C53.21 19.3894 53.0773 19.9476 52.8119 20.4487C52.5551 20.941 52.1998 21.3322 51.7461 21.6223C51.3009 21.9124 50.8001 22.0574 50.2436 22.0574ZM50.0125 20.6861C50.3549 20.6861 50.6545 20.6069 50.9114 20.4487C51.1768 20.2817 51.3822 20.0531 51.5278 19.763C51.6819 19.473 51.7589 19.1389 51.7589 18.7609C51.7589 18.3829 51.6819 18.0489 51.5278 17.7588C51.3822 17.4687 51.1768 17.2445 50.9114 17.0863C50.6545 16.9193 50.3549 16.8358 50.0125 16.8358C49.67 16.8358 49.3661 16.9193 49.1007 17.0863C48.8353 17.2445 48.6299 17.4687 48.4843 17.7588C48.3388 18.0489 48.266 18.3829 48.266 18.7609C48.266 19.1389 48.3388 19.473 48.4843 19.763C48.6299 20.0531 48.8353 20.2817 49.1007 20.4487C49.3661 20.6069 49.67 20.6861 50.0125 20.6861ZM46.9433 21.9255V12.4316H48.3559V17.1522L48.1119 18.6818L48.3559 20.2246V21.9255H46.9433Z"
          fill="#2C3039"
        />
        <path
          d="M57.2175 22.0574C56.6183 22.0574 56.0746 21.9124 55.5867 21.6223C55.0987 21.3234 54.7092 20.9234 54.4181 20.4223C54.1356 19.9213 53.9943 19.3631 53.9943 18.7477C53.9943 18.1324 54.1356 17.5786 54.4181 17.0863C54.7092 16.594 55.0987 16.2028 55.5867 15.9127C56.0746 15.6139 56.6183 15.4644 57.2175 15.4644C57.8254 15.4644 58.3733 15.6095 58.8612 15.8996C59.3492 16.1896 59.7345 16.5852 60.017 17.0863C60.3081 17.5786 60.4536 18.1324 60.4536 18.7477C60.4536 19.3631 60.3081 19.9213 60.017 20.4223C59.7345 20.9234 59.3492 21.3234 58.8612 21.6223C58.3733 21.9124 57.8254 22.0574 57.2175 22.0574ZM57.2175 20.6597C57.5685 20.6597 57.8767 20.5806 58.1421 20.4223C58.4161 20.2553 58.6258 20.0268 58.7714 19.7367C58.9255 19.4466 59.0025 19.1169 59.0025 18.7477C59.0025 18.3785 58.9255 18.0533 58.7714 17.772C58.6173 17.4907 58.4075 17.2709 58.1421 17.1127C57.8767 16.9456 57.5685 16.8621 57.2175 16.8621C56.8751 16.8621 56.5669 16.9456 56.293 17.1127C56.0276 17.2709 55.8178 17.4907 55.6637 17.772C55.5182 18.0533 55.4454 18.3785 55.4454 18.7477C55.4454 19.1169 55.5182 19.4466 55.6637 19.7367C55.8178 20.0268 56.0276 20.2553 56.293 20.4223C56.5669 20.5806 56.8751 20.6597 57.2175 20.6597Z"
          fill="#2C3039"
        />
        <path
          d="M64.2094 22.0574C63.653 22.0574 63.1479 21.9124 62.6941 21.6223C62.249 21.3322 61.8937 20.941 61.6283 20.4487C61.3715 19.9476 61.243 19.3894 61.243 18.7741C61.243 18.15 61.3715 17.5918 61.6283 17.0995C61.8937 16.5984 62.249 16.2028 62.6941 15.9127C63.1479 15.6139 63.653 15.4644 64.2094 15.4644C64.6803 15.4644 65.0955 15.5699 65.4551 15.7809C65.8232 15.9831 66.1143 16.2644 66.3283 16.6248C66.5423 16.9852 66.6493 17.394 66.6493 17.8511V19.6707C66.6493 20.1279 66.5423 20.5366 66.3283 20.897C66.1228 21.2575 65.836 21.5432 65.4679 21.7541C65.0998 21.9563 64.6803 22.0574 64.2094 22.0574ZM64.4406 20.6861C64.9628 20.6861 65.3823 20.5059 65.699 20.1454C66.0244 19.785 66.187 19.3235 66.187 18.7609C66.187 18.3829 66.1143 18.0489 65.9687 17.7588C65.8232 17.4687 65.6177 17.2445 65.3523 17.0863C65.0955 16.9193 64.7916 16.8358 64.4406 16.8358C64.0981 16.8358 63.7942 16.9193 63.5288 17.0863C63.272 17.2445 63.0665 17.4687 62.9124 17.7588C62.7669 18.0489 62.6941 18.3829 62.6941 18.7609C62.6941 19.1389 62.7669 19.473 62.9124 19.763C63.0665 20.0531 63.272 20.2817 63.5288 20.4487C63.7942 20.6069 64.0981 20.6861 64.4406 20.6861ZM66.0971 21.9255V20.2246L66.3411 18.6818L66.0971 17.1522V15.5963H67.5097V21.9255H66.0971Z"
          fill="#2C3039"
        />
        <path
          d="M69.0774 21.9255V15.5963H70.4899V21.9255H69.0774ZM70.4899 18.4049L70.0019 18.1544C70.0019 17.3544 70.1732 16.7083 70.5156 16.216C70.8666 15.715 71.3931 15.4644 72.0951 15.4644C72.4033 15.4644 72.6815 15.5216 72.9298 15.6358C73.1781 15.7501 73.4092 15.9347 73.6232 16.1896L72.6987 17.1654C72.5874 17.0423 72.4632 16.9544 72.3263 16.9017C72.1893 16.8489 72.0309 16.8226 71.8511 16.8226C71.4573 16.8226 71.132 16.95 70.8752 17.205C70.6183 17.4599 70.4899 17.8599 70.4899 18.4049Z"
          fill="#2C3039"
        />
        <path
          d="M76.6998 22.0574C76.1433 22.0574 75.6382 21.9124 75.1845 21.6223C74.7393 21.3322 74.384 20.941 74.1187 20.4487C73.8618 19.9476 73.7334 19.3894 73.7334 18.7741C73.7334 18.15 73.8618 17.5918 74.1187 17.0995C74.384 16.5984 74.7393 16.2028 75.1845 15.9127C75.6382 15.6139 76.1433 15.4644 76.6998 15.4644C77.1706 15.4644 77.5859 15.5699 77.9454 15.7809C78.3135 15.9831 78.6046 16.2644 78.8186 16.6248C79.0327 16.9852 79.1397 17.394 79.1397 17.8511V19.6707C79.1397 20.1279 79.0327 20.5366 78.8186 20.897C78.6132 21.2575 78.3264 21.5432 77.9583 21.7541C77.5901 21.9563 77.1706 22.0574 76.6998 22.0574ZM76.9309 20.6861C77.2819 20.6861 77.5859 20.6069 77.8427 20.4487C78.1081 20.2817 78.3135 20.0531 78.4591 19.763C78.6046 19.473 78.6774 19.1389 78.6774 18.7609C78.6774 18.3829 78.6046 18.0489 78.4591 17.7588C78.3135 17.4687 78.1081 17.2445 77.8427 17.0863C77.5859 16.9193 77.2819 16.8358 76.9309 16.8358C76.5885 16.8358 76.2846 16.9193 76.0192 17.0863C75.7624 17.2445 75.5569 17.4687 75.4028 17.7588C75.2573 18.0489 75.1845 18.3829 75.1845 18.7609C75.1845 19.1389 75.2573 19.473 75.4028 19.763C75.5569 20.0531 75.7624 20.2817 76.0192 20.4487C76.2846 20.6069 76.5885 20.6861 76.9309 20.6861ZM80.0001 21.9255H78.5875V20.2246L78.8315 18.6818L78.5875 17.1522V12.4316H80.0001V21.9255Z"
          fill="#2C3039"
        />
        <path
          d="M27.4942 14.7762C27.2005 15.3068 26.8384 15.7944 26.418 16.2258C26.3294 16.3318 26.2094 16.405 26.0767 16.4345C25.9439 16.4637 25.8056 16.4476 25.6828 16.3884C25.617 16.3498 25.5926 16.2645 25.6275 16.1952L27.0323 13.4435L27.0412 13.4279C27.1727 13.1873 27.1578 12.9248 27.0101 12.8372C26.8625 12.7496 26.6323 12.872 26.4972 13.1089L26.4845 13.1136L24.9586 15.6004C24.8893 15.7395 24.7271 15.7998 24.5878 15.7382L24.3773 15.6147C24.2555 15.5204 24.2269 15.3447 24.3119 15.214L25.6381 12.6145L25.647 12.5989C25.7784 12.3583 25.7635 12.0958 25.6159 12.0082C25.4682 11.9206 25.2402 12.0422 25.1043 12.277L25.0916 12.2817L23.482 14.9127C23.4419 14.9787 23.3582 14.9999 23.2929 14.9607C23.1809 14.8816 23.0974 14.7667 23.0548 14.6337C23.0124 14.5008 23.0135 14.357 23.058 14.2246C23.2088 13.6346 23.4363 13.068 23.7343 12.5402C24.6383 10.9259 25.3977 9.56948 26.7181 9.81635C26.9599 9.8618 27.203 9.74161 27.3202 9.51889L28.7658 6.48368C28.8432 6.34533 28.972 6.24537 29.1223 6.20665C29.2727 6.16792 29.4318 6.19386 29.5627 6.27859L29.8337 6.43853C29.9671 6.51723 30.0639 6.64794 30.102 6.80131C30.1401 6.95468 30.1164 7.11747 30.0363 7.25303L28.2288 10.0737C28.1001 10.2896 28.1209 10.5657 28.2805 10.7576C29.1548 11.8045 28.3962 13.1628 27.4942 14.7762Z"
          fill="#2C3039"
        />
        <path
          d="M26.133 20.5259C28.0428 20.0004 29.2319 17.9487 28.0517 16.3694C27.3386 15.4152 26.4495 14.6145 25.4351 14.0131C24.4207 13.4117 23.3008 13.0214 22.1395 12.8644C20.2259 12.6057 19.0638 14.6201 19.5537 16.4975L20.1292 18.7026C20.5964 20.4933 22.4218 21.547 24.2061 21.056L26.133 20.5259Z"
          fill="#B2AEF8"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.7888 18.6114C27.562 18.1227 27.2855 17.6585 26.963 17.227C26.3596 16.4196 25.6073 15.7421 24.7489 15.2332C23.8906 14.7244 22.943 14.3941 21.9604 14.2613C21.4352 14.1903 20.9054 14.1764 20.3799 14.2191L21.9456 20.2191L27.7888 18.6114ZM29.2251 18.4626C29.4652 19.0625 29.0964 19.7104 28.4864 19.8782L21.638 21.7625C21.272 21.8632 20.8957 21.6401 20.7977 21.2643L18.9626 14.2322C18.7992 13.6058 19.1613 12.9539 19.7872 12.8675C20.5661 12.76 21.357 12.7585 22.1395 12.8643C23.3008 13.0212 24.4206 13.4116 25.4351 14.013C26.4495 14.6143 27.3386 15.415 28.0517 16.3692C28.5321 17.0121 28.9263 17.7163 29.2251 18.4626Z"
          fill="#7E8289"
        />
        <ellipse
          cx="116"
          cy="16.6666"
          rx="5.33333"
          ry="5.33333"
          fill="#C8D6F8"
        />
        <rect
          x="126.667"
          y="12.6667"
          width="53.3333"
          height="8"
          rx="4"
          fill="url(#paint0_linear_2804_23265)"
        />
        <ellipse
          cx="201.333"
          cy="16.6666"
          rx="5.33333"
          ry="5.33333"
          fill="#C8D6F8"
        />
        <rect
          x="212"
          y="12.6667"
          width="34"
          height="8"
          rx="4"
          fill="url(#paint1_linear_2804_23265)"
        />
        <ellipse
          cx="267.333"
          cy="16.6666"
          rx="5.33333"
          ry="5.33333"
          fill="#C8D6F8"
        />
        <rect
          x="278"
          y="12.6667"
          width="46"
          height="8"
          rx="4"
          fill="url(#paint2_linear_2804_23265)"
        />
        <ellipse
          cx="345.333"
          cy="16.6666"
          rx="5.33333"
          ry="5.33333"
          fill="#C8D6F8"
        />
        <rect
          x="356"
          y="12.6667"
          width="34"
          height="8"
          rx="4"
          fill="url(#paint3_linear_2804_23265)"
        />
        <ellipse
          cx="411.333"
          cy="16.6666"
          rx="5.33333"
          ry="5.33333"
          fill="#C8D6F8"
        />
        <rect
          x="422"
          y="12.6667"
          width="34"
          height="8"
          rx="4"
          fill="url(#paint4_linear_2804_23265)"
        />
        <rect
          opacity="0.4"
          x="610.667"
          y="11"
          width="16"
          height="2"
          rx="1"
          fill="#5C61D7"
        />
        <rect
          opacity="0.4"
          x="610.667"
          y="15.6667"
          width="16"
          height="2"
          rx="1"
          fill="#5C61D7"
        />
        <rect
          opacity="0.4"
          x="610.667"
          y="20.3333"
          width="16"
          height="2"
          rx="1"
          fill="#5C61D7"
        />
        <path
          d="M640 32H0V34H640V32Z"
          fill="#DDE6FD"
          mask="url(#path-1-inside-1_2804_23265)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2804_23265"
            x1="180"
            y1="3.41675"
            x2="117.487"
            y2="46.2392"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E7EEFF" />
            <stop offset="1" stopColor="#DEE3EE" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2804_23265"
            x1="246"
            y1="3.41675"
            x2="196.825"
            y2="24.8912"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E7EEFF" />
            <stop offset="1" stopColor="#DEE3EE" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2804_23265"
            x1="324"
            y1="3.41675"
            x2="265.28"
            y2="38.1102"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E7EEFF" />
            <stop offset="1" stopColor="#DEE3EE" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2804_23265"
            x1="390"
            y1="3.41675"
            x2="340.825"
            y2="24.8912"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E7EEFF" />
            <stop offset="1" stopColor="#DEE3EE" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2804_23265"
            x1="456"
            y1="3.41675"
            x2="406.825"
            y2="24.8912"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E7EEFF" />
            <stop offset="1" stopColor="#DEE3EE" />
          </linearGradient>
        </defs>
      </svg>
    </Figure>
  );
};
