import { SvgIconProps } from '@mui/material';
import { ComponentType } from 'react';
import { CountryCode } from '../generated/graphql';
import { DashboardRoute, NewAccountRoute } from '../routes';
import { RouteType } from '../routes/types';

export interface SidebarLink {
  path: string;
  title: string;
  Icon?: ComponentType<SvgIconProps>;
  isNew?: boolean;
  isAccountingTier?: boolean;
  supportedCountries?: CountryCode[];
}

export const primaryLinks: (SidebarLink & { children?: RouteType[] })[] = [
  {
    path: DashboardRoute.path,
    title: DashboardRoute.title,
    Icon: DashboardRoute.icon,
  },
  {
    path: NewAccountRoute.path,
    title: NewAccountRoute.title,
    Icon: NewAccountRoute.icon,
  },
];
