import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateLayout } from '../../layout';
import { useAuth } from '../../providers/auth.provider';
import { CurrentUserProvider } from '../../providers/current-user.provider';
import { privateRoutes, publicRoutes } from '../../routes';

export const IndexPage = () => {
  const { logout, user } = useAuth();

  if (user) {
    return (
      <CurrentUserProvider user={user} logout={logout}>
        <PrivateLayout>
          <Routes>
            {privateRoutes.map(({ component, children, path }) => {
              const Component = component;
              if (children) {
                return (
                  <Route key={path} path={path} element={<Component />}>
                    {children.map(
                      ({
                        component: childComponent,
                        path: childPath,
                        children: subChildrenRoutes,
                      }) => {
                        const ChildComponent = childComponent;
                        if (subChildrenRoutes) {
                          return (
                            <Route
                              key={childPath}
                              path={childPath}
                              element={<ChildComponent />}
                            >
                              {subChildrenRoutes.map(
                                ({
                                  component: subChildComponent,
                                  path: subChildPath,
                                }) => {
                                  const SubChildComponent = subChildComponent;
                                  return (
                                    <Route
                                      key={subChildPath}
                                      path={subChildPath}
                                      element={<SubChildComponent />}
                                    />
                                  );
                                },
                              )}
                            </Route>
                          );
                        }
                        return (
                          <Route
                            key={childPath}
                            path={childPath}
                            element={<ChildComponent />}
                          />
                        );
                      },
                    )}
                  </Route>
                );
              }

              return <Route key={path} path={path} element={<Component />} />;
            })}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </PrivateLayout>
      </CurrentUserProvider>
    );
  }

  return (
    <Routes>
      {publicRoutes.map(
        ({
          title,
          path,
          component,
          // exact
        }) => {
          const Component = component;
          return <Route key={title} path={path} element={<Component />} />;
        },
      )}
      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
};
