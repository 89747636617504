import { ThemeOptions, alpha, createTheme } from '@mui/material';

const themeOptions: ThemeOptions = {
  drawer: {
    width: 80,
  },
  dashboardDrawer: {
    width: 250,
  },
  typography: {
    fontFamily: 'GT Walsheim Pro',
    h1: {
      fontFamily: 'Paytone one',
      fontWeight: 400,
      fontSize: 52,
      letterSpacing: 0,
    },
    h2: {
      fontFamily: 'Paytone one',
      fontWeight: 400,
      fontSize: 36,
      letterSpacing: 0,
    },
    h3: {
      fontFamily: 'Paytone one',
      fontWeight: 400,
      fontSize: 20,
      letterSpacing: 0,
    },
    subtitle1: {
      fontFamily: 'GT Walsheim Pro',
      fontWeight: 700,
      fontSize: 18,
      letterSpacing: 0,
    },
    subtitle2: {
      fontFamily: 'GT Walsheim Pro',
      fontWeight: 500,
      fontSize: 16,
      letterSpacing: 0,
    },
    body1: {
      fontFamily: 'GT Walsheim Pro',
      fontWeight: 400,
      fontSize: 16,
      letterSpacing: 0,
    },
    body2: {
      fontFamily: 'GT Walsheim Pro',
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: 0,
    },
    button: {
      fontFamily: 'GT Walsheim Pro',
      fontWeight: 500,
      fontSize: 18,
      letterSpacing: 0,
      lineHeight: '27px',
    },
    caption: {
      fontFamily: 'GT Walsheim Pro',
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: 0.4,
    },
  },
  mainContentPadding: {
    desktop: {
      horizontal: 40,
      vertical: 24,
    },
    mobile: {
      horizontal: 24,
      vertical: 24,
    },
  },
  toolbar: {
    desktopHeight: 54,
    mobileHeight: 54,
  },
  appbar: {
    desktopHeight: 72,
    mobileHeight: 64,
  },
  mixins: {
    toolbar: {
      minHeight: 54,
      '@media (min-width: 600px)': {
        minHeight: 54,
      },
      '@media (min-width: 0px) and (orientation: landscape)': {
        minHeight: 54,
      },
    },
  },
  palette: {
    primary: {
      main: '#5C61D7',
    },
    success: {
      main: '#1DAEA6',
    },
    error: {
      main: '#D94B75',
    },
    common: {
      black: '#2C3039',
      white: '#FAFBFF',
    },
    text: {
      primary: '#2C3039',
    },
    background: {
      paper: '#FAFBFF',
    },
    icon: {
      notActive: '#AEBBCB',
      highlighted: '#554DF0',
    },
    divider: '#DFE8FF',
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          background: 'inherit',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '6px',
          boxShadow: 'none',
        },
      },
      variants: [
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            backgroundColor: '#5C61D7',
            '&:hover': {
              backgroundColor: '#5C61D7',
            },
            '&:focus': {
              backgroundColor: '#4F48AB',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'success' },
          style: {
            backgroundColor: '#97EDDD99',
            color: '#1DAEA6',
            '&:hover': {
              color: '#2C3039',
              backgroundColor: alpha('#97EDDD', 0.6),
            },
            '&:focus': {
              backgroundColor: alpha('#97EDDD', 0.6),
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 300,
          color: '#2C3039',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: '#E7EEFF',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          position: 'absolute',
          bottom: 0,
          transform: 'translateY(100%)',
        },
      },
    },
  },
  background: {
    card: '#1991FF29',
    secondary: '#F2F3FA',
  },
  borderRadius: '10px',
};

const theme = createTheme(undefined, themeOptions);

export default theme;
