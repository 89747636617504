export const FilledZigZags = () => (
  <svg
    fill="none"
    height="134"
    viewBox="0 0 215 134"
    width="215"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#b2aef8">
      <path
        d="m214.702 0-94.324 94.3245-40.176-40.1756-80.202 80.2021h214.702z"
        opacity=".3"
      />
      <path d="m214 67-75 67.405h75z" />
    </g>
  </svg>
);
