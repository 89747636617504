/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-props-no-spreading */
import {
  AppBar,
  Badge,
  Box,
  BoxProps,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Toolbar,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { UserMenu } from '../modules/common/components';
import { privateRoutes } from '../routes';
import { routePaths } from '../routes/routePaths';
import { BurgerMenu } from './BurgerMenu';
import SiteLogo from './SiteLogo';

const StyledAppBar = styled(AppBar)`
  ${({ theme }) => `
        && {
            background-color: ${theme.palette.common.white};
            box-shadow: none;
            border-bottom: 1px solid ${theme.palette.divider};
            height: ${theme.appbar.mobileHeight}px;
            ${theme.breakpoints.up('md')} {
              height: ${theme.appbar.desktopHeight}px;
            }
        }
    `}
`;

const routesToRender = privateRoutes.filter(({ showInNavBar }) => showInNavBar);

const SiteLogoLink = (props: BoxProps) => (
  <Box width={108} height={32} {...props}>
    <Link to={routePaths.dashboard}>
      <SiteLogo />
    </Link>
  </Box>
);

const DesktopQuickLinks = () => {
  const theme = useTheme();

  return (
    <Box display="grid" gridAutoFlow="column" columnGap={1}>
      {routesToRender.map(({ path, icon: Icon, title }) => (
        <MenuItem
          sx={{
            borderRadius: '6px',
            '.MuiListItemIcon-root': {
              minWidth: 0,
              pr: 1.5,
              color: theme.palette.common.black,
            },
            color: theme.palette.common.black,
            ':hover': {
              color: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.04),
              '.MuiListItemIcon-root': {
                color: theme.palette.primary.main,
              },
            },
          }}
          component={Link}
          to={path}
          key={path}
        >
          {Icon ? (
            <ListItemIcon>
              <Icon fontSize="small" color="inherit" />
            </ListItemIcon>
          ) : null}
          <ListItemText disableTypography>
            <Badge
              color="error"
              overlap="rectangular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <Typography variant="subtitle2" color="inherit" lineHeight={1.5}>
                {title}
              </Typography>
            </Badge>
          </ListItemText>
        </MenuItem>
      ))}
    </Box>
  );
};
const IS_PROD = import.meta.env.PROD;
const EnvironmentBadge = () => {
  if (IS_PROD) {
    return null;
  }

  const label = <Typography variant="caption">DEV</Typography>;

  return <Chip label={label} color="primary" size="medium" />;
};

const AdminBadge = () => {
  const theme = useTheme();

  const label = <Typography variant="caption">ADMIN</Typography>;

  return (
    <Chip
      label={label}
      color="error"
      size="medium"
      sx={{
        backgroundColor: 'rgba(217, 75, 117, 0.08)',
        color: theme.palette.error.main,
      }}
    />
  );
};

const Navbar = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const appbarHeight = isDesktop
    ? theme.appbar.desktopHeight
    : theme.appbar.mobileHeight;
  const anchorRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Toolbar
        sx={{
          height: `${appbarHeight}px`,
        }}
      />
      <StyledAppBar ref={anchorRef}>
        <Box
          display="grid"
          gridAutoFlow="column"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          height="100%"
          color={theme.palette.common.black}
          position="relative"
        >
          <Box display="grid" gridAutoFlow="column" columnGap={1}>
            <Box
              display="grid"
              gridAutoFlow="column"
              columnGap={2}
              alignItems="center"
            >
              <BurgerMenu anchorRef={anchorRef} />
              {isDesktop ? <SiteLogoLink mb={0.5} /> : null}
              <AdminBadge />
              <Box height="16px" width="2px" bgcolor={theme.palette.divider} />
            </Box>
            {isDesktop ? <DesktopQuickLinks /> : null}
          </Box>
          {isDesktop ? null : <SiteLogoLink mb={0.5} />}
          <Box
            display="grid"
            gridAutoFlow="column"
            alignItems="center"
            columnGap={1}
          >
            <EnvironmentBadge />
            <UserMenu anchorRef={anchorRef} />
          </Box>
        </Box>
      </StyledAppBar>
    </>
  );
};

export default () => <Navbar />;
