/* eslint-disable max-len */
export const RedCircleQuarter = () => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M0 68C-7.58693e-07 59.0701 1.75887 50.2277 5.17619 41.9775C8.59351 33.7274 13.6024 26.2311 19.9167 19.9167C26.2311 13.6024 33.7274 8.59351 41.9775 5.17619C50.2277 1.75887 59.0701 4.13632e-07 68 0L68 68L0 68Z"
      fill="#D94B75"
    />
  </svg>
);
