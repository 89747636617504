import React from 'react';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

const TextField = ({ required, helperText, ...other }: TextFieldProps) => {
  return (
    <MuiTextField
      margin="normal"
      InputLabelProps={{ shrink: true, required }}
      variant="filled"
      helperText={helperText}
      fullWidth
      {...other}
    />
  );
};

export default TextField;
