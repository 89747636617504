/* eslint-disable max-len */
import { SvgIconProps } from '@mui/material';

const SiteLogo = ({
  width,
  height,
}: Pick<SvgIconProps, 'width' | 'height'>) => (
  <svg viewBox="0 0 105 27" width={width} height={height}>
    <path
      d="M21.7083 26.7803V16.2351H24.1249V26.7803H21.7083ZM22.9166 14.3019C22.5065 14.3019 22.1623 14.1627 21.884 13.8844C21.6204 13.6062 21.4886 13.262 21.4886 12.8519C21.4886 12.4418 21.6204 12.0976 21.884 11.8193C22.1623 11.5411 22.5065 11.4019 22.9166 11.4019C23.3413 11.4019 23.6855 11.5411 23.9491 11.8193C24.2128 12.0976 24.3446 12.4418 24.3446 12.8519C24.3446 13.262 24.2128 13.6062 23.9491 13.8844C23.6855 14.1627 23.3413 14.3019 22.9166 14.3019Z"
      fill="#2C3039"
    />
    <path
      d="M30.2845 27C29.6986 27 29.1348 26.9268 28.5929 26.7803C28.0509 26.6192 27.553 26.3995 27.099 26.1212C26.6449 25.8283 26.2495 25.4768 25.9126 25.0667L27.4505 23.5289C27.8166 23.9536 28.234 24.2758 28.7027 24.4955C29.186 24.7006 29.7279 24.8031 30.3284 24.8031C30.8703 24.8031 31.2804 24.7225 31.5587 24.5614C31.837 24.4003 31.9761 24.166 31.9761 23.8584C31.9761 23.5362 31.8443 23.2872 31.5807 23.1115C31.317 22.9357 30.9728 22.7892 30.5481 22.6721C30.138 22.5403 29.6986 22.4084 29.23 22.2766C28.7759 22.1448 28.3365 21.9691 27.9118 21.7494C27.5017 21.515 27.1649 21.2001 26.9012 20.8047C26.6376 20.4093 26.5058 19.8966 26.5058 19.2669C26.5058 18.5931 26.6596 18.0146 26.9671 17.5313C27.2894 17.048 27.7361 16.6745 28.3073 16.4109C28.8931 16.1473 29.5888 16.0154 30.3943 16.0154C31.2438 16.0154 31.9907 16.1692 32.6352 16.4768C33.2942 16.7697 33.8435 17.2164 34.2828 17.8169L32.745 19.3547C32.4374 18.9739 32.0859 18.6883 31.6905 18.4979C31.2951 18.3076 30.841 18.2124 30.3284 18.2124C29.8451 18.2124 29.4716 18.2856 29.208 18.432C28.9444 18.5785 28.8125 18.7909 28.8125 19.0691C28.8125 19.3621 28.9444 19.5891 29.208 19.7502C29.4716 19.9113 29.8085 20.0504 30.2186 20.1676C30.6433 20.2848 31.0827 20.4166 31.5367 20.563C32.0054 20.6949 32.4448 20.8853 32.8549 21.1342C33.2796 21.3686 33.6238 21.6908 33.8874 22.1009C34.151 22.4963 34.2828 23.0163 34.2828 23.6607C34.2828 24.6859 33.924 25.4988 33.2064 26.0993C32.4887 26.6997 31.5147 27 30.2845 27Z"
      fill="#2C3039"
    />
    <path
      d="M65.9523 27C64.9271 27 63.9971 26.7583 63.1623 26.275C62.3274 25.777 61.661 25.1106 61.1631 24.2758C60.6798 23.441 60.4381 22.511 60.4381 21.4857C60.4381 20.4605 60.6798 19.5378 61.1631 18.7176C61.661 17.8975 62.3274 17.2457 63.1623 16.7624C63.9971 16.2644 64.9271 16.0154 65.9523 16.0154C66.9922 16.0154 67.9295 16.2571 68.7644 16.7404C69.5992 17.2237 70.2583 17.8828 70.7416 18.7176C71.2396 19.5378 71.4885 20.4605 71.4885 21.4857C71.4885 22.511 71.2396 23.441 70.7416 24.2758C70.2583 25.1106 69.5992 25.777 68.7644 26.275C67.9295 26.7583 66.9922 27 65.9523 27ZM65.9523 24.6713C66.5528 24.6713 67.0801 24.5395 67.5341 24.2758C68.0028 23.9975 68.3616 23.6168 68.6106 23.1334C68.8742 22.6501 69.006 22.1009 69.006 21.4857C69.006 20.8706 68.8742 20.3287 68.6106 19.86C68.347 19.3914 67.9881 19.0252 67.5341 18.7616C67.0801 18.4833 66.5528 18.3442 65.9523 18.3442C65.3665 18.3442 64.8392 18.4833 64.3706 18.7616C63.9165 19.0252 63.5577 19.3914 63.2941 19.86C63.0451 20.3287 62.9206 20.8706 62.9206 21.4857C62.9206 22.1009 63.0451 22.6501 63.2941 23.1334C63.5577 23.6168 63.9165 23.9975 64.3706 24.2758C64.8392 24.5395 65.3665 24.6713 65.9523 24.6713Z"
      fill="#2C3039"
    />
    <path
      d="M15.102 14.8686C14.5996 15.7526 13.9801 16.565 13.2609 17.2838C13.1093 17.4603 12.904 17.5823 12.6769 17.6314C12.4497 17.6802 12.2131 17.6532 12.0031 17.5547C11.8905 17.4903 11.8488 17.3483 11.9085 17.2327L14.3118 12.6482L14.3271 12.6221C14.5519 12.2213 14.5265 11.784 14.2739 11.638C14.0212 11.492 13.6274 11.696 13.3963 12.0907L13.3746 12.0986L10.7642 16.2417C10.6456 16.4735 10.368 16.5739 10.1298 16.4713L9.76958 16.2657C9.56119 16.1085 9.51236 15.8157 9.65771 15.5979L11.9265 11.267L11.9418 11.2409C12.1666 10.8401 12.1412 10.4028 11.8886 10.2568C11.6359 10.1108 11.2459 10.3134 11.0133 10.7047L10.9916 10.7125L8.23795 15.0961C8.16937 15.206 8.02611 15.2413 7.91452 15.176C7.72291 15.0442 7.57993 14.8528 7.50719 14.6312C7.43455 14.4098 7.43649 14.1702 7.51256 13.9496C7.77065 12.9665 8.15977 12.0226 8.66957 11.1431C10.2162 8.45367 11.5153 6.19368 13.7742 6.605C14.1879 6.68071 14.6039 6.48047 14.8043 6.1094L17.2774 1.05244C17.4099 0.821938 17.6301 0.655407 17.8874 0.590887C18.1446 0.526359 18.4167 0.569582 18.6408 0.710756L19.1045 0.977226C19.3327 1.10834 19.4982 1.32612 19.5634 1.58165C19.6285 1.83718 19.588 2.10841 19.451 2.33426L16.3588 7.0338C16.1386 7.39345 16.1741 7.85341 16.4473 8.17313C17.9429 9.91736 16.6452 12.1806 15.102 14.8686Z"
      fill="#2C3039"
    />
    <path
      d="M12.9009 24.4139C16.0977 23.5574 18.0704 20.1486 16.0557 17.523C14.8358 15.9332 13.3147 14.5992 11.5793 13.5973C9.84384 12.5953 7.92804 11.945 5.94126 11.6834C2.66006 11.2514 0.694381 14.6643 1.55095 17.861L2.46842 21.2851C3.28639 24.3378 6.42417 26.1494 9.47685 25.3314L12.9009 24.4139Z"
      fill="#B2AEF8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6059 21.2585C15.218 20.4443 14.7449 19.671 14.1932 18.952C13.1609 17.6068 11.8738 16.478 10.4054 15.6302C8.93693 14.7824 7.31587 14.2321 5.63475 14.0108C4.73624 13.8925 3.82999 13.8694 2.93087 13.9406L5.60942 23.9371L15.6059 21.2585ZM18.0631 21.0107C18.4739 22.01 17.843 23.0895 16.7994 23.3692L5.08321 26.5085C4.45702 26.6763 3.81338 26.3047 3.64559 25.6785L0.506258 13.9624C0.226612 12.9187 0.846031 11.8326 1.91687 11.6887C3.24936 11.5096 4.60263 11.5072 5.94126 11.6834C7.92804 11.945 9.84384 12.5953 11.5793 13.5973C13.3147 14.5992 14.8358 15.9332 16.0557 17.523C16.8777 18.5942 17.552 19.7672 18.0631 21.0107Z"
      fill="#2C3039"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.503 20.6949V26.7803H45.9196V20.1676C45.9196 19.3474 45.7439 18.6298 45.3923 18.0146C45.0408 17.3848 44.5575 16.8942 43.9424 16.5427C43.3273 16.1912 42.6242 16.0154 41.8334 16.0154C41.0571 16.0154 40.3541 16.1985 39.7243 16.5647C39.3244 16.7932 38.9802 17.0805 38.6918 17.4266V10.9625H36.2752V26.7803H38.6918V20.6949C38.6918 20.2262 38.7943 19.8088 38.9993 19.4426C39.2044 19.0765 39.49 18.7909 39.8561 18.5858C40.2223 18.3808 40.6397 18.2783 41.1084 18.2783C41.8114 18.2783 42.3826 18.5053 42.822 18.9593C43.276 19.4133 43.503 19.9918 43.503 20.6949ZM38.6918 20.6949V17.4266C38.689 17.4299 38.6863 17.4332 38.6836 17.4365C38.5316 17.6201 38.3952 17.8201 38.2744 18.0366C37.9229 18.6517 37.7471 19.3621 37.7471 20.1676L38.6918 20.6949Z"
      fill="#2C3039"
    />
    <path
      d="M37.7471 20.1676L38.6918 20.6949V17.4266L38.6836 17.4365C38.5316 17.6201 38.3952 17.8201 38.2744 18.0366C37.9229 18.6517 37.7471 19.3621 37.7471 20.1676Z"
      fill="#2C3039"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M51.8684 26.4947C52.4982 26.8316 53.2159 27 54.0214 27C54.9734 27 55.8302 26.7583 56.5918 26.275C57.368 25.7917 57.9758 25.1399 58.4152 24.3198C58.8692 23.4849 59.0963 22.5549 59.0963 21.5297C59.0963 20.4898 58.8692 19.5598 58.4152 18.7396C57.9758 17.9048 57.368 17.2457 56.5918 16.7624C55.8302 16.2644 54.9734 16.0154 54.0214 16.0154C53.2305 16.0154 52.5202 16.1912 51.8904 16.5427C51.4637 16.7709 51.0976 17.0596 50.7919 17.4088V10.9625H48.3753V26.7803H50.7919V25.634C51.0931 25.9754 51.452 26.2623 51.8684 26.4947ZM49.8473 23.0236V19.9918C49.8473 19.2303 50.0303 18.5492 50.3965 17.9487C50.5109 17.7611 50.6382 17.5863 50.7784 17.4244C50.7829 17.4192 50.7874 17.414 50.7919 17.4088V18.8275L50.3745 21.3759L50.7919 23.9463V25.634C50.6377 25.4592 50.4985 25.2701 50.3745 25.0667C50.023 24.4662 49.8473 23.7852 49.8473 23.0236ZM55.1638 24.3198C54.7244 24.5834 54.2118 24.7152 53.6259 24.7152C53.0401 24.7152 52.5202 24.5834 52.0661 24.3198C51.6121 24.0415 51.2606 23.6607 51.0116 23.1774C50.7626 22.694 50.6382 22.1375 50.6382 21.5077C50.6382 20.8779 50.7626 20.3214 51.0116 19.8381C51.2606 19.3547 51.6121 18.9813 52.0661 18.7176C52.5202 18.4394 53.0401 18.3002 53.6259 18.3002C54.2118 18.3002 54.7244 18.4394 55.1638 18.7176C55.6178 18.9813 55.9693 19.3547 56.2183 19.8381C56.4819 20.3214 56.6138 20.8779 56.6138 21.5077C56.6138 22.1375 56.4819 22.694 56.2183 23.1774C55.9693 23.6607 55.6178 24.0415 55.1638 24.3198Z"
      fill="#2C3039"
    />
    <path
      d="M49.8473 23.0236C49.8473 23.7852 50.023 24.4662 50.3745 25.0667C50.4985 25.2701 50.6377 25.4592 50.7919 25.634V23.9463L50.3745 21.3759L50.7919 18.8275V17.4088L50.7784 17.4244C50.6382 17.5863 50.5109 17.7611 50.3965 17.9487C50.0303 18.5492 49.8473 19.2303 49.8473 19.9918V23.0236Z"
      fill="#2C3039"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M75.3216 26.275C76.0979 26.7583 76.962 27 77.914 27C78.7195 27 79.4372 26.8316 80.067 26.4947C80.488 26.2597 80.8468 25.9691 81.1434 25.6229V26.7803H83.56V16.2351H81.1434V17.4088C80.8378 17.0596 80.4716 16.7709 80.045 16.5427C79.4299 16.1912 78.7195 16.0154 77.914 16.0154C76.962 16.0154 76.0979 16.2644 75.3216 16.7624C74.56 17.2457 73.9522 17.9048 73.4982 18.7396C73.0588 19.5598 72.8391 20.4898 72.8391 21.5297C72.8391 22.5549 73.0588 23.4849 73.4982 24.3198C73.9522 25.1399 74.56 25.7917 75.3216 26.275ZM82.0881 19.9918V23.0236C82.0881 23.7852 81.905 24.4662 81.5389 25.0667C81.429 25.2544 81.3056 25.4299 81.1686 25.5932C81.1603 25.6031 81.1519 25.613 81.1434 25.6229V23.9463L81.5609 21.3759L81.1434 18.8275V17.4088C81.289 17.5751 81.4208 17.755 81.5389 17.9487C81.905 18.5492 82.0881 19.2303 82.0881 19.9918ZM80.4624 23.8145C79.9205 24.415 79.2028 24.7152 78.3094 24.7152C77.7236 24.7152 77.2037 24.5834 76.7496 24.3198C76.3102 24.0415 75.9587 23.6607 75.6951 23.1774C75.4461 22.694 75.3216 22.1375 75.3216 21.5077C75.3216 20.8779 75.4461 20.3214 75.6951 19.8381C75.9587 19.3547 76.3102 18.9813 76.7496 18.7176C77.2037 18.4394 77.7236 18.3002 78.3094 18.3002C78.9099 18.3002 79.4299 18.4394 79.8692 18.7176C80.3233 18.9813 80.6748 19.3547 80.9238 19.8381C81.1727 20.3214 81.2972 20.8779 81.2972 21.5077C81.2972 22.4451 81.019 23.214 80.4624 23.8145Z"
      fill="#2C3039"
    />
    <path
      d="M82.0881 19.9918C82.0881 19.2303 81.905 18.5492 81.5389 17.9487C81.4208 17.755 81.289 17.5751 81.1434 17.4088V18.8275L81.5609 21.3759L81.1434 23.9463V25.6229L81.1686 25.5932C81.3056 25.4299 81.429 25.2544 81.5389 25.0667C81.905 24.4662 82.0881 23.7852 82.0881 23.0236V19.9918Z"
      fill="#2C3039"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M86.242 16.2351V26.7803H88.6586V20.9146C88.6586 20.0065 88.8783 19.3401 89.3177 18.9154C89.757 18.4906 90.3136 18.2783 90.9873 18.2783C91.2949 18.2783 91.5658 18.3222 91.8002 18.4101C92.0345 18.498 92.2469 18.6444 92.4373 18.8495L94.019 17.2237C93.6529 16.799 93.2575 16.4914 92.8327 16.301C92.408 16.1106 91.932 16.0154 91.4047 16.0154C90.2037 16.0154 89.303 16.4329 88.7025 17.2677C88.6877 17.2884 88.673 17.3094 88.6586 17.3305V16.2351H86.242ZM88.6586 17.3305V20.9146L87.8238 20.4971C87.8238 19.3309 88.048 18.361 88.4966 17.5873C88.5477 17.4991 88.6017 17.4135 88.6586 17.3305Z"
      fill="#2C3039"
    />
    <path
      d="M87.8238 20.4971L88.6586 20.9146V17.3305C88.6017 17.4135 88.5477 17.4991 88.4966 17.5873C88.048 18.361 87.8238 19.3309 87.8238 20.4971Z"
      fill="#2C3039"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.69 26.275C97.4663 26.7583 98.3304 27 99.2824 27C100.088 27 100.806 26.8316 101.435 26.4947C101.856 26.2597 102.215 25.9691 102.512 25.6229V26.7803H104.928V10.9625H102.512V17.4088C102.206 17.0596 101.84 16.7709 101.413 16.5427C100.798 16.1912 100.088 16.0154 99.2824 16.0154C98.3304 16.0154 97.4663 16.2644 96.69 16.7624C95.9284 17.2457 95.3206 17.9048 94.8666 18.7396C94.4272 19.5598 94.2075 20.4898 94.2075 21.5297C94.2075 22.5549 94.4272 23.4849 94.8666 24.3198C95.3206 25.1399 95.9284 25.7917 96.69 26.275ZM103.456 19.9918V23.0236C103.456 23.7852 103.273 24.4662 102.907 25.0667C102.797 25.2544 102.674 25.4299 102.537 25.5932C102.529 25.6031 102.52 25.613 102.512 25.6229V23.9463L102.929 21.3759L102.512 18.8275V17.4088C102.657 17.5751 102.789 17.755 102.907 17.9487C103.273 18.5492 103.456 19.2303 103.456 19.9918ZM101.238 24.3198C100.798 24.5834 100.278 24.7152 99.6778 24.7152C99.092 24.7152 98.572 24.5834 98.118 24.3198C97.6786 24.0415 97.3271 23.6607 97.0635 23.1774C96.8145 22.694 96.69 22.1375 96.69 21.5077C96.69 20.8779 96.8145 20.3214 97.0635 19.8381C97.3271 19.3547 97.6786 18.9813 98.118 18.7176C98.572 18.4394 99.092 18.3002 99.6778 18.3002C100.278 18.3002 100.798 18.4394 101.238 18.7176C101.692 18.9813 102.043 19.3547 102.292 19.8381C102.541 20.3214 102.666 20.8779 102.666 21.5077C102.666 22.1375 102.541 22.694 102.292 23.1774C102.043 23.6607 101.692 24.0415 101.238 24.3198Z"
      fill="#2C3039"
    />
    <path
      d="M103.456 19.9918C103.456 19.2303 103.273 18.5492 102.907 17.9487C102.789 17.755 102.657 17.5751 102.512 17.4088V18.8275L102.929 21.3759L102.512 23.9463V25.6229L102.537 25.5932C102.674 25.4299 102.797 25.2544 102.907 25.0667C103.273 24.4662 103.456 23.7852 103.456 23.0236V19.9918Z"
      fill="#2C3039"
    />
  </svg>
);

export default SiteLogo;
