export const BarGraph = () => (
  <svg
    fill="none"
    height="97"
    viewBox="0 0 141 97"
    width="141"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#e7eeff" opacity=".6">
      <path d="m119.636 43.7033h21.364v53.2967h-21.364z" />
      <path d="m111.091 17.055h-21.3637v79.945h21.3637z" />
      <path d="m21.3636 43.7033h-21.36360764l-.00000932 53.2967h21.36361696z" />
      <path d="m81.1818.00001419-21.3636-.00000373v96.99998954h21.3636z" />
      <path d="m51.2727 29.3132h-21.3636v67.6868h21.3636z" />
    </g>
  </svg>
);
