import { Box, Button } from '@mui/material';
import { useFormik } from 'formik';
import { useAuth } from '../../../providers/auth.provider';
import { PasswordField, TextField } from '../../../ui';
import { loginFormValidation } from './validation';

export const LoginForm = () => {
  const { login, isLoginLoading } = useAuth();
  const {
    handleSubmit,
    isSubmitting,
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
  } = useFormik({
    initialValues: { email: '', password: '' },
    onSubmit: async ({ email, password }) => {
      try {
        await login({ email, password });
      } catch (e) {
        console.error(e);
      }
    },
    validationSchema: loginFormValidation,
  });
  return (
    <Box component="form" onSubmit={handleSubmit} display="grid" rowGap={2.5}>
      <TextField
        id="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
        sx={{ borderRadius: '8px' }}
      />
      <PasswordField
        id="password"
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.password && Boolean(errors.password)}
        helperText={touched.password && errors.password}
        sx={{ borderRadius: '8px' }}
      />
      <Button
        variant="contained"
        disabled={isSubmitting || isLoginLoading}
        type="submit"
        sx={{ minWidth: 160, justifySelf: 'end' }}
      >
        Login
      </Button>
    </Box>
  );
};
