import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import {
  useArchivePendingAccountMutation,
  useConfirmPendingAccountMutation,
  useEditPendingAccountMutation,
  usePendingAccountsQuery,
  useUnarchivePendingAccountMutation,
} from '../../generated/graphql';
import { NewAccountConfirmationDialog } from './NewAccountConfirmationDialog';
import { PendingAccountCard } from './PendingAccountCard';
import { PendingAccount } from './types';
import { UpdatePendingAccountDialog } from './UpdatePendingAccountDialog';

export const PendingAccountList = () => {
  const { data, loading } = usePendingAccountsQuery();

  const [editPendingAccount] = useEditPendingAccountMutation();
  const [archivePendingAccount] = useArchivePendingAccountMutation();
  const [unarchivePendingAccount] = useUnarchivePendingAccountMutation();
  const [confirmPendingAccount] = useConfirmPendingAccountMutation();

  const [editingPendingAccount, setEditingPendingAccount] = useState<
    (PendingAccount & { id: string }) | null
  >(null);

  const [venueToArchiveId, setVenueToArchiveId] = useState<string | null>(null);
  const [venueToUnarchiveId, setVenueToUnarchiveId] = useState<string | null>(
    null,
  );

  const [venueToCreate, setVenueToCreate] = useState<
    (PendingAccount & { id: string }) | null
  >(null);

  const [selectedTab, setSelectedTab] = useState(0);

  const sortedAccounts = useMemo(
    () =>
      data
        ? [...data.pendingAccounts].sort(
            (a, b) =>
              new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
          )
        : null,
    [data],
  );

  const filteredAccounts = useMemo(() => {
    if (!sortedAccounts) return null;

    if (selectedTab === 0)
      return sortedAccounts.filter((v) => !v.deletedAt && !v.isSent);
    if (selectedTab === 1) return sortedAccounts.filter((v) => v.isSent);
    if (selectedTab === 2) return sortedAccounts.filter((v) => !!v.deletedAt);

    return null;
  }, [sortedAccounts, selectedTab]);

  const handlePendingAccountEdit =
    (venue: PendingAccount & { id: string }) => () => {
      setEditingPendingAccount(venue);
    };

  const handlePendingAccountEditConfirm =
    (pendingAccountId?: string) => (venue: PendingAccount) => {
      if (!pendingAccountId) return;

      editPendingAccount({
        variables: {
          id: pendingAccountId,
          venueName: venue.venueName,
          country: venue.venueCountry,
          email: venue.email,
          firstName: venue.firstName,
          lastName: venue.lastName,
        },
        toastConfig: {
          successText: 'Pending venue updated successfully',
          errorText: 'Failed to update pending venue',
        },
      });

      closeEditDialog();
    };

  const handlePendingAccountArchive = (id: string) => () =>
    setVenueToArchiveId(id);
  const handlePendingAccountArchiveConfirm = () => {
    if (!venueToArchiveId) return;

    archivePendingAccount({
      variables: {
        id: venueToArchiveId,
      },
      toastConfig: {
        successText: 'Pending venue archived successfully',
        errorText: 'Failed to archive pending venue',
      },
    });
    closeArchiveDialog();
  };

  const handlePendingAccountUnarchive = (id: string) => () =>
    setVenueToUnarchiveId(id);
  const handlePendingAccountUnarchiveConfirm = () => {
    if (!venueToUnarchiveId) return;

    unarchivePendingAccount({
      variables: {
        id: venueToUnarchiveId,
      },
      toastConfig: {
        successText: 'Pending venue unarchived successfully',
        errorText: 'Failed to unarchive pending venue',
      },
    });
    closeUnarchiveDialog();
  };

  const handlePendingAccountProceed =
    (venue: PendingAccount & { id: string }) => () =>
      setVenueToCreate(venue);

  const closeEditDialog = () => setEditingPendingAccount(null);
  const closeArchiveDialog = () => setVenueToArchiveId(null);
  const closeUnarchiveDialog = () => setVenueToUnarchiveId(null);

  const handleTabChange = (event: unknown, newValue: number) => {
    setSelectedTab(newValue);
  };

  const handleVenueCreateConfirm = ({
    doesAccountExist,
  }: {
    doesAccountExist: boolean;
  }) => {
    if (!venueToCreate) return;

    confirmPendingAccount({
      variables: {
        pendingAccountId: venueToCreate.id,
        doesAccountExist,
      },
      toastConfig: {
        successText: 'Pending venue confirmed successfully',
        errorText: 'Failed to confirm pending venue',
      },
    });

    setVenueToCreate(null);
  };

  return (
    <>
      <Box display="flex" flexDirection="column">
        <Typography variant="h3">Pending accounts</Typography>

        {loading ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : null}

        {!loading && filteredAccounts ? (
          <Box display="flex" flexDirection="column" gap={2} mt={2}>
            <Tabs value={selectedTab} onChange={handleTabChange} centered>
              <Tab label="Pending" />
              <Tab label="Created" />
              <Tab label="Archived" />
            </Tabs>
            {filteredAccounts.map((account) => {
              const pendingAccount: PendingAccount & { id: string } = {
                id: account.id,
                venueName: account.venue.name,
                venueCountry: account.venue.legalEntity.country,
                email: account.email,
                firstName: account.firstName,
                lastName: account.lastName,
              };

              return (
                <PendingAccountCard
                  key={account.id}
                  pendingAccount={pendingAccount}
                  isSent={account.isSent}
                  isDeleted={!!account.deletedAt}
                  onEdit={handlePendingAccountEdit(pendingAccount)}
                  onArchive={handlePendingAccountArchive(account.id)}
                  onUnarchive={handlePendingAccountUnarchive(account.id)}
                  onProceed={handlePendingAccountProceed(pendingAccount)}
                />
              );
            })}
          </Box>
        ) : null}
      </Box>
      <UpdatePendingAccountDialog
        pendingAccount={editingPendingAccount}
        onClose={closeEditDialog}
        onConfirm={handlePendingAccountEditConfirm(editingPendingAccount?.id)}
      />
      <NewAccountConfirmationDialog
        pendingAccount={venueToCreate}
        onClose={() => setVenueToCreate(null)}
        onConfirm={handleVenueCreateConfirm}
        isFinalConfirm
      />
      <Dialog open={!!venueToArchiveId} onClose={closeArchiveDialog}>
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <Typography variant="h3">Archive pending venue</Typography>
          <Typography>
            Are you sure you want to archive this pending venue? You will always
            be able to unarchive it later.
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button color="inherit" onClick={closeArchiveDialog}>
              Cancel
            </Button>
            <Button color="error" onClick={handlePendingAccountArchiveConfirm}>
              Archive
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={!!venueToUnarchiveId} onClose={closeUnarchiveDialog}>
        <Box display="flex" flexDirection="column" gap={2} p={2}>
          <Typography variant="h3">Unarchive pending venue</Typography>
          <Typography>
            Are you sure you want to unarchive this pending venue? It will not
            be sent right away, you will still be able to Edit/Archive/Confirm
            it later.
          </Typography>
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button color="inherit" onClick={closeUnarchiveDialog}>
              Cancel
            </Button>
            <Button
              color="error"
              onClick={handlePendingAccountUnarchiveConfirm}
            >
              Unarchive
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
