/* eslint-disable @typescript-eslint/naming-convention */
import {
  Badge,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { SidebarLink } from './utils';

interface MenuListLinkProps extends SidebarLink {
  onClose: () => void;
  isActive: boolean;
  notificationCount?: number;
  isNew?: boolean;
}

export const MenuListLink = ({
  Icon,
  title,
  path,
  onClose,
  isActive,
  notificationCount,
  isNew,
}: MenuListLinkProps) => {
  const theme = useTheme();

  return (
    <MenuItem
      selected={isActive}
      component={Link}
      to={path}
      onClick={onClose}
      sx={{
        p: 1.5,
        '.MuiListItemIcon-root': { minWidth: 40 },
        borderRadius: '6px',
      }}
    >
      {Icon ? (
        <ListItemIcon>
          <Icon
            sx={{
              color: isActive
                ? theme.palette.primary.main
                : theme.palette.common.black,
            }}
            color="inherit"
          />
        </ListItemIcon>
      ) : null}
      <ListItemText disableTypography>
        <Badge
          color="error"
          overlap="rectangular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          badgeContent={notificationCount}
        >
          <Typography
            variant="subtitle2"
            lineHeight={1.5}
            pr={1}
            color={isActive ? theme.palette.primary.main : undefined}
          >
            {title}
          </Typography>
          {isNew ? (
            <Chip
              sx={{ borderRadius: '4px' }}
              label="New"
              color="primary"
              size="small"
            />
          ) : null}
        </Badge>
      </ListItemText>
    </MenuItem>
  );
};
