/* eslint-disable max-len */
export const CircleWithBubble = () => (
  <svg
    fill="none"
    height="226"
    viewBox="0 0 216 226"
    width="216"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#e7eeff">
      <path
        d="m108.42 223.691c-58.7743 0-106.42-47.646-106.42-106.419-.00001-58.7743 47.6457-106.4199 106.42-106.4199 58.773 0 106.419 47.6456 106.419 106.4199 0 58.773-47.646 106.419-106.419 106.419z"
        opacity=".4"
      />
      <path d="m108.919 101v-50c0-27.6142 22.386-49.99999 50-49.99999 27.615-.00001 50 22.38579 50 49.99999s-22.385 50-50 50z" />
    </g>
  </svg>
);
