import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { useAuth } from '../../providers/auth.provider';
import { Login } from './components';

const LoginPage = () => {
  const { isLoginLoading } = useAuth();
  return isLoginLoading ? (
    <Grid container justifyContent="center">
      <Grid item xs={6}>
        <Backdrop open>
          <CircularProgress />
        </Backdrop>
      </Grid>
    </Grid>
  ) : (
    <Login />
  );
};

export default LoginPage;
