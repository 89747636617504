/* eslint-disable react/jsx-props-no-spreading */
import { useField } from 'formik';
import {
  TextField as MuiTextField,
  TextFieldProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';

interface FormTextFieldProps
  extends Omit<
    TextFieldProps,
    'name' | 'value' | 'onBlur' | 'error' | 'helperText'
  > {
  name: string;
  number?: boolean;
  defaultHelperText?: string;
}

const FormTextField = ({
  required,
  name,
  number,
  InputProps,
  onChange: onChangeProp,
  defaultHelperText,
  ...other
}: FormTextFieldProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const [{ value, onBlur, onChange }, { error, touched }] = useField(name);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (number && Number.isNaN(Number(event.target.value))) {
      return;
    }

    onChange(event);

    if (onChangeProp) {
      onChangeProp(event);
    }
  };

  return (
    <MuiTextField
      margin="normal"
      InputLabelProps={{ shrink: true, required }}
      variant="filled"
      error={touched && Boolean(error)}
      helperText={defaultHelperText || (touched && error)}
      fullWidth
      value={value || ''}
      onBlur={onBlur}
      onChange={handleChange}
      onWheel={(e) => (e.target as HTMLElement).blur()}
      InputProps={{
        ...(number
          ? {
              type: isDesktop && isSafari ? 'text' : 'number',
            }
          : {}),
        ...InputProps,
      }}
      {...other}
    />
  );
};

export default FormTextField;
