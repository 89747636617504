/* eslint-disable max-len */
export const ColoredDots = () => (
  <svg
    width="116"
    height="50"
    viewBox="0 0 116 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.2558 40.5405C51.2558 45.7649 47.0285 50 41.814 50C36.5994 50 32.3721 45.7649 32.3721 40.5405C32.3721 35.3162 36.5994 31.0811 41.814 31.0811C47.0285 31.0811 51.2558 35.3162 51.2558 40.5405Z"
      fill="white"
    />
    <path
      d="M83.6279 40.5405C83.6279 45.7649 79.4007 50 74.1861 50C68.9715 50 64.7442 45.7649 64.7442 40.5405C64.7442 35.3162 68.9715 31.0811 74.1861 31.0811C79.4007 31.0811 83.6279 35.3162 83.6279 40.5405Z"
      fill="white"
    />
    <path
      d="M116 40.5405C116 45.7649 111.773 50 106.558 50C101.344 50 97.1163 45.7649 97.1163 40.5405C97.1163 35.3162 101.344 31.0811 106.558 31.0811C111.773 31.0811 116 35.3162 116 40.5405Z"
      fill="white"
    />
    <path
      d="M83.6279 9.45946C83.6279 14.6838 79.4007 18.9189 74.1861 18.9189C68.9715 18.9189 64.7442 14.6838 64.7442 9.45946C64.7442 4.23514 68.9715 0 74.1861 0C79.4007 0 83.6279 4.23514 83.6279 9.45946Z"
      fill="white"
    />
    <path
      d="M51.2558 9.45946C51.2558 14.6838 47.0285 18.9189 41.814 18.9189C36.5994 18.9189 32.3721 14.6838 32.3721 9.45946C32.3721 4.23514 36.5994 0 41.814 0C47.0285 0 51.2558 4.23514 51.2558 9.45946Z"
      fill="white"
    />
    <path
      d="M18.8837 9.45946C18.8837 14.6838 14.6565 18.9189 9.44186 18.9189C4.22727 18.9189 0 14.6838 0 9.45946C0 4.23514 4.22727 0 9.44186 0C14.6565 0 18.8837 4.23514 18.8837 9.45946Z"
      fill="white"
    />
    <path
      d="M18.8837 40.5405C18.8837 45.7649 14.6565 50 9.44186 50C4.22727 50 0 45.7649 0 40.5405C0 35.3162 4.22727 31.0811 9.44186 31.0811C14.6565 31.0811 18.8837 35.3162 18.8837 40.5405Z"
      fill="#FFBEE9"
    />
    <path
      d="M116 9.45946C116 14.6838 111.773 18.9189 106.558 18.9189C101.344 18.9189 97.1163 14.6838 97.1163 9.45946C97.1163 4.23514 101.344 0 106.558 0C111.773 0 116 4.23514 116 9.45946Z"
      fill="#FFBEE9"
    />
  </svg>
);
