import { CloseRounded } from '@mui/icons-material';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { PendingAccountForm } from './PendingAccountForm';
import { PendingAccount } from './types';

export interface UpdatePendingAccountDialogProps {
  pendingAccount: PendingAccount | null;
  onClose: () => void;
  onConfirm: (venue: PendingAccount) => void;
}

export const UpdatePendingAccountDialog: React.FC<
  UpdatePendingAccountDialogProps
> = ({ pendingAccount, onClose, onConfirm }) => {
  return (
    <Dialog
      open={!!pendingAccount}
      onClose={onClose}
      fullWidth
      sx={{ maxWidth: '844px', margin: 'auto' }}
    >
      <Box display="flex" flexDirection="column" px={2} py={2}>
        <Box
          display="grid"
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          gridAutoFlow="column"
        >
          <Typography lineHeight={1.2} variant="h3">
            Update pending venue
          </Typography>
          <IconButton
            sx={{ padding: 0 }}
            onClick={onClose}
            color="primary"
            aria-label="close-dialog"
            component="span"
          >
            <CloseRounded />
          </IconButton>
        </Box>

        {pendingAccount ? (
          <PendingAccountForm
            initialValues={pendingAccount}
            onSubmit={onConfirm}
          />
        ) : null}
      </Box>
    </Dialog>
  );
};
