import { PersonAdd } from '@mui/icons-material';
import { NewAccount } from '../modules';
import { RouteType } from './types';
import { routePaths } from './routePaths';

const NewAccountRoute: RouteType = {
  exact: true,
  icon: PersonAdd,
  showInNavBar: true,
  path: routePaths.newAccount,
  title: 'New Account',
  component: () => <NewAccount />,
};

export default NewAccountRoute;
