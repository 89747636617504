import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '../graphql/customGqlComponents';
import * as ApolloReactHooks from '../graphql/customGqlHooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
}

export interface AccessItemInputType {
  role: UserRole;
  venueId: Scalars['ID'];
}

export interface Account {
  __typename?: 'Account';
  canEditVenue: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  cultureCode?: Maybe<CultureCode>;
  defaultUserId?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  languageCode?: Maybe<CultureCode>;
  lastName: Scalars['String'];
  users: Array<User>;
}

export interface AccountingCategory {
  __typename?: 'AccountingCategory';
  createdAt: Scalars['Date'];
  createdBy: CreatedOrUpdatedByObjectType;
  createdById: Scalars['ID'];
  creditAccount?: Maybe<Scalars['String']>;
  debitAccount?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<CreatedOrUpdatedByObjectType>;
  deletedById?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface AccountingExpenseItemObjectType {
  __typename?: 'AccountingExpenseItemObjectType';
  amount: Scalars['Int'];
  id: Scalars['ID'];
  paymentMethod: ExpensePaymentMethod;
  purchaseDate: Scalars['Date'];
  supplierName: Scalars['String'];
  wasExported: Scalars['Boolean'];
}

export interface AccountingExpensesObjectType {
  __typename?: 'AccountingExpensesObjectType';
  oneTimeExpenses: Array<AccountingExpenseItemObjectType>;
  splitExpenses: Array<AccountingExpenseItemObjectType>;
  supplyExpenses: Array<AccountingExpenseItemObjectType>;
}

export enum AccountingSystem {
  AbraFlexi = 'AbraFlexi',
  Duel = 'Duel',
  MoneyS3 = 'MoneyS3',
  Pohoda = 'Pohoda',
  Xero = 'Xero',
}

export interface AdminAccount {
  __typename?: 'AdminAccount';
  accountId: Scalars['ID'];
  canCreateNewVenues: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
}

export interface AdminPendingNewAccount {
  __typename?: 'AdminPendingNewAccount';
  adminAccountId: Scalars['ID'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  isSent: Scalars['Boolean'];
  lastName: Scalars['String'];
  venue: Venue;
  venueId: Scalars['ID'];
}

export interface Category {
  __typename?: 'Category';
  createdAt: Scalars['Date'];
  createdBy: CreatedOrUpdatedByObjectType;
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<CreatedOrUpdatedByObjectType>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parentCategory?: Maybe<Category>;
  parentCategoryId?: Maybe<Scalars['ID']>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export enum CountryCode {
  Afghanistan = 'Afghanistan',
  AlandIslands = 'AlandIslands',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'AmericanSamoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antarctica = 'Antarctica',
  AntiguaAndBarbuda = 'AntiguaAndBarbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  BosniaAndHerzegovina = 'BosniaAndHerzegovina',
  Botswana = 'Botswana',
  BouvetIsland = 'BouvetIsland',
  Brazil = 'Brazil',
  BritishIndianOceanTerritory = 'BritishIndianOceanTerritory',
  BruneiDarussalam = 'BruneiDarussalam',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'BurkinaFaso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'CapeVerde',
  CaymanIslands = 'CaymanIslands',
  CentralAfricanRepublic = 'CentralAfricanRepublic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  ChristmasIsland = 'ChristmasIsland',
  CocosKeelingIslands = 'CocosKeelingIslands',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CongoDemocraticRepublic = 'CongoDemocraticRepublic',
  CookIslands = 'CookIslands',
  CostaRica = 'CostaRica',
  CoteDIvoire = 'CoteDIvoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'CzechRepublic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'DominicanRepublic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'ElSalvador',
  EquatorialGuinea = 'EquatorialGuinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FalklandIslands = 'FalklandIslands',
  FaroeIslands = 'FaroeIslands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'FrenchGuiana',
  FrenchPolynesia = 'FrenchPolynesia',
  FrenchSouthernTerritories = 'FrenchSouthernTerritories',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  GuineaBissau = 'GuineaBissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  HeardIslandMcdonaldIslands = 'HeardIslandMcdonaldIslands',
  HolySeeVaticanCityState = 'HolySeeVaticanCityState',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'IsleOfMan',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  Korea = 'Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  LaoPeoplesDemocraticRepublic = 'LaoPeoplesDemocraticRepublic',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  LibyanArabJamahiriya = 'LibyanArabJamahiriya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'MarshallIslands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'NetherlandsAntilles',
  NewCaledonia = 'NewCaledonia',
  NewZealand = 'NewZealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'NorfolkIsland',
  NorthernMarianaIslands = 'NorthernMarianaIslands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  PalestinianTerritory = 'PalestinianTerritory',
  Panama = 'Panama',
  PapuaNewGuinea = 'PapuaNewGuinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn = 'Pitcairn',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'PuertoRico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  RussianFederation = 'RussianFederation',
  Rwanda = 'Rwanda',
  SaintBarthelemy = 'SaintBarthelemy',
  SaintHelena = 'SaintHelena',
  SaintKittsAndNevis = 'SaintKittsAndNevis',
  SaintLucia = 'SaintLucia',
  SaintMartin = 'SaintMartin',
  SaintPierreAndMiquelon = 'SaintPierreAndMiquelon',
  SaintVincentAndGrenadines = 'SaintVincentAndGrenadines',
  Samoa = 'Samoa',
  SanMarino = 'SanMarino',
  SaoTomeAndPrincipe = 'SaoTomeAndPrincipe',
  SaudiArabia = 'SaudiArabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'SierraLeone',
  Singapore = 'Singapore',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'SolomonIslands',
  Somalia = 'Somalia',
  SouthAfrica = 'SouthAfrica',
  SouthGeorgiaAndSandwichIsl = 'SouthGeorgiaAndSandwichIsl',
  Spain = 'Spain',
  SriLanka = 'SriLanka',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  SvalbardAndJanMayen = 'SvalbardAndJanMayen',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  SyrianArabRepublic = 'SyrianArabRepublic',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  TimorLeste = 'TimorLeste',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'TrinidadAndTobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'TurksAndCaicosIslands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'UnitedArabEmirates',
  UnitedKingdom = 'UnitedKingdom',
  UnitedStates = 'UnitedStates',
  UnitedStatesOutlyingIslands = 'UnitedStatesOutlyingIslands',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  VietNam = 'VietNam',
  VirginIslandsBritish = 'VirginIslandsBritish',
  VirginIslandsUs = 'VirginIslandsUS',
  WallisAndFutuna = 'WallisAndFutuna',
  WesternSahara = 'WesternSahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}

export interface CreateAccountInputType {
  cultureCode?: InputMaybe<CultureCode>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}

export interface CreateBusinessEntityArgs {
  name: Scalars['String'];
}

export interface CreateExpenseAccountingInfoInputType {
  accountingCategoryId?: InputMaybe<Scalars['ID']>;
  bankAccountNumber: Scalars['String'];
  bankCode: Scalars['String'];
  invoiceType: InvoiceType;
  paymentMethod: ExpensePaymentMethod;
  senderAddress: Scalars['String'];
  taxPointDate: Scalars['Date'];
  taxes: Array<CreateExpenseTaxInputType>;
  variableSymbol: Scalars['String'];
  vatBreakdown?: InputMaybe<VatBreakdown>;
}

export interface CreateExpenseBankInfoInputType {
  bankAccountNumber: Scalars['String'];
  bankCode: Scalars['String'];
  variableSymbol: Scalars['String'];
}

export interface CreateExpenseTaxInputType {
  baseAmount: Scalars['Int'];
  rate: TaxRate;
  taxAmount: Scalars['Int'];
}

export interface CreateLegalEntityArgs {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyIdentificationNumber?: InputMaybe<Scalars['String']>;
  country: CountryCode;
  currencyCurrencyCode: CurrencyCode;
  district?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  taxIdentificationNumber?: InputMaybe<Scalars['String']>;
  venue: CreateVenueArgs;
}

export interface CreateRecurrencePatternInput {
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  endDate?: InputMaybe<Scalars['Date']>;
  interval: RecurrenceInterval;
  monthOfYear?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface CreateVenueArgs {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cultureCode: CultureCode;
  defaultTimezone: Scalars['String'];
  district?: InputMaybe<Scalars['String']>;
  languageCode: CultureCode;
  name: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
}

export interface CreatedOrUpdatedByObjectType {
  __typename?: 'CreatedOrUpdatedByObjectType';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
}

export enum CultureCode {
  CsCz = 'cs_CZ',
  EnGb = 'en_GB',
  EnUs = 'en_US',
  SkSk = 'sk_SK',
}

export interface Currency {
  __typename?: 'Currency';
  currencyCode: CurrencyCode;
  precision: Scalars['Int'];
}

export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uyw = 'UYW',
  Uzs = 'UZS',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL',
}

export interface CurrentUserObjectType {
  __typename?: 'CurrentUserObjectType';
  client_id: Scalars['String'];
  cultureCode: CultureCode;
  currency: Currency;
  email: Scalars['String'];
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  languageCode: CultureCode;
  scope: ScopeObjectType;
  subscriptionData: SubscriptionDataObjectType;
  zoneinfo: Scalars['String'];
}

export interface DateFromToInputType {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export enum DayOfWeek {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed',
}

export interface EmailInvoiceUploadMeta {
  __typename?: 'EmailInvoiceUploadMeta';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  receivedAt: Scalars['Date'];
  receivedFrom: Scalars['String'];
}

export interface Employee {
  __typename?: 'Employee';
  createdAt: Scalars['Date'];
  createdBy: CreatedOrUpdatedByObjectType;
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<CreatedOrUpdatedByObjectType>;
  deletedById?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  integrationType?: Maybe<LaborIntegrationType>;
  lastName?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface ExistingAccountObjectType {
  __typename?: 'ExistingAccountObjectType';
  accountId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  venueNames: Array<Scalars['String']>;
}

export interface ExpenseAboExport {
  __typename?: 'ExpenseAboExport';
  aboExport: Scalars['String'];
  ocrExpenses: Array<OcrExpense>;
}

export interface ExpenseAccountingInfo {
  __typename?: 'ExpenseAccountingInfo';
  accountingCategoryId?: Maybe<Scalars['ID']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  exportedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  invoiceType: InvoiceType;
  paymentMethod: ExpensePaymentMethod;
  senderAddress: Scalars['String'];
  taxPointDate: Scalars['Date'];
  taxes: Array<ExpenseTax>;
  variableSymbol?: Maybe<Scalars['String']>;
  vatBreakdown?: Maybe<VatBreakdown>;
}

export interface ExpenseAgenda {
  __typename?: 'ExpenseAgenda';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  initialCount?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface ExpenseBankInfo {
  __typename?: 'ExpenseBankInfo';
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  variableSymbol?: Maybe<Scalars['String']>;
}

export enum ExpenseDateFilterType {
  AccountingDate = 'AccountingDate',
  DueDate = 'DueDate',
}

export enum ExpensePaymentMethod {
  Cash = 'Cash',
  CashOnDelivery = 'CashOnDelivery',
  Credit = 'Credit',
  Deposit = 'Deposit',
  PaymentCard = 'PaymentCard',
  Transfer = 'Transfer',
}

export interface ExpenseStructuredTotalAmountObjectType {
  __typename?: 'ExpenseStructuredTotalAmountObjectType';
  oneTimeExpensesTotalAmount: TotalAmountObjectType;
  oneTimeLaborExpensesTotalAmount: TotalAmountObjectType;
  recurringExpensesTotalAmount: TotalAmountObjectType;
  recurringLaborExpensesTotalAmount: TotalAmountObjectType;
  supplyExpensesTotalAmount: TotalAmountObjectType;
}

export interface ExpenseTax {
  __typename?: 'ExpenseTax';
  baseAmount: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  expenseAccountingInfoId: Scalars['ID'];
  id: Scalars['ID'];
  rate: TaxRate;
  taxAmount: Scalars['Int'];
}

export interface ExpenseTotalByCategoryObjectType {
  __typename?: 'ExpenseTotalByCategoryObjectType';
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  categoryId: Scalars['ID'];
  categoryName: Scalars['String'];
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  precision: Scalars['Int'];
}

export enum ExpenseType {
  OneTime = 'OneTime',
  Recurring = 'Recurring',
}

export interface ExpensesAmountsWithPaymentByMonthObjectType {
  __typename?: 'ExpensesAmountsWithPaymentByMonthObjectType';
  amount: Scalars['Int'];
  amountPaid: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  /** month 1-12 */
  isoMonth: Scalars['Int'];
  /** Year in format YYYY */
  isoYear: Scalars['Int'];
  precision: Scalars['Int'];
}

export enum FlowpayLinkType {
  MoreInfo = 'MORE_INFO',
  SignUp = 'SIGN_UP',
}

export interface Integration {
  __typename?: 'Integration';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  provider: IntegrationProvider;
}

export enum IntegrationProvider {
  Dotypos = 'Dotypos',
  Storyous = 'Storyous',
}

export interface IntegrationSync {
  __typename?: 'IntegrationSync';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  finished?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  started: Scalars['Date'];
}

export enum InvoiceType {
  CorrectiveTax = 'CorrectiveTax',
  CreditNode = 'CreditNode',
  Proforma = 'Proforma',
  Receipt = 'Receipt',
  ReceivedInvoice = 'ReceivedInvoice',
}

export interface InvoiceUpload {
  __typename?: 'InvoiceUpload';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  emailUploadMeta?: Maybe<EmailInvoiceUploadMeta>;
  id: Scalars['ID'];
  items: Array<InvoiceUploadItem>;
  manualUploadMeta?: Maybe<ManualInvoiceUploadMeta>;
  uploadMethod: InvoiceUploadMethod;
  venueId: Scalars['ID'];
}

export interface InvoiceUploadItem {
  __typename?: 'InvoiceUploadItem';
  autoapproveAttempted?: Maybe<Scalars['Boolean']>;
  autoapproved?: Maybe<Scalars['Boolean']>;
  bucket?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  duplicates: Array<UploadItemDuplicate>;
  externalId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize: Scalars['Int'];
  id: Scalars['ID'];
  invoiceUpload: InvoiceUploadMeta;
  invoiceUploadId: Scalars['ID'];
  isAccountingEnabled?: Maybe<Scalars['Boolean']>;
  isBankInfoEnabled?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  ocrExpense?: Maybe<OcrExpense>;
  ocrQueueId?: Maybe<Scalars['String']>;
  qrCodeData?: Maybe<Scalars['String']>;
  rawOcrResponseS3Key?: Maybe<Scalars['ID']>;
  state: UploadedInvoiceState;
  uploadError?: Maybe<UploadError>;
  wasExported?: Maybe<Scalars['Boolean']>;
}

export interface InvoiceUploadItemCountByFeStateObjectType {
  __typename?: 'InvoiceUploadItemCountByFeStateObjectType';
  processed: Scalars['Int'];
  processing: Scalars['Int'];
  readyForReview: Scalars['Int'];
  rejected: Scalars['Int'];
}

export interface InvoiceUploadItemCountByStateObjectType {
  __typename?: 'InvoiceUploadItemCountByStateObjectType';
  count: Scalars['Int'];
  state: UploadedInvoiceState;
  uploadError?: Maybe<UploadError>;
}

export interface InvoiceUploadItemForExportObjectType {
  __typename?: 'InvoiceUploadItemForExportObjectType';
  amount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  invoiceUploadItem: InvoiceUploadItem;
  name: Scalars['String'];
  purchaseDate?: Maybe<Scalars['Date']>;
}

export interface InvoiceUploadItemWithTempUrlObjectType {
  __typename?: 'InvoiceUploadItemWithTempUrlObjectType';
  id: Scalars['ID'];
  invoiceUploadItem: InvoiceUploadItem;
  tempUrl: Scalars['String'];
}

export interface InvoiceUploadMeta {
  __typename?: 'InvoiceUploadMeta';
  createdAt: Scalars['Date'];
  emailUploadMeta?: Maybe<PartialEmail>;
  id: Scalars['ID'];
  manualUploadMeta?: Maybe<PartialManual>;
  uploadMethod: InvoiceUploadMethod;
}

export enum InvoiceUploadMethod {
  Email = 'EMAIL',
  Manual = 'MANUAL',
}

export interface LaborExpenseTimeSeriesObjectType {
  __typename?: 'LaborExpenseTimeSeriesObjectType';
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
  currencyPrecision: Scalars['Int'];
  date: Scalars['Date'];
  employee: Employee;
  employeeId: Scalars['ID'];
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  laborExpense: LaborExpenseUnion;
  laborExpenseId: Scalars['ID'];
  laborExpenseType: ExpenseType;
}

export type LaborExpenseUnion = OneTimeLaborExpense | RepeatingLaborExpense;

export enum LaborIntegrationType {
  Bizimply = 'BIZIMPLY',
}

export interface LegalEntity {
  __typename?: 'LegalEntity';
  address?: Maybe<Scalars['String']>;
  businessEntityId: Scalars['ID'];
  city?: Maybe<Scalars['String']>;
  companyIdentificationNumber?: Maybe<Scalars['String']>;
  country: CountryCode;
  createdAt: Scalars['Date'];
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  legalName?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  taxIdentificationNumber?: Maybe<Scalars['String']>;
}

export interface ManualInvoiceUploadMeta {
  __typename?: 'ManualInvoiceUploadMeta';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  updatedById?: Maybe<Scalars['ID']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  activateDotyposIntegration: Scalars['Boolean'];
  addRevenue: Revenue;
  addUserToVenueAsAdmin: User;
  archivePendingAccount: AdminPendingNewAccount;
  checkAuthState?: Maybe<CurrentUserObjectType>;
  confirmPendingAccount: AdminPendingNewAccount;
  convertOcrExpenseToOneTimeExpense: Scalars['Boolean'];
  convertOcrExpenseToSplitExpense: Scalars['Boolean'];
  convertOcrExpenseToSupplyExpense: Scalars['Boolean'];
  createAccountAsAdmin: Account;
  createAccountWithAccess: Account;
  createAccountingCategory: AccountingCategory;
  createAccountingExport: Scalars['String'];
  createCategory: Category;
  createEmployee: Employee;
  createOneTimeExpense: OneTimeExpense;
  createOneTimeLaborExpense: OneTimeLaborExpense;
  createPaymentMethod: PaymentMethod;
  createPendingAccount: AdminPendingNewAccount;
  createRepeatingExpense: RepeatingExpense;
  createRepeatingLaborExpense: RepeatingLaborExpense;
  createRevenueSource: RevenueSource;
  createSupplier: Supplier;
  createSupplyExpense: SupplyExpense;
  createVenue: Venue;
  editPendingAccount: AdminPendingNewAccount;
  flowpayLinkClick: Scalars['Boolean'];
  login: CurrentUserObjectType;
  logout: Scalars['Boolean'];
  markLaborExpensesAsPaid: Scalars['Boolean'];
  markOneTimeExpensePayed: OneTimeExpense;
  markOneTimeLaborExpensePayed: OneTimeLaborExpense;
  markRepeatingExpensePaid: Scalars['Boolean'];
  markRepeatingLaborExpensePaid: Scalars['Boolean'];
  markRepeatingLaborExpensePaidOneByOne: Array<RepeatingLaborExpense>;
  markSplitExpensePaid: SplitExpense;
  markSupplyExpensePayed: SupplyExpense;
  overwriteRevenue: Revenue;
  regenToken: CurrentUserObjectType;
  rejectInvoiceUploadItem: Scalars['Boolean'];
  removeAccountingCategory: Scalars['Boolean'];
  removeCategory: Scalars['Boolean'];
  removeEmployee: Scalars['Boolean'];
  removeOneTimeExpense: Scalars['Boolean'];
  removeOneTimeLaborExpense: Scalars['Boolean'];
  removePaymentMethod: Scalars['Boolean'];
  removeRepeatingExpense: Scalars['Boolean'];
  removeRepeatingLaborExpense: Scalars['Boolean'];
  removeRevenue: Scalars['Boolean'];
  removeRevenueSource: Scalars['Boolean'];
  removeSplitExpense: Scalars['Boolean'];
  removeSupplier: Scalars['Boolean'];
  removeSupplyExpense: Scalars['Boolean'];
  removeUser: User;
  requestInvoiceFiles: Scalars['Boolean'];
  requestPOSIntegration: Scalars['String'];
  rescheduleRepeatingExpense: RepeatingExpense;
  rescheduleRepeatingLaborExpense: RepeatingLaborExpense;
  restoreAccountingCategory: Scalars['Boolean'];
  restoreCategory: Scalars['Boolean'];
  restoreEmployee: Scalars['Boolean'];
  restoreRevenueSource: Scalars['Boolean'];
  restoreSupplier: Scalars['Boolean'];
  setDefaultCategories: Array<SupplierDefaultCategory>;
  setDefaultCategory: SupplierDefaultCategory;
  setNoVatMode: Venue;
  setWarehouseInventoryValue: WarehouseInventoryValue;
  signUp: SignUpObjectType;
  skipRepeatingExpense: RepeatingExpense;
  skipRepeatingLaborExpense: RepeatingLaborExpense;
  softDeleteInvoiceUploadItem: InvoiceUploadItem;
  switchVenue: CurrentUserObjectType;
  terminateRepeatingExpense: RepeatingExpense;
  terminateRepeatingLaborExpense: RepeatingLaborExpense;
  unarchivePendingAccount: AdminPendingNewAccount;
  unmarkLaborExpensesAsPaid: Scalars['Boolean'];
  unmarkOneTimeExpensePayed: OneTimeExpense;
  unmarkOneTimeLaborExpensePayed: OneTimeLaborExpense;
  unmarkRepeatingExpensePaid: Scalars['Boolean'];
  unmarkRepeatingLaborExpensePaid: Scalars['Boolean'];
  unmarkRepeatingLaborExpensePaidOneByOne: Array<RepeatingLaborExpense>;
  unmarkSplitExpensePaid: SplitExpense;
  unmarkSupplyExpensePayed: SupplyExpense;
  updateAccountingCategory: AccountingCategory;
  updateCategory: Category;
  updateCurrentAccount: Account;
  updateEmployee: Employee;
  updateInvoiceUploadItemAgenda: InvoiceUploadItem;
  updateOneTimeExpense?: Maybe<OneTimeExpense>;
  updateOneTimeLaborExpense: OneTimeLaborExpense;
  updateRevenue: Revenue;
  updateRevenueSource: RevenueSource;
  updateSplitExpense: Scalars['Boolean'];
  updateSupplier: Supplier;
  updateSupplyExpense?: Maybe<SupplyExpense>;
  updateUserRole: User;
  updateVenueNotificationEmails: Venue;
  uploadInvoice: InvoiceUpload;
}

export interface MutationActivateDotyposIntegrationArgs {
  cloudId: Scalars['String'];
  csrfToken: Scalars['String'];
  token: Scalars['String'];
}

export interface MutationAddRevenueArgs {
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  mode?: InputMaybe<RevenueMode>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  revenueSourceId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationAddUserToVenueAsAdminArgs {
  accountId: Scalars['ID'];
  adminPassword: Scalars['String'];
  isNewAccount?: InputMaybe<Scalars['Boolean']>;
  venueId: Scalars['ID'];
}

export interface MutationArchivePendingAccountArgs {
  id: Scalars['ID'];
}

export interface MutationConfirmPendingAccountArgs {
  doesAccountExist: Scalars['Boolean'];
  pendingAccountId: Scalars['ID'];
}

export interface MutationConvertOcrExpenseToOneTimeExpenseArgs {
  accountingDate: Scalars['Date'];
  accountingInfo?: InputMaybe<CreateExpenseAccountingInfoInputType>;
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  bankInfo?: InputMaybe<CreateExpenseBankInfoInputType>;
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ocrExpenseId: Scalars['ID'];
  originalAmount?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationConvertOcrExpenseToSplitExpenseArgs {
  accountingDate: Scalars['Date'];
  accountingInfo?: InputMaybe<CreateExpenseAccountingInfoInputType>;
  bankInfo?: InputMaybe<CreateExpenseBankInfoInputType>;
  dueDate: Scalars['Date'];
  invoiceNumber: Scalars['String'];
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  ocrExpenseId: Scalars['ID'];
  originalAmount?: InputMaybe<Scalars['Int']>;
  splitExpenses: Array<SplitExpenseInput>;
  supplierId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationConvertOcrExpenseToSupplyExpenseArgs {
  accountingDate: Scalars['Date'];
  accountingInfo?: InputMaybe<CreateExpenseAccountingInfoInputType>;
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  bankInfo?: InputMaybe<CreateExpenseBankInfoInputType>;
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  ocrExpenseId: Scalars['ID'];
  originalAmount?: InputMaybe<Scalars['Int']>;
  supplierId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateAccountAsAdminArgs {
  adminPassword: Scalars['String'];
  cultureCode?: InputMaybe<CultureCode>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  password: Scalars['String'];
}

export interface MutationCreateAccountWithAccessArgs {
  cultureCode?: InputMaybe<CultureCode>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  sendInviteEmail?: InputMaybe<Scalars['Boolean']>;
  venueAccess: Array<AccessItemInputType>;
}

export interface MutationCreateAccountingCategoryArgs {
  creditAccount?: InputMaybe<Scalars['String']>;
  debitAccount?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface MutationCreateAccountingExportArgs {
  accountingSystem: AccountingSystem;
  invoiceType: InvoiceType;
  moneyS3Rada?: InputMaybe<Scalars['String']>;
  oneTimeExpenseIds: Array<Scalars['String']>;
  splitExpenseIds: Array<Scalars['String']>;
  supplyExpenseIds: Array<Scalars['String']>;
}

export interface MutationCreateCategoryArgs {
  name: Scalars['String'];
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}

export interface MutationCreateEmployeeArgs {
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateOneTimeExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateOneTimeLaborExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  employeeId: Scalars['ID'];
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreatePaymentMethodArgs {
  name: Scalars['String'];
}

export interface MutationCreatePendingAccountArgs {
  country: CountryCode;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  venueName: Scalars['String'];
}

export interface MutationCreateRepeatingExpenseArgs {
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['ID'];
  name: Scalars['String'];
  recurrencePattern: CreateRecurrencePatternInput;
  supplierId?: InputMaybe<Scalars['ID']>;
}

export interface MutationCreateRepeatingLaborExpenseArgs {
  amount: Scalars['Int'];
  categoryId?: InputMaybe<Scalars['ID']>;
  employeeId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  recurrencePattern: CreateRecurrencePatternInput;
}

export interface MutationCreateRevenueSourceArgs {
  name: Scalars['String'];
}

export interface MutationCreateSupplierArgs {
  cin?: InputMaybe<Scalars['String']>;
  defaultCategoryId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  tin?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateSupplyExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateVenueArgs {
  cultureCode?: InputMaybe<CultureCode>;
  defaultTimezone?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<CultureCode>;
  name: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
}

export interface MutationEditPendingAccountArgs {
  country: CountryCode;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  venueName: Scalars['String'];
}

export interface MutationFlowpayLinkClickArgs {
  linkType: FlowpayLinkType;
}

export interface MutationLoginArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}

export interface MutationMarkLaborExpensesAsPaidArgs {
  expenseIds: Array<Scalars['ID']>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface MutationMarkOneTimeExpensePayedArgs {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationMarkOneTimeLaborExpensePayedArgs {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationMarkRepeatingExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationMarkRepeatingLaborExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationMarkRepeatingLaborExpensePaidOneByOneArgs {
  date: Scalars['Date'];
  expenseIds: Array<Scalars['ID']>;
}

export interface MutationMarkSplitExpensePaidArgs {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationMarkSupplyExpensePayedArgs {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationOverwriteRevenueArgs {
  amount: Scalars['Int'];
  categoryId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  mode?: InputMaybe<RevenueMode>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  revenueSourceId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationRejectInvoiceUploadItemArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveAccountingCategoryArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveCategoryArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveEmployeeArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveOneTimeExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveOneTimeLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemovePaymentMethodArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRepeatingExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRevenueArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRevenueSourceArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveSplitExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveSupplierArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveSupplyExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveUserArgs {
  accountId: Scalars['ID'];
  venueId: Scalars['ID'];
}

export interface MutationRequestInvoiceFilesArgs {
  byPurchaseDate?: InputMaybe<Scalars['Boolean']>;
  date: Scalars['Date'];
}

export interface MutationRequestPosIntegrationArgs {
  integrationProvider: IntegrationProvider;
  startDate: Scalars['Date'];
}

export interface MutationRescheduleRepeatingExpenseArgs {
  id: Scalars['ID'];
  recurrencePattern: UpdateRecurrencePatternInput;
}

export interface MutationRescheduleRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
  recurrencePattern: UpdateRecurrencePatternInput;
}

export interface MutationRestoreAccountingCategoryArgs {
  id: Scalars['ID'];
}

export interface MutationRestoreCategoryArgs {
  id: Scalars['ID'];
}

export interface MutationRestoreEmployeeArgs {
  id: Scalars['ID'];
}

export interface MutationRestoreRevenueSourceArgs {
  id: Scalars['ID'];
}

export interface MutationRestoreSupplierArgs {
  id: Scalars['ID'];
}

export interface MutationSetDefaultCategoriesArgs {
  defaultCategories: Array<SetDefaultCategoryInputType>;
}

export interface MutationSetDefaultCategoryArgs {
  accountingCategoryId?: InputMaybe<Scalars['ID']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  expenseAgendaId?: InputMaybe<Scalars['ID']>;
  expenseType?: InputMaybe<ExpenseType>;
  isAutoConfirmationEnabled?: InputMaybe<Scalars['Boolean']>;
  supplierId: Scalars['ID'];
}

export interface MutationSetNoVatModeArgs {
  noVatMode?: InputMaybe<Scalars['Boolean']>;
}

export interface MutationSetWarehouseInventoryValueArgs {
  copyMonthEndToNextMonthStart?: InputMaybe<Scalars['Boolean']>;
  date: Scalars['Date'];
  monthEndValue?: InputMaybe<Scalars['Int']>;
  monthStartValue?: InputMaybe<Scalars['Int']>;
}

export interface MutationSignUpArgs {
  account: CreateAccountInputType;
  businessEntity: CreateBusinessEntityArgs;
  legalEntity: CreateLegalEntityArgs;
  secret: Scalars['String'];
}

export interface MutationSkipRepeatingExpenseArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationSkipRepeatingLaborExpenseArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationSoftDeleteInvoiceUploadItemArgs {
  id: Scalars['ID'];
}

export interface MutationSwitchVenueArgs {
  targetVenueId: Scalars['ID'];
}

export interface MutationTerminateRepeatingExpenseArgs {
  id: Scalars['ID'];
  terminationDate: Scalars['Date'];
}

export interface MutationTerminateRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
  terminationDate: Scalars['Date'];
}

export interface MutationUnarchivePendingAccountArgs {
  id: Scalars['ID'];
}

export interface MutationUnmarkLaborExpensesAsPaidArgs {
  expenseIds: Array<Scalars['ID']>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface MutationUnmarkOneTimeExpensePayedArgs {
  id: Scalars['ID'];
}

export interface MutationUnmarkOneTimeLaborExpensePayedArgs {
  id: Scalars['ID'];
}

export interface MutationUnmarkRepeatingExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationUnmarkRepeatingLaborExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationUnmarkRepeatingLaborExpensePaidOneByOneArgs {
  date: Scalars['Date'];
  expenseIds: Array<Scalars['ID']>;
}

export interface MutationUnmarkSplitExpensePaidArgs {
  id: Scalars['ID'];
}

export interface MutationUnmarkSupplyExpensePayedArgs {
  id: Scalars['ID'];
}

export interface MutationUpdateAccountingCategoryArgs {
  creditAccount?: InputMaybe<Scalars['String']>;
  debitAccount?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateCategoryArgs {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}

export interface MutationUpdateCurrentAccountArgs {
  cultureCode?: InputMaybe<CultureCode>;
  defaultUserId?: InputMaybe<Scalars['ID']>;
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateEmployeeArgs {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateInvoiceUploadItemAgendaArgs {
  agendaId: Scalars['ID'];
  invoiceUploadItemId?: InputMaybe<Scalars['ID']>;
  oneTimeExpenseId?: InputMaybe<Scalars['ID']>;
  splitExpenseId?: InputMaybe<Scalars['ID']>;
  supplyExpenseId?: InputMaybe<Scalars['ID']>;
}

export interface MutationUpdateOneTimeExpenseArgs {
  accountingDate?: InputMaybe<Scalars['Date']>;
  accountingInfo?: InputMaybe<CreateExpenseAccountingInfoInputType>;
  amount?: InputMaybe<Scalars['Int']>;
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  bankInfo?: InputMaybe<CreateExpenseBankInfoInputType>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  expenseType?: InputMaybe<SplitExpenseType>;
  id: Scalars['ID'];
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  originalAmount?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateOneTimeLaborExpenseArgs {
  accountingDate?: InputMaybe<Scalars['Date']>;
  amount?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  note?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateRevenueArgs {
  amount?: InputMaybe<Scalars['Int']>;
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  revenueSourceId?: InputMaybe<Scalars['ID']>;
}

export interface MutationUpdateRevenueSourceArgs {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateSplitExpenseArgs {
  accountingDate: Scalars['Date'];
  accountingInfo?: InputMaybe<CreateExpenseAccountingInfoInputType>;
  bankInfo?: InputMaybe<CreateExpenseBankInfoInputType>;
  dueDate: Scalars['Date'];
  invoiceNumber: Scalars['String'];
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  originalAmount?: InputMaybe<Scalars['Int']>;
  splitExpenseId: Scalars['ID'];
  splitExpenses: Array<UpdateSplitExpenseInput>;
  supplierId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateSupplierArgs {
  cin?: InputMaybe<Scalars['String']>;
  defaultCategoryId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  tin?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateSupplyExpenseArgs {
  accountingDate?: InputMaybe<Scalars['Date']>;
  accountingInfo?: InputMaybe<CreateExpenseAccountingInfoInputType>;
  amount?: InputMaybe<Scalars['Int']>;
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  bankInfo?: InputMaybe<CreateExpenseBankInfoInputType>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  expenseType?: InputMaybe<SplitExpenseType>;
  id: Scalars['ID'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  isAlreadyPaid?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  originalAmount?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateUserRoleArgs {
  accountId: Scalars['ID'];
  role: UserRole;
  venueId: Scalars['ID'];
}

export interface MutationUpdateVenueNotificationEmailsArgs {
  notificationEmails?: InputMaybe<Scalars['String']>;
}

export interface MutationUploadInvoiceArgs {
  files: Array<Scalars['Upload']>;
}

export interface OcrExpense {
  __typename?: 'OcrExpense';
  /** Identifies a date when this expense will be reflected in expense list */
  accountingDate: Scalars['Date'];
  accountingInfo?: Maybe<ExpenseAccountingInfo>;
  accountingInfoId?: Maybe<Scalars['ID']>;
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  bankInfo?: Maybe<ExpenseBankInfo>;
  bankInfoId?: Maybe<Scalars['ID']>;
  bulkPaymentExportedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  dueDate: Scalars['Date'];
  exchangeRate?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  invoiceUploadItem: InvoiceUploadItem;
  invoiceUploadItemId: Scalars['ID'];
  isAlreadyPaid?: Maybe<Scalars['Boolean']>;
  isBankInfoVerified?: Maybe<Scalars['Boolean']>;
  note?: Maybe<Scalars['String']>;
  oneTimeExpense?: Maybe<OneTimeExpense>;
  oneTimeExpenseId?: Maybe<Scalars['ID']>;
  originalAmount?: Maybe<Scalars['Int']>;
  originalCurrencyCode?: Maybe<CurrencyCode>;
  splitExpense?: Maybe<SplitExpense>;
  supplier: Supplier;
  supplierId: Scalars['ID'];
  supplyExpense?: Maybe<SupplyExpense>;
  supplyExpenseId?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface OneTimeExpense {
  __typename?: 'OneTimeExpense';
  /** Identifies a date when this expense will be reflected in accounting */
  accountingDate: Scalars['Date'];
  accountingInfo?: Maybe<ExpenseAccountingInfo>;
  accountingInfoId?: Maybe<Scalars['ID']>;
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  bankInfo?: Maybe<ExpenseBankInfo>;
  bankInfoId?: Maybe<Scalars['ID']>;
  category: Category;
  categoryId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  exchangeRate?: Maybe<Scalars['Float']>;
  hasQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ocrExpense?: Maybe<OcrExpense>;
  ocrExpenseId?: Maybe<Scalars['ID']>;
  originalAmount?: Maybe<Scalars['Int']>;
  originalCurrencyCode?: Maybe<CurrencyCode>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['ID']>;
  splitExpense?: Maybe<SplitExpense>;
  splitExpenseId?: Maybe<Scalars['ID']>;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['ID']>;
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface OneTimeLaborExpense {
  __typename?: 'OneTimeLaborExpense';
  /** Identifies a date when this expense will be reflected in accounting */
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  employee?: Maybe<Employee>;
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
  insuranceAmount?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['ID']>;
  socialSecurityAmount?: Maybe<Scalars['Int']>;
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface PaginatedCategoryObjectType {
  __typename?: 'PaginatedCategoryObjectType';
  count: Scalars['Int'];
  items: Array<Category>;
}

export interface PaginatedEmployeeObjectType {
  __typename?: 'PaginatedEmployeeObjectType';
  count: Scalars['Int'];
  items: Array<Employee>;
}

export interface PaginatedInvoiceUploadItemObjectType {
  __typename?: 'PaginatedInvoiceUploadItemObjectType';
  count: Scalars['Int'];
  items: Array<InvoiceUploadItem>;
}

export interface PaginatedOneTimeExpenseObjectType {
  __typename?: 'PaginatedOneTimeExpenseObjectType';
  count: Scalars['Int'];
  items: Array<OneTimeExpense>;
}

export interface PaginatedOneTimeLaborExpenseObjectType {
  __typename?: 'PaginatedOneTimeLaborExpenseObjectType';
  count: Scalars['Int'];
  items: Array<OneTimeLaborExpense>;
}

export interface PaginatedPaymentMethodObjectType {
  __typename?: 'PaginatedPaymentMethodObjectType';
  count: Scalars['Int'];
  items: Array<PaymentMethod>;
}

export interface PaginatedPaymentObjectType {
  __typename?: 'PaginatedPaymentObjectType';
  count: Scalars['Int'];
  items: Array<Payment>;
}

export interface PaginatedRepeatingExpenseObjectType {
  __typename?: 'PaginatedRepeatingExpenseObjectType';
  count: Scalars['Int'];
  items: Array<RepeatingExpense>;
}

export interface PaginatedRepeatingLaborExpenseObjectType {
  __typename?: 'PaginatedRepeatingLaborExpenseObjectType';
  count: Scalars['Int'];
  items: Array<RepeatingLaborExpense>;
}

export interface PaginatedRevenueObjectType {
  __typename?: 'PaginatedRevenueObjectType';
  count: Scalars['Int'];
  items: Array<Revenue>;
}

export interface PaginatedRevenueSourceObjectType {
  __typename?: 'PaginatedRevenueSourceObjectType';
  count: Scalars['Int'];
  items: Array<RevenueSource>;
}

export interface PaginatedSupplierObjectType {
  __typename?: 'PaginatedSupplierObjectType';
  count: Scalars['Int'];
  items: Array<Supplier>;
}

export interface PaginatedSupplyExpenseObjectType {
  __typename?: 'PaginatedSupplyExpenseObjectType';
  count: Scalars['Int'];
  items: Array<SupplyExpense>;
}

export interface PartialEmail {
  __typename?: 'PartialEmail';
  receivedAt: Scalars['Date'];
  receivedFrom: Scalars['String'];
}

export interface PartialManual {
  __typename?: 'PartialManual';
  createdBy: CreatedOrUpdatedByObjectType;
}

export interface Payment {
  __typename?: 'Payment';
  amount: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  oneTimeExpenseId: Scalars['ID'];
  oneTimeLaborExpenseId: Scalars['ID'];
  payedAt: Scalars['Date'];
  paymentDueDate: Scalars['Date'];
  repeatingExpenseId: Scalars['ID'];
  repeatingLaborExpenseId: Scalars['ID'];
  supplyExpenseId: Scalars['ID'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface PaymentMethod {
  __typename?: 'PaymentMethod';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
}

export interface PnlLaborItemObjectType {
  __typename?: 'PnlLaborItemObjectType';
  employeeId: Scalars['ID'];
  employeeName: Scalars['String'];
  timeSeries: Array<PnlTimeSeriesObjectType>;
}

export interface PnlLossItemObjectType {
  __typename?: 'PnlLossItemObjectType';
  categoryId: Scalars['String'];
  categoryName: Scalars['String'];
  employeeId: Scalars['String'];
  employeeName: Scalars['String'];
  groupId: Scalars['String'];
  groupName: Scalars['String'];
  supplierId: Scalars['String'];
  supplierName: Scalars['String'];
  timeSeries: Array<PnlTimeSeriesObjectType>;
}

export interface PnlLossNewObjectType {
  __typename?: 'PnlLossNewObjectType';
  labor: PnlLossSectionObjectType;
  other: PnlLossSectionObjectType;
  supply: PnlLossSectionObjectType;
}

export interface PnlLossObjectType {
  __typename?: 'PnlLossObjectType';
  labor: PnlLossSectionObjectType;
  new: PnlLossNewObjectType;
  other: PnlLossSectionObjectType;
  supply: PnlLossSectionObjectType;
}

export interface PnlLossSectionObjectType {
  __typename?: 'PnlLossSectionObjectType';
  items: Array<PnlLossItemObjectType>;
  timeSeries: Array<PnlTimeSeriesObjectType>;
}

export interface PnlOtherItemObjectType {
  __typename?: 'PnlOtherItemObjectType';
  categoryId: Scalars['ID'];
  categoryName: Scalars['String'];
  timeSeries: Array<PnlTimeSeriesObjectType>;
}

export interface PnlProfitObjectType {
  __typename?: 'PnlProfitObjectType';
  items: Array<PnlRevenueItemObjectType>;
  timeSeries: Array<PnlTimeSeriesObjectType>;
}

export interface PnlRevenueItemObjectType {
  __typename?: 'PnlRevenueItemObjectType';
  revenueSourceId: Scalars['ID'];
  revenueSourceName: Scalars['String'];
  timeSeries: Array<PnlTimeSeriesObjectType>;
}

export interface PnlSupplyItemObjectType {
  __typename?: 'PnlSupplyItemObjectType';
  supplierId: Scalars['ID'];
  supplierName: Scalars['String'];
  timeSeries: Array<PnlTimeSeriesObjectType>;
}

export interface PnlTimeSeriesObjectType {
  __typename?: 'PnlTimeSeriesObjectType';
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  currencyCode: CurrencyCode;
  date: Scalars['Date'];
  precision: Scalars['Int'];
}

export interface ProfitAndLossObjectType {
  __typename?: 'ProfitAndLossObjectType';
  loss: PnlLossObjectType;
  profit: PnlProfitObjectType;
}

export interface QrCodeData {
  __typename?: 'QrCodeData';
  amount: Scalars['Float'];
  bankAccountNumber: Scalars['String'];
  bankCode: Scalars['String'];
  detected: Scalars['Boolean'];
  isBankInfoVerified?: Maybe<Scalars['Boolean']>;
  qrCodeString: Scalars['String'];
  variableSymbol?: Maybe<Scalars['String']>;
}

export interface Query {
  __typename?: 'Query';
  accountingCategories: Array<AccountingCategory>;
  accountingExpenses: AccountingExpensesObjectType;
  adminPermissions: AdminAccount;
  categories: PaginatedCategoryObjectType;
  category: Category;
  currentUser: User;
  currentUserAccount: Account;
  currentVenue: Venue;
  deletedAccountingCategories: Array<AccountingCategory>;
  employee: Employee;
  employees: PaginatedEmployeeObjectType;
  existingAccountByEmail?: Maybe<ExistingAccountObjectType>;
  expenseAgendas: Array<ExpenseAgenda>;
  expenseQrCodeData?: Maybe<QrCodeData>;
  expensesAboExport: ExpenseAboExport;
  expensesAmountsByMonthWithPaidAmount: Array<ExpensesAmountsWithPaymentByMonthObjectType>;
  getSubscriptionPaymentLink: Scalars['String'];
  integration?: Maybe<Integration>;
  invoiceUploadItemCountByFeStates: InvoiceUploadItemCountByFeStateObjectType;
  invoiceUploadItemCountByStates: Array<InvoiceUploadItemCountByStateObjectType>;
  invoiceUploadItems: PaginatedInvoiceUploadItemObjectType;
  invoiceUploadItemsForExport: Array<InvoiceUploadItemForExportObjectType>;
  invoiceUploads: Array<InvoiceUpload>;
  invoicesTempUrls: Array<InvoiceUploadItemWithTempUrlObjectType>;
  laborExpensesTimeSeries: Array<LaborExpenseTimeSeriesObjectType>;
  laborExpensesTotalAmount: TotalAmountObjectType;
  latestIntegrationSync?: Maybe<IntegrationSync>;
  ocrExpense: OcrExpense;
  oneTimeExpense: OneTimeExpense;
  oneTimeExpenses: PaginatedOneTimeExpenseObjectType;
  oneTimeExpensesForBulkExport: Array<OneTimeExpense>;
  oneTimeExpensesTotalAmount: TotalAmountObjectType;
  oneTimeLaborExpense: OneTimeLaborExpense;
  oneTimeLaborExpenseTotalAmount: TotalAmountObjectType;
  oneTimeLaborExpenses: PaginatedOneTimeLaborExpenseObjectType;
  payment: Payment;
  paymentMethod: PaymentMethod;
  paymentMethods: PaginatedPaymentMethodObjectType;
  payments: PaginatedPaymentObjectType;
  pendingAccounts: Array<AdminPendingNewAccount>;
  processedInvoiceCount: Scalars['Int'];
  profitsAndLoss: ProfitAndLossObjectType;
  recurringExpensesTimeSeries: Array<RecurringExpenseTimeSeriesObjectType>;
  recurringExpensesTotalAmount: TotalAmountObjectType;
  recurringLaborExpensesTotalAmount: TotalAmountObjectType;
  referralLink: Scalars['String'];
  repeatingExpense: RepeatingExpense;
  repeatingExpenses: PaginatedRepeatingExpenseObjectType;
  repeatingLaborExpense: RepeatingLaborExpense;
  repeatingLaborExpenses: PaginatedRepeatingLaborExpenseObjectType;
  revenue: Revenue;
  revenueSource: RevenueSource;
  revenueSources: PaginatedRevenueSourceObjectType;
  revenues: PaginatedRevenueObjectType;
  revenuesTotal: TotalAmountObjectType;
  revenuesTotalBySource: Array<RevenueTotalAmountBySourceObjectType>;
  splitExpensesForBulkExport: Array<SplitExpenseForExportObjectType>;
  structuredTotalExpenses: ExpenseStructuredTotalAmountObjectType;
  supplier: Supplier;
  suppliers: PaginatedSupplierObjectType;
  supplyExpense: SupplyExpense;
  supplyExpenses: PaginatedSupplyExpenseObjectType;
  supplyExpensesForBulkExport: Array<SupplyExpense>;
  supplyExpensesTotalAmount: TotalAmountObjectType;
  totalExpenses: TotalAmountObjectType;
  totalExpensesByCategory?: Maybe<Array<ExpenseTotalByCategoryObjectType>>;
  totalProfit: TotalAmountObjectType;
  usersInChain: Array<User>;
  venues: Array<Venue>;
  warehouseInventoryValue?: Maybe<WarehouseInventoryValue>;
}

export interface QueryAccountingCategoriesArgs {
  deletedOnly?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryAccountingExpensesArgs {
  from: Scalars['Date'];
  invoiceType: InvoiceType;
  to: Scalars['Date'];
}

export interface QueryCategoriesArgs {
  deletedOnly?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryCategoryArgs {
  id: Scalars['ID'];
}

export interface QueryEmployeeArgs {
  id: Scalars['ID'];
}

export interface QueryEmployeesArgs {
  deletedOnly?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryExistingAccountByEmailArgs {
  email: Scalars['String'];
}

export interface QueryExpenseQrCodeDataArgs {
  ocrExpenseId: Scalars['ID'];
  oneTimeExpenseId?: InputMaybe<Scalars['ID']>;
  splitExpenseId?: InputMaybe<Scalars['ID']>;
  supplyExpenseId?: InputMaybe<Scalars['ID']>;
}

export interface QueryExpensesAboExportArgs {
  bankAccountNumber: Scalars['String'];
  bankCode: Scalars['String'];
  markExpensesAsPaid: Scalars['Boolean'];
  ocrExpenseIds: Array<Scalars['ID']>;
}

export interface QueryExpensesAmountsByMonthWithPaidAmountArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  includeNegative?: InputMaybe<Scalars['Boolean']>;
  multiSearchValue?: InputMaybe<Scalars['String']>;
  oneTimeExpenses?: InputMaybe<Scalars['Boolean']>;
  oneTimeLaborExpenses?: InputMaybe<Scalars['Boolean']>;
  recurringExpenses?: InputMaybe<Scalars['Boolean']>;
  repeatingLaborExpenses?: InputMaybe<Scalars['Boolean']>;
  supplyExpenses?: InputMaybe<Scalars['Boolean']>;
  to: Scalars['Date'];
}

export interface QueryGetSubscriptionPaymentLinkArgs {
  countryCode: Scalars['String'];
  period: Scalars['String'];
  planType: Scalars['String'];
}

export interface QueryInvoiceUploadItemCountByFeStatesArgs {
  dateFromTo?: InputMaybe<DateFromToInputType>;
}

export interface QueryInvoiceUploadItemsArgs {
  dateFromTo?: InputMaybe<DateFromToInputType>;
  uploadedInvoiceState?: InputMaybe<UploadedInvoiceFeState>;
}

export interface QueryInvoiceUploadItemsForExportArgs {
  byPurchaseDate?: InputMaybe<Scalars['Boolean']>;
  dateFromTo: DateFromToInputType;
}

export interface QueryInvoicesTempUrlsArgs {
  invoiceUploadItemIds: Array<Scalars['ID']>;
}

export interface QueryLaborExpensesTimeSeriesArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  employeeIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  to: Scalars['Date'];
}

export interface QueryLaborExpensesTotalAmountArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  employeeIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  to: Scalars['Date'];
}

export interface QueryOcrExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryOneTimeExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryOneTimeExpensesArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilter?: InputMaybe<DateFromToInputType>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  limit?: InputMaybe<Scalars['Int']>;
  multiSearchValue?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']>>;
}

export interface QueryOneTimeExpensesForBulkExportArgs {
  dateFromTo?: InputMaybe<DateFromToInputType>;
  overdue?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryOneTimeExpensesTotalAmountArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']>>;
  to: Scalars['Date'];
}

export interface QueryOneTimeLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryOneTimeLaborExpenseTotalAmountArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  to: Scalars['Date'];
}

export interface QueryOneTimeLaborExpensesArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilter?: InputMaybe<DateFromToInputType>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  employeeIds?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  multiSearchValue?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryPaymentArgs {
  id: Scalars['ID'];
}

export interface QueryPaymentMethodArgs {
  id: Scalars['ID'];
}

export interface QueryPaymentMethodsArgs {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryPaymentsArgs {
  dateFilter: DateFromToInputType;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryProfitsAndLossArgs {
  byCategory?: InputMaybe<Scalars['Boolean']>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryRecurringExpensesTimeSeriesArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']>>;
  to: Scalars['Date'];
}

export interface QueryRecurringExpensesTotalAmountArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']>>;
  to: Scalars['Date'];
}

export interface QueryRecurringLaborExpensesTotalAmountArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  employeeIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  to: Scalars['Date'];
}

export interface QueryRepeatingExpenseArgs {
  id: Scalars['String'];
}

export interface QueryRepeatingExpensesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryRepeatingLaborExpensesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryRevenueArgs {
  id: Scalars['ID'];
}

export interface QueryRevenueSourceArgs {
  id: Scalars['ID'];
}

export interface QueryRevenueSourcesArgs {
  deletedOnly?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryRevenuesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  revenueSourceIds?: InputMaybe<Array<Scalars['ID']>>;
  sum?: InputMaybe<Scalars['String']>;
}

export interface QueryRevenuesTotalArgs {
  from: Scalars['Date'];
  revenueSourceIds?: InputMaybe<Array<Scalars['ID']>>;
  to: Scalars['Date'];
}

export interface QueryRevenuesTotalBySourceArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QuerySplitExpensesForBulkExportArgs {
  dateFromTo?: InputMaybe<DateFromToInputType>;
  overdue?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryStructuredTotalExpensesArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  to: Scalars['Date'];
}

export interface QuerySupplierArgs {
  id: Scalars['ID'];
}

export interface QuerySuppliersArgs {
  deletedOnly?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
}

export interface QuerySupplyExpenseArgs {
  id: Scalars['ID'];
}

export interface QuerySupplyExpensesArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilter?: InputMaybe<DateFromToInputType>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  limit?: InputMaybe<Scalars['Int']>;
  multiSearchValue?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']>>;
}

export interface QuerySupplyExpensesForBulkExportArgs {
  dateFromTo?: InputMaybe<DateFromToInputType>;
  overdue?: InputMaybe<Scalars['Boolean']>;
}

export interface QuerySupplyExpensesTotalAmountArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']>>;
  to: Scalars['Date'];
}

export interface QueryTotalExpensesArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  employeeIds?: InputMaybe<Array<Scalars['ID']>>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  supplierIds?: InputMaybe<Array<Scalars['ID']>>;
  to: Scalars['Date'];
}

export interface QueryTotalExpensesByCategoryArgs {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  multiSearchValue?: InputMaybe<Scalars['String']>;
  to: Scalars['Date'];
}

export interface QueryTotalProfitArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryUsersInChainArgs {
  currentVenueOnly?: InputMaybe<Scalars['Boolean']>;
}

export interface QueryWarehouseInventoryValueArgs {
  date: Scalars['Date'];
}

export enum RecurrenceInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export interface RecurrencePattern {
  __typename?: 'RecurrencePattern';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  dayOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  interval: RecurrenceInterval;
  monthOfYear?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
}

export interface RecurringExpenseTimeSeriesObjectType {
  __typename?: 'RecurringExpenseTimeSeriesObjectType';
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
  currencyPrecision: Scalars['Int'];
  date: Scalars['Date'];
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  repeatingExpense: RepeatingExpense;
  repeatingExpenseId: Scalars['ID'];
}

export interface RepeatingExpense {
  __typename?: 'RepeatingExpense';
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  category: Category;
  categoryId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  predecessorId?: Maybe<Scalars['ID']>;
  recurrencePattern: RecurrencePattern;
  recurrencePatternId: Scalars['ID'];
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['ID']>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface RepeatingLaborExpense {
  __typename?: 'RepeatingLaborExpense';
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  employee: Employee;
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  predecessorId?: Maybe<Scalars['ID']>;
  recurrencePattern: RecurrencePattern;
  recurrencePatternId: Scalars['ID'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface Revenue {
  __typename?: 'Revenue';
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  category: Category;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  date: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  mode: RevenueMode;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  revenueSource: RevenueSource;
  revenueSourceId: Scalars['ID'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export enum RevenueMode {
  Day = 'DAY',
  Month = 'MONTH',
}

export interface RevenueSource {
  __typename?: 'RevenueSource';
  createdAt: Scalars['Date'];
  createdBy: CreatedOrUpdatedByObjectType;
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<CreatedOrUpdatedByObjectType>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  integrationProvider?: Maybe<IntegrationProvider>;
  name: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface RevenueTotalAmountBySourceObjectType {
  __typename?: 'RevenueTotalAmountBySourceObjectType';
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  precision: Scalars['Int'];
  revenueSourceId: Scalars['ID'];
  revenueSourceName: Scalars['String'];
}

export interface Role {
  __typename?: 'Role';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  name: UserRole;
  permissions: Scalars['String'];
}

export interface ScopeObjectType {
  __typename?: 'ScopeObjectType';
  currentUserId: Scalars['String'];
  currentUserRole: UserRole;
  currentVenueCountry: CountryCode;
  currentVenueId: Scalars['String'];
  currentVenueName: Scalars['String'];
  noVatAvailableFrom?: Maybe<Scalars['Date']>;
  noVatMode: Scalars['Boolean'];
  permissions: Array<Scalars['String']>;
  userIds: Array<Scalars['String']>;
}

export interface SetDefaultCategoryInputType {
  accountingCategoryId?: InputMaybe<Scalars['ID']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  expenseType?: InputMaybe<ExpenseType>;
  isAutoConfirmationEnabled?: InputMaybe<Scalars['Boolean']>;
  supplierId: Scalars['ID'];
}

export interface SignUpObjectType {
  __typename?: 'SignUpObjectType';
  accountId: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  userId: Scalars['String'];
  venueId: Scalars['String'];
}

export interface SplitExpense {
  __typename?: 'SplitExpense';
  /** Identifies a date when this expense will be reflected in expense list */
  accountingDate?: Maybe<Scalars['Date']>;
  accountingInfo?: Maybe<ExpenseAccountingInfo>;
  accountingInfoId?: Maybe<Scalars['ID']>;
  bankInfo?: Maybe<ExpenseBankInfo>;
  bankInfoId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  hasQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  ocrExpense: OcrExpense;
  ocrExpenseId: Scalars['ID'];
  oneTimeExpenses: Array<OneTimeExpense>;
  originalAmount?: Maybe<Scalars['Int']>;
  originalCurrencyCode?: Maybe<CurrencyCode>;
  supplyExpenses: Array<SupplyExpense>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface SplitExpenseForExportObjectType {
  __typename?: 'SplitExpenseForExportObjectType';
  amount: Scalars['Float'];
  dueDate: Scalars['Date'];
  name: Scalars['String'];
  splitExpense: SplitExpense;
}

export interface SplitExpenseInput {
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['ID'];
  type: SplitExpenseType;
}

export enum SplitExpenseType {
  OneTime = 'OneTime',
  Supply = 'Supply',
}

export interface SubscriptionDataObjectType {
  __typename?: 'SubscriptionDataObjectType';
  accountingEnabled: Scalars['Boolean'];
  bankInfoEnabled: Scalars['Boolean'];
  end?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  invoiceEmail: Scalars['String'];
  /** @deprecated Use "invoiceEmail" instead */
  invoiceEmailAddress: Scalars['String'];
  invoiceRecognitionEnabled: Scalars['Boolean'];
  invoiceRecognitionsPerMonth: Scalars['Int'];
  start: Scalars['Date'];
  subscriptionPeriod?: Maybe<SubscriptionPeriod>;
  subscriptionPlanType: SubscriptionPlanType;
  venueId: Scalars['ID'];
}

export enum SubscriptionPeriod {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly',
}

export enum SubscriptionPlanType {
  AwaitingPayment = 'AWAITING_PAYMENT',
  Tier_1 = 'TIER_1',
  Tier_1Acc = 'TIER_1_ACC',
  Tier_2 = 'TIER_2',
  Tier_2Acc = 'TIER_2_ACC',
  Tier_3 = 'TIER_3',
  Trial = 'TRIAL',
}

export interface Supplier {
  __typename?: 'Supplier';
  cin?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  createdBy: CreatedOrUpdatedByObjectType;
  createdById: Scalars['ID'];
  defaultCategory?: Maybe<SupplierDefaultCategory>;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedBy?: Maybe<CreatedOrUpdatedByObjectType>;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['String'];
}

export interface SupplierDefaultCategory {
  __typename?: 'SupplierDefaultCategory';
  accountingCategory?: Maybe<AccountingCategory>;
  accountingCategoryId?: Maybe<Scalars['ID']>;
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  expenseAgendaId?: Maybe<Scalars['ID']>;
  expenseType?: Maybe<ExpenseType>;
  id: Scalars['ID'];
  isAutoConfirmationEnabled?: Maybe<Scalars['Boolean']>;
  supplierId: Scalars['ID'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['String'];
}

export interface SupplyExpense {
  __typename?: 'SupplyExpense';
  /** Identifies a date when this expense will be reflected in accounting */
  accountingDate: Scalars['Date'];
  accountingInfo?: Maybe<ExpenseAccountingInfo>;
  accountingInfoId?: Maybe<Scalars['ID']>;
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  bankInfo?: Maybe<ExpenseBankInfo>;
  bankInfoId?: Maybe<Scalars['ID']>;
  category: Category;
  categoryId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedAt?: Maybe<Scalars['Date']>;
  deletedById?: Maybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  exchangeRate?: Maybe<Scalars['Float']>;
  hasQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  ocrExpense?: Maybe<OcrExpense>;
  ocrExpenseId?: Maybe<Scalars['ID']>;
  originalAmount?: Maybe<Scalars['Int']>;
  originalCurrencyCode?: Maybe<CurrencyCode>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['ID']>;
  splitExpense?: Maybe<SplitExpense>;
  splitExpenseId?: Maybe<Scalars['ID']>;
  supplier: Supplier;
  supplierId: Scalars['ID'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export enum TaxRate {
  Tax_0 = 'TAX_0',
  Tax_5 = 'TAX_5',
  Tax_8 = 'TAX_8',
  Tax_10 = 'TAX_10',
  Tax_12 = 'TAX_12',
  Tax_15 = 'TAX_15',
  Tax_20 = 'TAX_20',
  Tax_21 = 'TAX_21',
  Tax_23 = 'TAX_23',
}

export interface TotalAmountObjectType {
  __typename?: 'TotalAmountObjectType';
  amount: Scalars['Int'];
  amountWithoutVat?: Maybe<Scalars['Int']>;
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  precision: Scalars['Int'];
}

export interface UpdateRecurrencePatternInput {
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  endDate?: InputMaybe<Scalars['Date']>;
  interval?: InputMaybe<RecurrenceInterval>;
  monthOfYear?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface UpdateSplitExpenseInput {
  amount: Scalars['Int'];
  amountWithoutVat?: InputMaybe<Scalars['Int']>;
  categoryId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  type: SplitExpenseType;
}

export enum UploadError {
  FileTooBig = 'FILE_TOO_BIG',
  FormatNotSupported = 'FORMAT_NOT_SUPPORTED',
}

export interface UploadItemDuplicate {
  __typename?: 'UploadItemDuplicate';
  createdAt: Scalars['Date'];
  fileName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  ocrExpense?: Maybe<OcrExpense>;
  state: UploadedInvoiceState;
  uploadError?: Maybe<UploadError>;
}

export enum UploadedInvoiceFeState {
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  ReadyForReview = 'READY_FOR_REVIEW',
  Rejected = 'REJECTED',
}

export enum UploadedInvoiceState {
  Approved = 'APPROVED',
  Error = 'ERROR',
  Failed = 'FAILED',
  FailedImport = 'FAILED_IMPORT',
  OverTheLimit = 'OVER_THE_LIMIT',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Uploaded = 'UPLOADED',
}

export interface User {
  __typename?: 'User';
  account: Account;
  accountId: Scalars['ID'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  role: Role;
  roleName: UserRole;
  venue: Venue;
}

export enum UserRole {
  InvoiceOnly = 'InvoiceOnly',
  VenueAdmin = 'VenueAdmin',
  VenueManager = 'VenueManager',
  VenueOwner = 'VenueOwner',
}

export enum VatBreakdown {
  PDosDan = 'PDosDan',
  PDslRegEu = 'PDslRegEu',
  PDzboziEu = 'PDzboziEu',
  Pd = 'Pd',
  Pdb2 = 'Pdb2',
  Pn = 'Pn',
}

export interface Venue {
  __typename?: 'Venue';
  address?: Maybe<Scalars['String']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  cultureCode: CultureCode;
  defaultTimezone: Scalars['String'];
  deletedAt?: Maybe<Scalars['Date']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invoiceEmail: Scalars['String'];
  languageCode: CultureCode;
  legalEntity: LegalEntity;
  name: Scalars['String'];
  noVatMode?: Maybe<Scalars['Boolean']>;
  notificationEmails?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
}

export interface WarehouseInventoryValue {
  __typename?: 'WarehouseInventoryValue';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  month: Scalars['Int'];
  monthEndValue?: Maybe<Scalars['Int']>;
  monthStartValue?: Maybe<Scalars['Int']>;
  venueId: Scalars['ID'];
  year: Scalars['Int'];
}

export type CurrentUserFragment = {
  __typename?: 'CurrentUserObjectType';
  client_id: string;
  given_name: string;
  family_name: string;
  email: string;
  zoneinfo: string;
  cultureCode: CultureCode;
  languageCode: CultureCode;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type CheckAuthStateMutationVariables = Exact<{ [key: string]: never }>;

export type CheckAuthStateMutation = {
  __typename?: 'Mutation';
  checkAuthState?: {
    __typename?: 'CurrentUserObjectType';
    client_id: string;
    given_name: string;
    family_name: string;
    email: string;
    zoneinfo: string;
    cultureCode: CultureCode;
    languageCode: CultureCode;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'CurrentUserObjectType';
    client_id: string;
    given_name: string;
    family_name: string;
    email: string;
    zoneinfo: string;
    cultureCode: CultureCode;
    languageCode: CultureCode;
  };
};

export type RegenTokenMutationVariables = Exact<{ [key: string]: never }>;

export type RegenTokenMutation = {
  __typename?: 'Mutation';
  regenToken: {
    __typename?: 'CurrentUserObjectType';
    client_id: string;
    given_name: string;
    family_name: string;
    email: string;
    zoneinfo: string;
    cultureCode: CultureCode;
    languageCode: CultureCode;
  };
};

export type CurrentUserAdminPermissionsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CurrentUserAdminPermissionsQuery = {
  __typename?: 'Query';
  adminPermissions: {
    __typename?: 'AdminAccount';
    id: string;
    accountId: string;
    canCreateNewVenues: boolean;
  };
};

export type CreatePendingAccountMutationVariables = Exact<{
  venueName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: CountryCode;
}>;

export type CreatePendingAccountMutation = {
  __typename?: 'Mutation';
  createPendingAccount: {
    __typename?: 'AdminPendingNewAccount';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isSent: boolean;
    adminAccountId: string;
    venue: {
      __typename?: 'Venue';
      id: string;
      name: string;
      legalEntity: { __typename?: 'LegalEntity'; country: CountryCode };
    };
  };
};

export type EditPendingAccountMutationVariables = Exact<{
  id: Scalars['ID'];
  venueName: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  country: CountryCode;
}>;

export type EditPendingAccountMutation = {
  __typename?: 'Mutation';
  editPendingAccount: {
    __typename?: 'AdminPendingNewAccount';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isSent: boolean;
    adminAccountId: string;
    venue: {
      __typename?: 'Venue';
      id: string;
      name: string;
      legalEntity: { __typename?: 'LegalEntity'; country: CountryCode };
    };
  };
};

export type ArchivePendingAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ArchivePendingAccountMutation = {
  __typename?: 'Mutation';
  archivePendingAccount: {
    __typename?: 'AdminPendingNewAccount';
    id: string;
    deletedAt?: any | null;
  };
};

export type UnarchivePendingAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnarchivePendingAccountMutation = {
  __typename?: 'Mutation';
  unarchivePendingAccount: {
    __typename?: 'AdminPendingNewAccount';
    id: string;
    deletedAt?: any | null;
  };
};

export type ConfirmPendingAccountMutationVariables = Exact<{
  pendingAccountId: Scalars['ID'];
  doesAccountExist: Scalars['Boolean'];
}>;

export type ConfirmPendingAccountMutation = {
  __typename?: 'Mutation';
  confirmPendingAccount: {
    __typename?: 'AdminPendingNewAccount';
    id: string;
    isSent: boolean;
  };
};

export type ExistingAccountByEmailQueryVariables = Exact<{
  email: Scalars['String'];
}>;

export type ExistingAccountByEmailQuery = {
  __typename?: 'Query';
  existingAccountByEmail?: {
    __typename?: 'ExistingAccountObjectType';
    accountId: string;
    email: string;
    firstName: string;
    lastName: string;
    venueNames: Array<string>;
    isDeleted: boolean;
  } | null;
};

export type PendingAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type PendingAccountsQuery = {
  __typename?: 'Query';
  pendingAccounts: Array<{
    __typename?: 'AdminPendingNewAccount';
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    isSent: boolean;
    adminAccountId: string;
    deletedAt?: any | null;
    createdAt: any;
    venue: {
      __typename?: 'Venue';
      id: string;
      name: string;
      legalEntity: { __typename?: 'LegalEntity'; country: CountryCode };
    };
  }>;
};

export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on CurrentUserObjectType {
    client_id
    given_name
    family_name
    email
    zoneinfo
    cultureCode
    languageCode
  }
`;
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;
export type LogoutComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
  'mutation'
>;

export const LogoutComponent = (props: LogoutComponentProps) => (
  <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables>
    mutation={LogoutDocument}
    {...props}
  />
);

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const CheckAuthStateDocument = gql`
  mutation CheckAuthState {
    checkAuthState {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type CheckAuthStateMutationFn = Apollo.MutationFunction<
  CheckAuthStateMutation,
  CheckAuthStateMutationVariables
>;
export type CheckAuthStateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >,
  'mutation'
>;

export const CheckAuthStateComponent = (
  props: CheckAuthStateComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >
    mutation={CheckAuthStateDocument}
    {...props}
  />
);

/**
 * __useCheckAuthStateMutation__
 *
 * To run a mutation, you first call `useCheckAuthStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkAuthStateMutation, { data, loading, error }] = useCheckAuthStateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckAuthStateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >(CheckAuthStateDocument, options);
}
export type CheckAuthStateMutationHookResult = ReturnType<
  typeof useCheckAuthStateMutation
>;
export type CheckAuthStateMutationResult =
  Apollo.MutationResult<CheckAuthStateMutation>;
export type CheckAuthStateMutationOptions = Apollo.BaseMutationOptions<
  CheckAuthStateMutation,
  CheckAuthStateMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;
export type LoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LoginMutation,
    LoginMutationVariables
  >,
  'mutation'
>;

export const LoginComponent = (props: LoginComponentProps) => (
  <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables>
    mutation={LoginDocument}
    {...props}
  />
);

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const RegenTokenDocument = gql`
  mutation RegenToken {
    regenToken {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type RegenTokenMutationFn = Apollo.MutationFunction<
  RegenTokenMutation,
  RegenTokenMutationVariables
>;
export type RegenTokenComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RegenTokenMutation,
    RegenTokenMutationVariables
  >,
  'mutation'
>;

export const RegenTokenComponent = (props: RegenTokenComponentProps) => (
  <ApolloReactComponents.Mutation<
    RegenTokenMutation,
    RegenTokenMutationVariables
  >
    mutation={RegenTokenDocument}
    {...props}
  />
);

/**
 * __useRegenTokenMutation__
 *
 * To run a mutation, you first call `useRegenTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenTokenMutation, { data, loading, error }] = useRegenTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRegenTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RegenTokenMutation,
    RegenTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RegenTokenMutation,
    RegenTokenMutationVariables
  >(RegenTokenDocument, options);
}
export type RegenTokenMutationHookResult = ReturnType<
  typeof useRegenTokenMutation
>;
export type RegenTokenMutationResult =
  Apollo.MutationResult<RegenTokenMutation>;
export type RegenTokenMutationOptions = Apollo.BaseMutationOptions<
  RegenTokenMutation,
  RegenTokenMutationVariables
>;
export const CurrentUserAdminPermissionsDocument = gql`
  query CurrentUserAdminPermissions {
    adminPermissions {
      id
      accountId
      canCreateNewVenues
    }
  }
`;
export type CurrentUserAdminPermissionsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CurrentUserAdminPermissionsQuery,
    CurrentUserAdminPermissionsQueryVariables
  >,
  'query'
>;

export const CurrentUserAdminPermissionsComponent = (
  props: CurrentUserAdminPermissionsComponentProps,
) => (
  <ApolloReactComponents.Query<
    CurrentUserAdminPermissionsQuery,
    CurrentUserAdminPermissionsQueryVariables
  >
    query={CurrentUserAdminPermissionsDocument}
    {...props}
  />
);

/**
 * __useCurrentUserAdminPermissionsQuery__
 *
 * To run a query within a React component, call `useCurrentUserAdminPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAdminPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAdminPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserAdminPermissionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserAdminPermissionsQuery,
    CurrentUserAdminPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CurrentUserAdminPermissionsQuery,
    CurrentUserAdminPermissionsQueryVariables
  >(CurrentUserAdminPermissionsDocument, options);
}
export function useCurrentUserAdminPermissionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserAdminPermissionsQuery,
    CurrentUserAdminPermissionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrentUserAdminPermissionsQuery,
    CurrentUserAdminPermissionsQueryVariables
  >(CurrentUserAdminPermissionsDocument, options);
}
export type CurrentUserAdminPermissionsQueryHookResult = ReturnType<
  typeof useCurrentUserAdminPermissionsQuery
>;
export type CurrentUserAdminPermissionsLazyQueryHookResult = ReturnType<
  typeof useCurrentUserAdminPermissionsLazyQuery
>;
export type CurrentUserAdminPermissionsQueryResult = Apollo.QueryResult<
  CurrentUserAdminPermissionsQuery,
  CurrentUserAdminPermissionsQueryVariables
>;
export function refetchCurrentUserAdminPermissionsQuery(
  variables?: CurrentUserAdminPermissionsQueryVariables,
) {
  return { query: CurrentUserAdminPermissionsDocument, variables: variables };
}
export const CreatePendingAccountDocument = gql`
  mutation CreatePendingAccount(
    $venueName: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $country: CountryCode!
  ) {
    createPendingAccount(
      venueName: $venueName
      email: $email
      firstName: $firstName
      lastName: $lastName
      country: $country
    ) {
      id
      email
      firstName
      lastName
      isSent
      adminAccountId
      venue {
        id
        name
        legalEntity {
          country
        }
      }
    }
  }
`;
export type CreatePendingAccountMutationFn = Apollo.MutationFunction<
  CreatePendingAccountMutation,
  CreatePendingAccountMutationVariables
>;
export type CreatePendingAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreatePendingAccountMutation,
    CreatePendingAccountMutationVariables
  >,
  'mutation'
>;

export const CreatePendingAccountComponent = (
  props: CreatePendingAccountComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreatePendingAccountMutation,
    CreatePendingAccountMutationVariables
  >
    mutation={CreatePendingAccountDocument}
    {...props}
  />
);

/**
 * __useCreatePendingAccountMutation__
 *
 * To run a mutation, you first call `useCreatePendingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePendingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPendingAccountMutation, { data, loading, error }] = useCreatePendingAccountMutation({
 *   variables: {
 *      venueName: // value for 'venueName'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useCreatePendingAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreatePendingAccountMutation,
    CreatePendingAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreatePendingAccountMutation,
    CreatePendingAccountMutationVariables
  >(CreatePendingAccountDocument, options);
}
export type CreatePendingAccountMutationHookResult = ReturnType<
  typeof useCreatePendingAccountMutation
>;
export type CreatePendingAccountMutationResult =
  Apollo.MutationResult<CreatePendingAccountMutation>;
export type CreatePendingAccountMutationOptions = Apollo.BaseMutationOptions<
  CreatePendingAccountMutation,
  CreatePendingAccountMutationVariables
>;
export const EditPendingAccountDocument = gql`
  mutation EditPendingAccount(
    $id: ID!
    $venueName: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $country: CountryCode!
  ) {
    editPendingAccount(
      id: $id
      venueName: $venueName
      email: $email
      firstName: $firstName
      lastName: $lastName
      country: $country
    ) {
      id
      email
      firstName
      lastName
      isSent
      adminAccountId
      venue {
        id
        name
        legalEntity {
          country
        }
      }
    }
  }
`;
export type EditPendingAccountMutationFn = Apollo.MutationFunction<
  EditPendingAccountMutation,
  EditPendingAccountMutationVariables
>;
export type EditPendingAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    EditPendingAccountMutation,
    EditPendingAccountMutationVariables
  >,
  'mutation'
>;

export const EditPendingAccountComponent = (
  props: EditPendingAccountComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    EditPendingAccountMutation,
    EditPendingAccountMutationVariables
  >
    mutation={EditPendingAccountDocument}
    {...props}
  />
);

/**
 * __useEditPendingAccountMutation__
 *
 * To run a mutation, you first call `useEditPendingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPendingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPendingAccountMutation, { data, loading, error }] = useEditPendingAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      venueName: // value for 'venueName'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useEditPendingAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EditPendingAccountMutation,
    EditPendingAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    EditPendingAccountMutation,
    EditPendingAccountMutationVariables
  >(EditPendingAccountDocument, options);
}
export type EditPendingAccountMutationHookResult = ReturnType<
  typeof useEditPendingAccountMutation
>;
export type EditPendingAccountMutationResult =
  Apollo.MutationResult<EditPendingAccountMutation>;
export type EditPendingAccountMutationOptions = Apollo.BaseMutationOptions<
  EditPendingAccountMutation,
  EditPendingAccountMutationVariables
>;
export const ArchivePendingAccountDocument = gql`
  mutation ArchivePendingAccount($id: ID!) {
    archivePendingAccount(id: $id) {
      id
      deletedAt
    }
  }
`;
export type ArchivePendingAccountMutationFn = Apollo.MutationFunction<
  ArchivePendingAccountMutation,
  ArchivePendingAccountMutationVariables
>;
export type ArchivePendingAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ArchivePendingAccountMutation,
    ArchivePendingAccountMutationVariables
  >,
  'mutation'
>;

export const ArchivePendingAccountComponent = (
  props: ArchivePendingAccountComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    ArchivePendingAccountMutation,
    ArchivePendingAccountMutationVariables
  >
    mutation={ArchivePendingAccountDocument}
    {...props}
  />
);

/**
 * __useArchivePendingAccountMutation__
 *
 * To run a mutation, you first call `useArchivePendingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchivePendingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archivePendingAccountMutation, { data, loading, error }] = useArchivePendingAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useArchivePendingAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchivePendingAccountMutation,
    ArchivePendingAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ArchivePendingAccountMutation,
    ArchivePendingAccountMutationVariables
  >(ArchivePendingAccountDocument, options);
}
export type ArchivePendingAccountMutationHookResult = ReturnType<
  typeof useArchivePendingAccountMutation
>;
export type ArchivePendingAccountMutationResult =
  Apollo.MutationResult<ArchivePendingAccountMutation>;
export type ArchivePendingAccountMutationOptions = Apollo.BaseMutationOptions<
  ArchivePendingAccountMutation,
  ArchivePendingAccountMutationVariables
>;
export const UnarchivePendingAccountDocument = gql`
  mutation UnarchivePendingAccount($id: ID!) {
    unarchivePendingAccount(id: $id) {
      id
      deletedAt
    }
  }
`;
export type UnarchivePendingAccountMutationFn = Apollo.MutationFunction<
  UnarchivePendingAccountMutation,
  UnarchivePendingAccountMutationVariables
>;
export type UnarchivePendingAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnarchivePendingAccountMutation,
    UnarchivePendingAccountMutationVariables
  >,
  'mutation'
>;

export const UnarchivePendingAccountComponent = (
  props: UnarchivePendingAccountComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UnarchivePendingAccountMutation,
    UnarchivePendingAccountMutationVariables
  >
    mutation={UnarchivePendingAccountDocument}
    {...props}
  />
);

/**
 * __useUnarchivePendingAccountMutation__
 *
 * To run a mutation, you first call `useUnarchivePendingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchivePendingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchivePendingAccountMutation, { data, loading, error }] = useUnarchivePendingAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnarchivePendingAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnarchivePendingAccountMutation,
    UnarchivePendingAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnarchivePendingAccountMutation,
    UnarchivePendingAccountMutationVariables
  >(UnarchivePendingAccountDocument, options);
}
export type UnarchivePendingAccountMutationHookResult = ReturnType<
  typeof useUnarchivePendingAccountMutation
>;
export type UnarchivePendingAccountMutationResult =
  Apollo.MutationResult<UnarchivePendingAccountMutation>;
export type UnarchivePendingAccountMutationOptions = Apollo.BaseMutationOptions<
  UnarchivePendingAccountMutation,
  UnarchivePendingAccountMutationVariables
>;
export const ConfirmPendingAccountDocument = gql`
  mutation ConfirmPendingAccount(
    $pendingAccountId: ID!
    $doesAccountExist: Boolean!
  ) {
    confirmPendingAccount(
      pendingAccountId: $pendingAccountId
      doesAccountExist: $doesAccountExist
    ) {
      id
      isSent
    }
  }
`;
export type ConfirmPendingAccountMutationFn = Apollo.MutationFunction<
  ConfirmPendingAccountMutation,
  ConfirmPendingAccountMutationVariables
>;
export type ConfirmPendingAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ConfirmPendingAccountMutation,
    ConfirmPendingAccountMutationVariables
  >,
  'mutation'
>;

export const ConfirmPendingAccountComponent = (
  props: ConfirmPendingAccountComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    ConfirmPendingAccountMutation,
    ConfirmPendingAccountMutationVariables
  >
    mutation={ConfirmPendingAccountDocument}
    {...props}
  />
);

/**
 * __useConfirmPendingAccountMutation__
 *
 * To run a mutation, you first call `useConfirmPendingAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPendingAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPendingAccountMutation, { data, loading, error }] = useConfirmPendingAccountMutation({
 *   variables: {
 *      pendingAccountId: // value for 'pendingAccountId'
 *      doesAccountExist: // value for 'doesAccountExist'
 *   },
 * });
 */
export function useConfirmPendingAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConfirmPendingAccountMutation,
    ConfirmPendingAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConfirmPendingAccountMutation,
    ConfirmPendingAccountMutationVariables
  >(ConfirmPendingAccountDocument, options);
}
export type ConfirmPendingAccountMutationHookResult = ReturnType<
  typeof useConfirmPendingAccountMutation
>;
export type ConfirmPendingAccountMutationResult =
  Apollo.MutationResult<ConfirmPendingAccountMutation>;
export type ConfirmPendingAccountMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPendingAccountMutation,
  ConfirmPendingAccountMutationVariables
>;
export const ExistingAccountByEmailDocument = gql`
  query ExistingAccountByEmail($email: String!) {
    existingAccountByEmail(email: $email) {
      accountId
      email
      firstName
      lastName
      venueNames
      isDeleted
    }
  }
`;
export type ExistingAccountByEmailComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    ExistingAccountByEmailQuery,
    ExistingAccountByEmailQueryVariables
  >,
  'query'
> &
  (
    | { variables: ExistingAccountByEmailQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const ExistingAccountByEmailComponent = (
  props: ExistingAccountByEmailComponentProps,
) => (
  <ApolloReactComponents.Query<
    ExistingAccountByEmailQuery,
    ExistingAccountByEmailQueryVariables
  >
    query={ExistingAccountByEmailDocument}
    {...props}
  />
);

/**
 * __useExistingAccountByEmailQuery__
 *
 * To run a query within a React component, call `useExistingAccountByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistingAccountByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistingAccountByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useExistingAccountByEmailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ExistingAccountByEmailQuery,
    ExistingAccountByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    ExistingAccountByEmailQuery,
    ExistingAccountByEmailQueryVariables
  >(ExistingAccountByEmailDocument, options);
}
export function useExistingAccountByEmailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ExistingAccountByEmailQuery,
    ExistingAccountByEmailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    ExistingAccountByEmailQuery,
    ExistingAccountByEmailQueryVariables
  >(ExistingAccountByEmailDocument, options);
}
export type ExistingAccountByEmailQueryHookResult = ReturnType<
  typeof useExistingAccountByEmailQuery
>;
export type ExistingAccountByEmailLazyQueryHookResult = ReturnType<
  typeof useExistingAccountByEmailLazyQuery
>;
export type ExistingAccountByEmailQueryResult = Apollo.QueryResult<
  ExistingAccountByEmailQuery,
  ExistingAccountByEmailQueryVariables
>;
export function refetchExistingAccountByEmailQuery(
  variables: ExistingAccountByEmailQueryVariables,
) {
  return { query: ExistingAccountByEmailDocument, variables: variables };
}
export const PendingAccountsDocument = gql`
  query PendingAccounts {
    pendingAccounts {
      id
      email
      firstName
      lastName
      isSent
      adminAccountId
      deletedAt
      createdAt
      venue {
        id
        name
        legalEntity {
          country
        }
      }
    }
  }
`;
export type PendingAccountsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PendingAccountsQuery,
    PendingAccountsQueryVariables
  >,
  'query'
>;

export const PendingAccountsComponent = (
  props: PendingAccountsComponentProps,
) => (
  <ApolloReactComponents.Query<
    PendingAccountsQuery,
    PendingAccountsQueryVariables
  >
    query={PendingAccountsDocument}
    {...props}
  />
);

/**
 * __usePendingAccountsQuery__
 *
 * To run a query within a React component, call `usePendingAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePendingAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePendingAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePendingAccountsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    PendingAccountsQuery,
    PendingAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PendingAccountsQuery,
    PendingAccountsQueryVariables
  >(PendingAccountsDocument, options);
}
export function usePendingAccountsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PendingAccountsQuery,
    PendingAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PendingAccountsQuery,
    PendingAccountsQueryVariables
  >(PendingAccountsDocument, options);
}
export type PendingAccountsQueryHookResult = ReturnType<
  typeof usePendingAccountsQuery
>;
export type PendingAccountsLazyQueryHookResult = ReturnType<
  typeof usePendingAccountsLazyQuery
>;
export type PendingAccountsQueryResult = Apollo.QueryResult<
  PendingAccountsQuery,
  PendingAccountsQueryVariables
>;
export function refetchPendingAccountsQuery(
  variables?: PendingAccountsQueryVariables,
) {
  return { query: PendingAccountsDocument, variables: variables };
}
