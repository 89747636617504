import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import SiteLogo from '../../../layout/SiteLogo';
import { AppPreview } from './AppPreview';
import { CircleGroup, GreenDiamond, LeftTop } from './DecorationElements';
import { DesktopBackgroundElements } from './DesktopBackgroundElements';
import { LoginForm } from './LoginForm';
import { Figure } from './style';

export const Login = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <Box
      display="grid"
      alignItems="center"
      position="relative"
      overflow="hidden"
    >
      {isDesktop ? (
        <Box position="relative" zIndex={0}>
          <DesktopBackgroundElements />
        </Box>
      ) : null}
      <Box
        display="grid"
        position="relative"
        justifySelf={isDesktop ? 'center' : undefined}
        gridTemplateColumns={[
          'minmax(auto, 400px)',
          '400px',
          '400px',
          '400px 640px',
        ]}
        gridTemplateRows={`auto ${isDesktop ? '79' : '67'}px`}
        rowGap={2}
        columnGap="135px"
        alignItems="center"
        minHeight="100vh"
        px={isDesktop ? undefined : 2}
        pt={isDesktop ? undefined : 10}
        justifyContent={isDesktop ? undefined : 'center'}
      >
        {isDesktop ? null : (
          <>
            <Figure
              position="absolute"
              sx={{ transform: 'scaleX(-1)' }}
              bottom={90}
              right={16}
            >
              <CircleGroup />
            </Figure>
            <Figure
              position="absolute"
              top={0}
              right={0}
              sx={{ transform: 'scaleX(-1)' }}
            >
              <LeftTop />
            </Figure>
          </>
        )}
        <Box position="relative">
          {isDesktop ? null : (
            <Figure position="absolute" top={100} right={24}>
              <GreenDiamond />
            </Figure>
          )}
          <Box mb={6} display="grid" rowGap={3.75}>
            <SiteLogo height={40} />
            <Typography
              whiteSpace="pre-wrap"
              variant={isDesktop ? 'h1' : 'h2'}
              component="h1"
            >
              {'Welcome to\n'}
              <Typography
                whiteSpace="pre-wrap"
                variant={isDesktop ? 'h1' : 'h2'}
                component="span"
                color="error"
              >
                Dishboard Admin
              </Typography>
            </Typography>
          </Box>
          <Typography variant="subtitle1">Login</Typography>
          <LoginForm />
        </Box>
        {isDesktop ? <AppPreview /> : null}
        <Box
          display="grid"
          gridAutoFlow="column"
          justifyContent="start"
          columnGap={1}
          alignSelf="start"
        >
          <Button
            component="a"
            target="_blank"
            href="https://drive.google.com/file/d/1ioZL1nCvDiJhQ3Q88fxLZIIVKpyxkVqn/view"
            rel="noreferrer noopener"
          >
            Terms of Usage
          </Button>
          <Button
            component="a"
            target="_blank"
            href="https://drive.google.com/file/d/1HGKAXOLR-RzV_NV99ivxlQsoaVFNsgv9/view"
            rel="noreferrer noopener"
          >
            Privacy Policy
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
