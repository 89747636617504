import { Box, BoxProps } from '@mui/material';
import styled from 'styled-components';

export const Figure = styled((props: BoxProps) => <Box {...props} />)`
  margin-block: unset;
  margin-inline: unset;

  ${({ position }) => (position ? `position: ${position};` : ``)}
  ${({ width }) => (width ? `width: ${width};` : ``)}
  ${({ height }) => (height ? `height: ${height};` : ``)}
  > svg {
    display: block;
  }
`;

export const ScrollContainer = styled(Figure)`
  width: 100%;
  position: absolute;

  &:hover {
    animation: 5s scroll-content;
  }

  @keyframes scroll-content {
    0% {
      transform: translateY(0%);
    }

    10% {
      transform: translateY(5%);
    }
    40% {
      transform: translateY(-65%);
    }
    50% {
      transform: translateY(-55%);
    }
    75% {
      transform: translateY(0%);
    }
    80% {
      transform: translateY(-2.5%);
    }

    100% {
      transform: translateY(0);
    }
  }
`;
