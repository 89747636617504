/* eslint-disable max-len */
export const LeftTop = () => (
  <svg
    fill="none"
    height="78"
    viewBox="0 0 108 78"
    width="108"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <clipPath id="a">
      <path d="m0 0h108v78h-108z" />
    </clipPath>
    <g clipPath="url(#a)">
      <path
        d="m86.4589-10.7705c3.8084 6.5962 6.2802 13.87794 7.2744 21.4295.9941 7.5515.4912 15.2248-1.4801 22.582-1.9714 7.3571-5.3725 14.2539-10.0092 20.2966-4.6368 6.0427-10.4183 11.113-17.0145 14.9213-6.5963 3.8084-13.878 6.2802-21.4295 7.2744-7.5515.9941-15.2249.4912-22.582-1.4801-7.3572-1.9714-14.25398-5.3725-20.296694-10.0092-6.042706-4.6368-11.113006-10.4183-14.921306-17.0145l10.04589-5.8c3.046668 5.277 7.10288 9.9022 11.93705 13.6116 4.83416 3.7094 10.35166 6.4303 16.23736 8.0073 5.8857 1.5771 12.0244 1.9795 18.0656 1.1841 6.0412-.7953 11.8666-2.7728 17.1436-5.8194 5.277-3.0467 9.9022-7.1029 13.6116-11.9371s6.4303-10.3516 8.0073-16.2373c1.5771-5.8857 1.9795-12.0244 1.1841-18.0656-.7953-6.04125-2.7728-11.866643-5.8194-17.14363z"
        fill="#ffbee9"
        opacity=".6"
      />
      <path
        d="m106 30.5c0 5.4499-1.073 10.8464-3.159 15.8814s-5.1424 9.6099-8.9961 13.4635l-29.3449-29.3449z"
        fill="#97eddd"
      />
    </g>
  </svg>
);
