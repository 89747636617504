import Dashboard from './Dashboard';
import Login from './Login';
import NewAccount from './NewAccount';
import { RouteType } from './types';

export const privateRoutes: Array<RouteType> = [Dashboard, NewAccount];

export {
  Dashboard as DashboardRoute,
  Login as LoginRoute,
  NewAccount as NewAccountRoute,
};

export const publicRoutes: Array<Omit<RouteType, 'icon'>> = [Login];
