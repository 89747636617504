/* eslint-disable max-len */
export const Zigzag = () => (
  <svg
    width="192"
    height="108"
    viewBox="0 0 192 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2410_20645)">
      <path
        opacity=".3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M191.596 108.758L112.267 29.4236L80.0867 61.6054L9.24276 -9.24249L0.75724 -0.757446L80.0867 78.5765L112.267 46.3946L183.111 117.243L191.596 108.758Z"
        fill="#B2AEF8"
      />
      <rect
        opacity=".4"
        x="111.941"
        y="62"
        width="29.74"
        height="29.74"
        transform="rotate(45 111.941 62)"
        fill="#97EDDD"
      />
    </g>
    <defs>
      <clipPath id="clip0_2410_20645">
        <rect width="192" height="108" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);
